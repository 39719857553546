import { GameModel } from "../../ilmare/models/GameModel";

export class MinigameModel extends GameModel {
    private _id: string;
    private _name: string;
    private _description: string;
    private _image: string;
    private _path: string;
    private _prizeCollections: string[];
    private _codename: string;

    public setup() {
    }

    populate(data: any) {
        this._id = data._id;
        this._name = data.name;
        this._description = data.description;
        this._prizeCollections = data.prizeCollections;
        this._image = data.image;
        this._path = data.path;
        this._codename = data.codename;
        this.notifyChange();
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get description(): string {
        return this._description;
    }

    get image(): string {
        return this._image;
    }

    get path(): string {
        return this._path;
    }

    get prizeCollections(): string[] {
        return this._prizeCollections;
    }

    get codename(): string {
        return this._codename;
    }

    public destroy() {
    }

}

