/**
 * ...
 * @author Juan Ignacio Albanesi
 */

import { IInjectable } from "./IInjectable";


//@:allow(currentGame.states)
export interface DependencyInjectorContext
{
	id: string;
	map: { [key: string]: IInjectable; };
	setupOrder: Array<string>; // se guarda el orden de add para llamar al setup con el mismo orden
	initialized: Map<string, boolean>; // para no llamar al setup 2 veces
}


export class DependencyInjector implements IInjectable
{
	public static DEFAULT_CONTEXTS: Array<string> = ["DEFAULT"];

	protected _contexts: { [key: string]: DependencyInjectorContext; };
	protected _contextsOrder: Array<string>;

	public constructor() 
	{
		//_map = new Map<string, IInjectable>();
		this._contexts = {};
		this._contextsOrder = [];
	}

	public setup()
	{
		
	}
	
	public map(property: string, value: IInjectable, contexts?: Array<string>)
	{
		if (contexts == null)
		{
			contexts = DependencyInjector.DEFAULT_CONTEXTS;
		}

		for (let context of contexts)
		{
			if (!this._contexts[context])
			{
				this._contexts[context] = {
					id: context,
					map: {},
					setupOrder: [],
					initialized: new Map<string, boolean>()
				};
				this._contextsOrder.push(context);
			}
			var contextMap: DependencyInjectorContext = this._contexts[context];
			contextMap.map[property] = value;
			contextMap.initialized[property] = false;
			contextMap.setupOrder.push(property);
		}
	}

	public injectInto(object: IInjectable, contexts?: Array<string>)
	{
		//D.trace("injectInto");
		if (contexts == null)
		{
			contexts = this._contextsOrder;
		}

		/*console.log("-------------------------------------------------");
		console.log("injectInto");
		console.log(object);*/

		// TODO: mejorar y agregar error handling
		for (let context of contexts)
		{
			//D.trace("context: " + context);
			if (this._contexts[context])
			{
				var contextMap: DependencyInjectorContext = this._contexts[context];
				//D.trace("contextMap: " + contextMap);

				for (let property of contextMap.setupOrder)
				{
					/*console.log("1 property: " + property + "? " + Reflect.has(object, property));
					console.log("2 property: " + property + "? " + object.hasOwnProperty(property));*/

					//if (object.hasOwnProperty(property)) 
					if (Reflect.has(object, property))
					{
						Reflect.set(object, property, contextMap.map[property]);
					}


				}
			}
		}

	}

	// Llamar despues de mapear todas las injections
	// Los setup solo se llaman una vez
	public process() 
	{
		for (let context of this._contextsOrder)
		{
			var contextMap: DependencyInjectorContext = this._contexts[context];

			for (let injectableId in contextMap.map)
			{
				this.injectInto(contextMap.map[injectableId]);
			}

			for (let injectableId of contextMap.setupOrder)
			{
				if (contextMap.initialized[injectableId] == false)
				{
					contextMap.map[injectableId].setup();
					contextMap.initialized[injectableId] = true;
				}
			}
		}
	}

	public unmapContext(context: string = "DEFAULT")
	{
		throw new Error("unmapContext NOT FULLY IMPLEMENTED");
		if (this._contexts[context])
		{
			var contextMap: DependencyInjectorContext = this._contexts[context];

			for (let property of contextMap.setupOrder)
			{
				if (contextMap.map[property])
				{
					var value: IInjectable = contextMap.map[property];
					value.destroy();
					//contextMap.map.delete(property); TODO
					contextMap.initialized.delete(property);
				}
			}
			//this._contexts.delete(context); TODO

			this._contextsOrder.splice(this._contextsOrder.indexOf(context), 1);
		}
	}

	public initialize(injectable: IInjectable)
	{
		this.injectInto(injectable);
		injectable.setup();
	}

	public getInstanceOf(id: string, contexts?: Array<string>) {
		if (contexts == null)
		{
			contexts = this._contextsOrder;
		}

		// TODO: contexts aren't tested
		for (let context of contexts)
		{
			if (this._contexts[context])
			{
				var contextMap: DependencyInjectorContext = this._contexts[context];			
				//console.table(contextMap);
				if (contextMap.initialized[id] === true)
				{
					return contextMap.map[id];
				}
			}
		}

		return null;
	}

	public destroy()
	{
		// TODO: hacer un unmap asi se llama el destroy
		this._contextsOrder = [];
		this._contexts = {};
	}
}