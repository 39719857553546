import React, { FunctionComponent } from 'react';
import { useNavigate } from "react-router-dom";
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { CreditsBundlesModel } from '../../../game/models/CreditsBundlesModel';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { BuyCreditsAction } from '../../../game/actions/user/BuyCreditsAction';
import { GetCreditsBundlesAction } from '../../../game/actions/credits/GetCreditsBundlesAction';
import { CardButtonContainer, CardDescriptionContainer, CardImage, CardImageContainer, CardTitleContainer, MainCardContainer, ContentCardContainer, CardsContainer, TitleContentCardContainer } from './styles';
import { ModalDialogCallToActionButton } from '../../modal/styles';

type CreditsBundleProps = {

}

export const CreditsBundles: FunctionComponent<CreditsBundleProps> = ()=> {

    // TODO: improve the view

    const creditsBundlesModel = useObservableModel<CreditsBundlesModel>('_creditsBundlesModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    if (creditsBundlesModel.bundles.length == 0) {
        actionsManager.executeActionInstant(GetCreditsBundlesAction);

        return <div>
            Loading...
        </div>
    }

    // - ARMAR EL CARD DE CADA BUNDLE
    // - EN EL SERVER ENTREGAR LA CANTIDAD DE CREDITOS ACORDE AL BUNDLE
    
    let bundles = creditsBundlesModel.bundles.map((bundle) => {
        return <MainCardContainer key={bundle.id}>
            <TitleContentCardContainer>
                <CardTitleContainer>{bundle.title}</CardTitleContainer>
            </TitleContentCardContainer>
            <ContentCardContainer>
                <CardImageContainer>
                    <CardImage src={"assets/bundles/" + bundle.image} />
                    {bundle.credits}
                </CardImageContainer>
                <CardDescriptionContainer>
                    {bundle.description}
                </CardDescriptionContainer>
                <CardButtonContainer>
                    <ModalDialogCallToActionButton onClick={
                        (evt) => {
                            actionsManager.executeActionInstant(BuyCreditsAction, bundle.id);
                        }
                    }>{bundle.price + " " + bundle.currency}</ModalDialogCallToActionButton>
                </CardButtonContainer>
            </ContentCardContainer>
        </MainCardContainer>
    });

    return <CardsContainer>
        {bundles}
    </CardsContainer>
    
}