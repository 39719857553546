import { GameEvent } from "../../../events/MainSceneEvent";
import { ActionsManager } from "../../../managers/ActionsManager";
import { PrizeCollectionModel } from "../../../models/PrizeCollectionModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { UserModel } from "../../../models/UserModel";
import { ServerAction } from "../../ServerAction";

export class GetAllPrizeCollectionsAction extends ServerAction {
    protected _actionsManager: ActionsManager = undefined; // injects
    protected _userModel: UserModel = undefined; // injects
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects

    constructor() {
        super("get-all-prize-collections", "get-all-prize-collections-completed");
    }

    public setup(): void {
        
    }

    protected processResponse(evtData: any) {        
        console.log('get-all-prize-collections-completed');

        for (let collection of evtData.collections) {
            let prizeCollection = new PrizeCollectionModel();
            prizeCollection.populate(collection);
    
            this._prizeCollectionsModel.addCollection(collection._id, prizeCollection);    
        }
    }

    public destroy(): void {
        
    }

}