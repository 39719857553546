import React, { useState, FunctionComponent } from 'react';
import { Formik } from 'formik';

import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { UserModel } from '../../../game/models/UserModel';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { Loading } from '../../loading/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import InputFormField from '../common/InputFormField';
import { BOFixedSizeImage, BOFormButton, BOStyledForm } from '../styles';
import { PrizeCollectionsModel } from '../../../game/models/PrizeCollectionsModel';
import { GetAllPrizeCollectionsAction } from '../../../game/actions/user/inventory/GetAllPrizeCollectionsAction';
import ManifestFS from '../../../manifest/ManifestFS';
import Path from '../../../utils/Path';
import { ModalPopupModel } from '../../../game/models/ModalPopupModel';
import { InfoPopupPreset } from '../../modal/presets/InfoPopupPreset';
import { useActionOnMount } from '../../../game/reactHooks/actionHooks';
import OptionsFormField from '../common/OptionsFormField';
import { BOCreatePrizeAction } from '../../../game/actions/backOffice/prizes/BOCreatePrizeAction';
import { usePrizeCollectionsCss } from '../../../game/reactHooks/cssHooks';

interface PrizesEditorCreateProps {

}


export const PrizesEditorCreate: FunctionComponent<PrizesEditorCreateProps> = (props) => {
    const prizeCollections = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');
    const userModel = useObservableModel<UserModel>('_userModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const modalPopupModel = useObservableModel<ModalPopupModel>('_modalPopupModel');

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [images, setImages] = useState<string[]>([]);
    const [imageFullPath, setImageFullPath] = useState<string>("");
    //const [imageLockedFullPath, setImageLockedFullPath] = useState<string>("");
    const [imageSlotFullPath, setImageSlotFullPath] = useState<string>("");

    const [loaded, setLoaded] = useState<boolean>(false);

    usePrizeCollectionsCss("all", setLoaded);

    useActionOnMount(GetAllPrizeCollectionsAction);


    if (!userModel.isAdmin) {
        return <></>;
    }

    if (!loaded) {
        return <Loading />;
    }

    return (
        <div>
            <h1>Crear Premio</h1>
            {loading ? (
                <Loading />
            ) : error ? (
                <p>{error}</p>
            ) : (
                <Formik
                    initialValues={{
                        title: "Title",
                        description: "Description",
                        image: "",
                        imagePath: "",
                        imageExtension: "",
                        prizeCollection: ""
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values, { setSubmitting }) => {
                        debugger;
                        setLoading(true);
                        await actionsManager.executeActionInstant(BOCreatePrizeAction, values);
                        modalPopupModel.initWithContent(InfoPopupPreset, { title: 'Premio creado', message: 'El premio se ha creado correctamente' });
                        setLoading(false);
                    }}
                >
                    {({ values, setFieldValue }) => {

                        const handlePrizeCollectionChange = (e) => {
                            let collection = prizeCollections.getCollectionById(e.target.value);
                            let images = ManifestFS.getFiles(Path.join(collection.path, "prizes"))
                                .filter((file) => !file.includes('_locked'))
                                .filter((file) => !file.includes('_slot'))
                                .filter((file) => !collection.prizes.find(prize => prize.texturePath.includes(file)));
                            setImages(images);

                            setFieldValue("image", images[0]);
                            setFieldValue("prizeCollection", e.target.value);
                            handleImageChange({ target: { value: images[0] } }, e.target.value);
                        }

                        const handleImageChange = (e, prizeCollection = null) => {
                            if (!e.target.value) { 
                                return; 
                            }
                            setFieldValue('imagePath', Path.join('prizes', e.target.value.split('.')[0]));
                            setFieldValue('imageExtension', e.target.value.split('.')[1]);

                            prizeCollection = prizeCollection ? prizeCollection : values.prizeCollection;;
                            setImageFullPath(Path.join(prizeCollections.getCollectionById(prizeCollection).path, "prizes", e.target.value));
                            //setImageLockedFullPath(Path.join(prizeCollections.getCollectionById(prizeCollection).path, "prizes", e.target.value.split('.')[0] + '_locked.' + e.target.value.split('.')[1]));
                            setImageSlotFullPath(Path.join(prizeCollections.getCollectionById(prizeCollection).path, "prizes", e.target.value.split('.')[0] + '_slot.' + e.target.value.split('.')[1]));
                        }

                        return (


                            <BOStyledForm>
                                <InputFormField label="Título" name="title" />
                                <InputFormField label="Descripción" name="description" />

                                <OptionsFormField label="Collection" name="prizeCollection"
                                    options={prizeCollections.allCollections.map((collection) => { return { id: collection.id, title: collection.title } })}
                                    onChange={handlePrizeCollectionChange}
                                />

                                <OptionsFormField label="Imagen" name="image" 
                                    options={images.map(image => { return { id: image, title: image } })} 
                                    onChange={handleImageChange} 
                                />
                                <BOFixedSizeImage src={imageFullPath} alt={values.title} />                                
                                <BOFixedSizeImage src={imageSlotFullPath} alt={values.title} />

                                <BOFormButton type="submit">Guardar</BOFormButton>
                            </BOStyledForm>
                        )
                    }}
                </Formik>
            )}
        </div>
    )
}