import { ActionsManager } from "../../managers/ActionsManager";
import { ServerAction } from "../ServerAction";
import { UpdateCreditsAction } from "./UpdateCreditsAction";

export class BuyCreditsAction extends ServerAction {
    protected _actionsManager: ActionsManager = undefined; // injects

    constructor(bundleId: string) {
        super('buy-credits', 'buy-credits-completed', { bundleId: bundleId });
    }

    public setup(): void {
        
    }
    
    protected processResponse(evtData: any) {
        console.log('buy-credits-completed');
        this._actionsManager.executeActionInstant(UpdateCreditsAction);
    }

    public destroy(): void {
        
    }

}