import React from 'react';
import styled from 'styled-components';

export const CollectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto; // Permite el desplazamiento vertical si es necesario
  justify-content: center; // Centra los premios si hay espacio
`;

export const PrizeContainer = styled.div`
  flex-basis: calc(50% - 10px); // Ajusta este valor para cambiar cuántos premios quieres por fila
  margin-bottom: 1.5em;
  text-align: center;

  @media (orientation: landscape) {
    flex-basis: calc(33.33% - 10px); // Más premios por fila en orientación horizontal
  }
`;

export const PrizeTitle = styled.h2`
    font-size: 1.5em;
    font-weight: bold;
`;

export const PrizeImage = styled.div`
  width: 100%;
  height: auto;
  display: inline-block;
  background-size: cover;
  background-position: center;
`;

export const PrizeImageContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LockedPrizeImage = styled(PrizeImage)`
  filter: grayscale(100%); // Ejemplo de filtro para indicar que está bloqueado
`;

export const CollectionTitleContainer = styled.div`
    width: 100%;
    margin: 10px 0;
`;

export const CollectionTitle = styled.h2`
    font-size: 2em;
    font-weight: bold;
    text-align: center;
`;

export const PrizeWonDate = styled.h3`
    font-size: 1em;    
`;