import React, { FunctionComponent } from 'react';
import { Outlet } from "react-router-dom";
import { AuthStatus } from "../auth/AuthStatus";
import { UserNavigationBar } from '../navigation/UserNavigationBar';
import Logo from "./Logo";
import { HeaderContainer, HeaderInnerContainer, TopBarItemContainer } from './styles';
import { useObservableModel } from '../../ilmare/models/ObservableModelState';
import { useOrientation } from '../orientation/OrientationContext';
import { BONavigationBar } from '../backOffice/navigation/BONavigationBar';
import { isDesktop, isMobile } from 'react-device-detect';
import { CreditsItem } from '../credits/refillableDisplay/CreditsItem';



type HeaderProps = {

}

export const Header: FunctionComponent<HeaderProps> = () => {    
    const orientation = useOrientation();

    return (
        <HeaderContainer>
            <HeaderInnerContainer>
                <Logo/>
                <AuthStatus/>
                <TopBarItemContainer>
                    <CreditsItem/>
                </TopBarItemContainer>

                { isDesktop || isMobile && orientation == 'landscape' ? <UserNavigationBar/> : <></>}
                <BONavigationBar/>
                
                <Outlet />

            </HeaderInnerContainer>
        </HeaderContainer>

    );

}