import { ServerService } from "../../services/ServerService";
import { Action } from "../Action";
import { ServerErrorAction } from "../error/ServerErrorAction";
import { ForwardEmitAction } from "../helper/ForwardEmitAction";
import { PrizeAwardedToPlayerAction } from "../machines/PrizeAwardedToPlayerAction";
import { UpdateMachineViewersAction } from "../machines/UpdateMachineViewersAction";
import { QueueSizeUpdatedAction } from "../queue/QueueSizeUpdatedAction";
import { QueueUpdatedAction } from "../queue/QueueUpdatedAction";
import { OnGetTokenAction } from "../user/OnGetTokenAction";
import { OnUserAuthorizedAction } from "../user/OnUserAuthorizedAction";

export class ListenToServerAction extends Action {
    protected _server: ServerService = undefined; // injects

    public setup(): void {
        console.log("Listen To Server Action");
    }

    protected listenToServerMessages() {        
        this._server.registerIncomingMessage('authorized', OnUserAuthorizedAction);
        this._server.registerIncomingMessage('get-token', OnGetTokenAction);
        this._server.registerIncomingMessage('get-config-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('get-machines-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('get-credits-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('buy-credits-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('reserve-machine-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('choose-machine-completed', ForwardEmitAction);        
        this._server.registerIncomingMessage('play-at-machine-completed', ForwardEmitAction);        
        this._server.registerIncomingMessage('update-current-queue-completed', ForwardEmitAction);        
        this._server.registerIncomingMessage('leave-current-queue-completed', ForwardEmitAction);        
        this._server.registerIncomingMessage('error', ServerErrorAction);        
        this._server.registerIncomingMessage('update-viewers', UpdateMachineViewersAction);
        this._server.registerIncomingMessage('queue-updated', QueueUpdatedAction);
        this._server.registerIncomingMessage('prize-awarded-to-player', PrizeAwardedToPlayerAction);
        this._server.registerIncomingMessage('machine-queue-size-updated', QueueSizeUpdatedAction);
        this._server.registerIncomingMessage('get-user-details-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('get-user-won-prizes-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('get-prize-collection-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('get-credits-bundles-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('get-all-prize-collections-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('get-minigames-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('load-minigame-status-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('save-minigame-status-completed', ForwardEmitAction);
        
        // back office        
        this._server.registerIncomingMessage('bo-create-machine-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('bo-edit-machine-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('bo-refill-machine-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('bo-delete-machine-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('bo-update-machine-state-completed', ForwardEmitAction);        
        
        this._server.registerIncomingMessage('bo-create-collection-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('bo-delete-collection-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('bo-edit-collection-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('bo-auto-fill-collection-completed', ForwardEmitAction);

        this._server.registerIncomingMessage('bo-create-prize-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('bo-delete-prize-completed', ForwardEmitAction);
        this._server.registerIncomingMessage('bo-edit-prize-completed', ForwardEmitAction);

        this._server.registerIncomingMessage('bo-edit-config-completed', ForwardEmitAction);

    }

    protected payload() {
        this.listenToServerMessages();        
        this.finish(true);
    }
    public destroy(): void {
        
    }

}