import styled from "styled-components";

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: white;
    background: linear-gradient(to bottom right, #556270, #ff6b6b);
`;

export const LoginBox = styled.div`
    padding: 2vw;
    background: white;
    color: black;
    border-radius: 0.5rem;
`;

export const Input = styled.input`
    font-family: Heebo, Helvetica, Arial, sans-serif;
    color: rgb(255, 177, 0);
    font-weight: 700;
    font-size: 0.875rem;
    text-transform: uppercase;
    padding-top: 1px;
    transition: all 0.2s ease 0s;

    margin: 1rem 0;
    padding: 0.5rem;
    width: 100%;
    border-radius: 0.5rem;
    border: 0.1rem solid #ccc;  

    &::placeholder {
        color: rgba(200, 200, 200, 1);
    }
    
    
    &:focus {
        outline: none;
        border-bottom: 1px solid rgba(241, 196, 15, 1);
    }    
`;

export const Button = styled.button`
    font-family: Heebo, Helvetica, Arial, sans-serif;
    color: rgb(255, 177, 0);
    font-weight: 700;
    font-size: 0.875rem;
    text-transform: uppercase;
    padding-top: 1px;
    transition: all 0.2s ease 0s;

    padding: 1rem;
    width: 100%;
    margin-top: 1rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
        filter: brightness(1.03);
    }    
`;

export const SocialLoginButton = styled(Button)`
    background-color: #db4437;
    color: white;
`;

export const ButtonAsLink = styled(Button)`
    font-size: 0.6rem;
    background: none;
    color: #007bff;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
    width: auto;

    &:hover,
    &:active {
        text-decoration: none;
        color: #0056b3;
    }

    &:focus {
        outline: 1px dotted;
        outline-offset: 2px;
    }
`;

export const InfoText = styled.p`
    font-family: Heebo, Helvetica, Arial, sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 700;
    font-size: 0.6rem;
    text-transform: uppercase;
    padding-top: 1px;
    transition: all 0.2s ease 0s;
`;

export const BoldLink = styled.a`
    font-size: 12px;
    color: rgba(241,196,15,1);
    font-weight: 500;
    text-decoration: none;
    border-bottom: 1px dashed rgba(241,196,15,1);
`;

export const MutedLink = styled.a`
    font-size: 12px;
    color: rgba(200, 200, 200, 0.8);
    font-weight: 500;
    text-decoration: none;
    border-bottom: 1px dashed rgba(200, 200, 200, 0.8);
`;