import React, { useState } from 'react';
import styled from 'styled-components';

const FileInput = styled.input`
    padding: 10px;
    margin: 10px;
    background: #f4f4f4;
    border: 2px dashed #ddd;
    display: block;
`;

interface FileUploaderProps {
    onFileLoad: (file: File) => void;  // Definición de la prop callback
}

const FileUploader: React.FC<FileUploaderProps> = ({onFileLoad}) => {
    const [file, setFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        setFile(file);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (file) {
            onFileLoad(file);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FileInput type="file" accept=".json" onChange={handleFileChange} />
            <button type="submit">Subir archivo</button>
        </form>
    );
};

export default FileUploader;
