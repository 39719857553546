import React, { useState, useEffect, FunctionComponent } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { UserModel } from '../../../game/models/UserModel';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { MachineDescriptor, MachinesModel } from '../../../game/models/MachinesModel';
import { BOEditMachineAction } from '../../../game/actions/backOffice/machines/BOEditMachineAction';
import { Loading } from '../../loading/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import InputFormField from '../common/InputFormField';
import { BOAddButton, BOCollectionRow, BOContainer, BOError, BOFixedSizeImage, BOFormButton, BOLabel, BOMachineRow, BOPrizeGrid, BOPrizeItem, BORemoveButton, BOStyledForm, BOTitle } from '../styles';
import { PrizeCollectionsModel } from '../../../game/models/PrizeCollectionsModel';
import { GetAllPrizeCollectionsAction } from '../../../game/actions/user/inventory/GetAllPrizeCollectionsAction';
import { PrizeCollectionModel } from '../../../game/models/PrizeCollectionModel';
import ManifestFS from '../../../manifest/ManifestFS';
import Path from '../../../utils/Path';
import { BOEditCollectionAction } from '../../../game/actions/backOffice/collections/BOEditCollectionAction';
import { ModalPopupModel } from '../../../game/models/ModalPopupModel';
import { InfoPopupPreset } from '../../modal/presets/InfoPopupPreset';
import { BOCreateCollectionAction } from '../../../game/actions/backOffice/collections/BOCreateCollectionAction';
import { useActionOnMount } from '../../../game/reactHooks/actionHooks';
import OptionsFormField from '../common/OptionsFormField';
import { usePrizeCollectionsCss } from '../../../game/reactHooks/cssHooks';
import { set } from 'date-fns';

interface PrizeCollectionEditorCreateProps {

}


export const PrizeCollectionEditorCreate: FunctionComponent<PrizeCollectionEditorCreateProps> = (props) => {
    const prizeCollections = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');
    const userModel = useObservableModel<UserModel>('_userModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const modalPopupModel = useObservableModel<ModalPopupModel>('_modalPopupModel');
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');    
    const [loaded, setLoaded] = useState<boolean>(false);

    useActionOnMount(GetAllPrizeCollectionsAction);
    usePrizeCollectionsCss("all", setLoaded);

    if (!userModel.isAdmin) {
        return <></>;
    }

    // filtrar las colecciones que ya existen
    const collectionPaths = ManifestFS.getDirs('assets/collections').filter(
        (dir) => { 
            let val = prizeCollections.allCollections.find((collection) => collection.path == Path.join('assets/collections', dir));            
            return !val;
         }).map((dir) => { 
            return { id: 'assets/collections/' + dir, title: dir } 
    });    

    if (!loaded) {
        return <Loading />;
    }

    return (
        <div>
            <h1>Crear Colección de Premios</h1>
            {loading ? (
                <Loading />
            ) : error ? (
                <p>{error}</p>
            ) : (
                <Formik
                    initialValues={{
                        title: "Title",
                        description: "Description",
                        image: "",
                        slotName: "",
                        path: collectionPaths[0].id,
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values, { setSubmitting }) => {
                        setLoading(true);
                        await actionsManager.executeActionInstant(BOCreateCollectionAction, values);
                        modalPopupModel.initWithContent(InfoPopupPreset, { title: 'Colección creada', message: 'La colección se ha creado correctamente' });
                        setLoading(false);
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <BOStyledForm>
                            <InputFormField label="Título" name="title" />
                            <InputFormField label="Descripción" name="description" />
                            <InputFormField label="Slot" name="slotName" />

                            <OptionsFormField label="Path" name="path" options={collectionPaths} onChange={(e) => {
                                setFieldValue('image', ManifestFS.getFiles(e.target.value)[0]);                                
                            }}/>
                            <BOLabel>{values.path}</BOLabel>

                            <OptionsFormField label="Imagen" name="image" options={ManifestFS.getFiles(values.path).map((file) => { return { id: file, title: file } })} />
                            <BOFixedSizeImage src={values.path + '/' + values.image} alt={values.title} />

                            <BOFormButton type="submit">Guardar</BOFormButton>
                        </BOStyledForm>
                    )}
                </Formik>
            )}
        </div>
    )
}