export class GameEvent {
    public static UPDATE_MACHINES: string = "GameEvent.UPDATE_MACHINES";
    public static UPDATE_MACHINES_COMPLETED: string = "GameEvent.UPDATE_MACHINES_COMPLETED";

    public static UPDATE_CREDITS: string = "GameEvent.UPDATE_CREDITS";
    public static UPDATE_CREDITS_COMPLETED: string = "GameEvent.UPDATE_CREDITS_COMPLETED";

    public static UPDATE_CURRENT_MACHINE: string = "GameEvent.UPDATE_CURRENT_MACHINE";
    public static UPDATE_CURRENT_MACHINE_COMPLETED: string = "GameEvent.UPDATE_CURRENT_MACHINE_COMPLETED";

    public static START_PRIZE_PRESENTATION: string = "GameEvent.START_PRIZE_PRESENTATION";
    public static START_OTHER_PRIZE_PRESENTATION: string = "GameEvent.START_OTHER_PRIZE_PRESENTATION";
    public static UPDATE_MACHINE_FILL_LEVEL: string = "GameEvent.UPDATE_MACHINE_FILL_LEVEL";

    public static SERVER_ERROR_RECEIVED: string = "GameEvent.SERVER_ERROR_RECEIVED";
}