// clase similar a path de nodejs pero para el cliente

class Path {
    join(...paths: string[]): string {
      const filteredPaths = paths.filter(Boolean); // Elimina rutas vacías o nulas
      let joinedPath = filteredPaths.join('/');
  
      // Elimina múltiples barras inclinadas seguidas
      joinedPath = joinedPath.replace(/\/+/g, '/');
  
      return joinedPath;
    }
  
    basename(fullPath: string): string {
      const parts = fullPath.split('/');
      return parts[parts.length - 1];
    }
  }
  
  export default new Path();