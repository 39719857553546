import React, { FunctionComponent } from 'react';
import { useNavigate } from "react-router-dom";

type InfoPopupPresetProps = {
    title: string,
    message: string
}

export const InfoPopupPreset: FunctionComponent<InfoPopupPresetProps> = (props: InfoPopupPresetProps)=> {
    return <>
        <h1>{props.title}</h1>
        <h3>{props.message}</h3>
    </>
}