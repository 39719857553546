import Color from 'color';

class ColorUtils {
    generatePastelColor(seed: string, whiteMix = 0.6, alpha = 1) {
        // Convertir la cadena de semilla en un valor hash para la consistencia del color
        let hash = 0;
        for (let i = 0; i < seed.length; i++) {
            hash = seed.charCodeAt(i) + ((hash << 5) - hash);
        }
    
        // Usar el hash para generar colores
        let r = (hash & 0xFF0000) >> 16;
        let g = (hash & 0x00FF00) >> 8;
        let b = hash & 0x0000FF;
    
        // Verificar si los componentes son similares y ajustar si es necesario
        const threshold = 64;
        if (Math.abs(r - g) < threshold && Math.abs(g - b) < threshold && Math.abs(r - b) < threshold) {
            const indexToAdjust = hash % 3;
            if (indexToAdjust === 0) {
                r = r < 127 ? r * 2 : r / 2;
            } else if (indexToAdjust === 1) {
                g = g < 127 ? g * 2 : g / 2;
            } else {
                b = b < 127 ? b * 2 : b / 2;
            }
        }

        // Crear color aleatorio saturado y mezclarlo con blanco para obtener un tono pastel
        let pastelColor = Color({ r, g, b })
            .saturate(0.1) // Saturar ligeramente el color
            .mix(Color('white'), whiteMix)// Mezclar con blanco para obtener pastel
            .alpha(alpha); 

        return pastelColor.hexa(); // Devolver el color en formato hexadecimal
    }

}

export default new ColorUtils();