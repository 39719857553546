import { GameModel } from "../../ilmare/models/GameModel";
import { PrizeCollectionModel } from "./PrizeCollectionModel";
import { PrizeModel } from "./PrizeModel";


export class PrizeCollectionsModel extends GameModel {
    protected _collections: { [id: string]: PrizeCollectionModel } = {};    
    
    public setup() {
        
    }

    public getCollectionById(id: string) {        
        if (!this._collections[id]) {
            return null;    
        }
        return this._collections[id];
    }

    public addCollection(id: string, collection: PrizeCollectionModel) {
        this._collections[id] = collection;
        this.notifyChange();
    }

    public get allCollections(): PrizeCollectionModel[] {
        return Object.values(this._collections);
    }    

    public updateCollection(collection: any) {
        if (!this._collections[collection._id]) {
            return;
        }
        this._collections[collection._id].populate(collection);        
    }
    
    public getPrizeById(id: string): PrizeModel {
        for (let collection of this.allCollections) {
            let prize = collection.getPrizeById(id);
            if (prize) {
                return prize;
            }
        }
        return null;
    }

    public destroy() {
        
    }
}
