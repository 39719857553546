import React from "react";
import { Action } from "../../../game/actions/Action";
import { ActionsManager } from "../../../game/managers/ActionsManager";
import { useInjection } from "../../../game/reactHooks/InjectHooks";
import { AddCreditsButton, AddCreditsButtonContainer, AddCreditsIcon, CreditsAmount, CreditsAmountContainer, CreditsContainer, CreditsIcon } from "./styles";
import { useObservableModel } from "../../../ilmare/models/ObservableModelState";
import { UserModel } from "../../../game/models/UserModel";

type BaseCreditsDisplayProps = {
    icon: any,
    amount: number
}


export const BaseCreditsDisplay: React.FC<BaseCreditsDisplayProps> = ({ icon, amount }) => {

    const userModel = useObservableModel<UserModel>("_userModel");
    
    if (userModel.isLoggedIn) {    
        return (
            <CreditsContainer>
                {icon}
                <CreditsAmountContainer>
                    <CreditsAmount>{amount}</CreditsAmount>
                </CreditsAmountContainer>
            </CreditsContainer>
        )
    } else {
        return <></>;
    }
};

