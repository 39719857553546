import React, { FunctionComponent } from "react"
import { useNavigate } from "react-router-dom";
import { ChooseMachineAction } from "../../../game/actions/machines/ChooseMachineAction";
import { LeaveCurrentQueueAction } from "../../../game/actions/queue/LeaveCurrentQueueAction";
import { ActionsManager } from "../../../game/managers/ActionsManager";
import { CurrentMachineModel } from "../../../game/models/CurrentMachineModel";
import { CurrentQueueModel } from "../../../game/models/CurrentQueueModel";
import { MachineDescriptor, MachinesModel } from "../../../game/models/MachinesModel";
import { useInjection } from "../../../game/reactHooks/InjectHooks";
import { useObservableModel } from "../../../ilmare/models/ObservableModelState";

type CurrentQueuePanelProps = {
    
}

export const CurrentQueuePanel: FunctionComponent<CurrentQueuePanelProps> = ({})=> {
    
    const navigate = useNavigate();    
    const machinesModel = useObservableModel<MachinesModel>('_machinesModel');
    const currentQueue = useObservableModel<CurrentQueueModel>('_currentQueueModel');
    const currentMachineModel = useObservableModel<CurrentMachineModel>('_currentMachineModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    if (currentQueue.isWaitingInAQueue && currentMachineModel.currentMachineId != currentQueue.machineId) {
        let machine: MachineDescriptor = machinesModel.getMachineById(currentQueue.machineId);
        if (!machine) {
            return <></>;
        }
        let timeLeft = currentQueue.isPlaying ? secondsToTime(currentQueue.timeLeft) : undefined;        
        
        return <div>
            <div>{machine.title}</div>
            <div>Your Position: { currentQueue.isPlaying ?
                <b>Ready to Play! { twoDigits(timeLeft.m) }:{ twoDigits(timeLeft.s) }</b> :
                <b>{currentQueue.queuePosition + 1} / {currentQueue.queueLength}</b>
            }
            </div>
            <button className="queueButton goButton" onClick={
                        (evt) => {
                            actionsManager.executeActionInstant(ChooseMachineAction, currentQueue.machineId).then((val) => {
                                navigate("/machine/view/" + currentQueue.machineId);
                            });     
                        }        
                    }>
                    Go!
            </button>
            <button className="queueButton leaveQueueButton" onClick={
                    (evt) => {
                        actionsManager.executeActionInstant(LeaveCurrentQueueAction, currentQueue.machineId);                
                    }        
                }>
                Leave Queue
            </button>
        </div>
    } else {
        return <></>
    }

    
}

const secondsToTime = (secs: number): { h: number, m: number, s: number } => {
    let hours = Math.floor(secs / (60 * 60));

    let divisorForMinutes = secs % (60 * 60);
    let minutes = Math.floor(divisorForMinutes / 60);

    let divisorForSeconds = divisorForMinutes % 60;
    let seconds = Math.ceil(divisorForSeconds);

    return {
        "h": hours,
        "m": minutes,
        "s": seconds
    };    
}

const twoDigits = (num) => String(num).padStart(2, '0')