import React, { FunctionComponent } from 'react';
import { LogInLink, RegisterLink } from './styles';
import { useObservableModel } from '../../ilmare/models/ObservableModelState';
import { UserModel } from '../../game/models/UserModel';

type AuthStatusProps = {

}

export const AuthStatus: FunctionComponent<AuthStatusProps> = ({ }) => {
    const userModel = useObservableModel<UserModel>("_userModel");

    if (!userModel.isLoggedIn) {
        return <>
            <LogInLink href="/login">
                Log in
            </LogInLink>
        </>
    } else {
        return <></>
    }

    /*    return <p>
            Welcome {userId}!{" "}
            <button
              onClick={() => {
                auth.signout(() => navigate("/"));
              }}
            >
              Sign out
            </button>
          </p>;*/
    return <p>
        Welcome {userModel.name}!{" "}
    </p>;
}