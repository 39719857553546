import { Socket } from "socket.io-client";
import { GameController } from "../../ilmare/controllers/GameController";
import { ChooseMachineAction } from "../actions/machines/ChooseMachineAction";
import { UpdateMachinesAction } from "../actions/machines/UpdateMachinesAction";
import { ConnectToServerAction } from "../actions/setup/ConnectToServerAction";
import { GetConfigAction } from "../actions/setup/GetConfigAction";
import { ListenToServerAction } from "../actions/setup/ListenToServerAction";
import { ActionsManager } from "../managers/ActionsManager";
import { EventListenerService } from "../services/EventListenerService";
import { ServerService } from "../services/ServerService";
import { GetMinigamesAction } from "../actions/minigames/GetMinigamesAction";
import { OnFirebaseLoginAction } from "../actions/user/OnFirebaseLoginAction";
import { RestoreStateAction } from "../actions/state/RestoreStateAction";

// TODO: tal vez sea mejor crear actions en vez de controllers como se hace del lado del server
// Los actions se tienen que poder injectar y quedar a la escucha de eventos del server o eventos del juego
// 

// TODO: meter esto en un action
export class DataController extends GameController {

    protected _server: ServerService = undefined; // injects
    protected _eventListener: EventListenerService = undefined; // injects    
    protected _actionsManager: ActionsManager = undefined; // injects

    public setup() {

    }

    public start() {
        //let socket: Socket = this._server.connect();

        this._actionsManager.queueAction(ConnectToServerAction);
        this._actionsManager.queueAction(ListenToServerAction);
        this._actionsManager.queueAction(OnFirebaseLoginAction);
        this._actionsManager.queueAction(GetConfigAction);
        this._actionsManager.queueAction(UpdateMachinesAction);
        this._actionsManager.queueAction(GetMinigamesAction);
        this._actionsManager.queueAction(RestoreStateAction);

    }

    protected onAuthorized(eventName, args) {
    }

    public destroy() {
        
    }

}