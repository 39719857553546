import { GameEvent } from "../../events/MainSceneEvent";
import { ActionsManager } from "../../managers/ActionsManager";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { CurrentQueueModel } from "../../models/CurrentQueueModel";
import { Action } from "../Action";
import { ChooseMachineAction } from "../machines/ChooseMachineAction";
import { ServerAction } from "../ServerAction";

export class LeaveCurrentQueueAction extends ServerAction {    
    protected _currentQueueModel: CurrentQueueModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects

    constructor(machineId: string) {        
        super("leave-current-queue", "leave-current-queue-completed", { machineId: machineId });
    }

    public setup(): void {        
        console.log("Leave Current Queue Action");        
    }

    protected payload(): void {        
        super.payload();
    }

    protected processResponse(evtData: any) {        

        switch (evtData.result) {
            case "queue-left":
                this._currentQueueModel.reset();
                let matches = window.location.pathname.match(/(\/machine\/view\/)(.*)/);
                if (!matches) {
                    matches = window.location.href.match(/(\/machine\/view\/)(.*)/);
                }
                if (matches !== null) { // is inside a machine
                    this._actionsManager.executeActionInstant(ChooseMachineAction, matches[2]);
                } else {
                    // outside of a machine, do nothing
                }                
                break;
            case "cannot-leave-queue":
                // TODO: show error
                break;                
        }

    }

    public destroy(): void {
        
    }

}
