import React, { useState } from 'react';
import FileUploader from "./FileUploader";
import { BOCreateCollectionAction } from '../../../game/actions/backOffice/collections/BOCreateCollectionAction';
import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { BOImportAnimationAction } from '../../../game/actions/backOffice/import/BOImportAnimationAction';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { UserModel } from '../../../game/models/UserModel';

const ImportAnimation: React.FC = () => {
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const [minigameName, setMinigameName] = useState<string>('dressup');
    const userModel = useObservableModel<UserModel>('_userModel');

    const handleMinigameNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMinigameName(event.target.value);
    };

    const handleJsonFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
            const text = event.target?.result;
            try {
                const json = JSON.parse(text as string);
                await processSkins(json);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        };
        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };
        reader.readAsText(file);
    }

    const processSkins = async (jsonData: any) => {
        // TODO: leer el valor del input del nombre del minigame        


        if (jsonData && jsonData.skins) {
            let collections = { };
            jsonData.skins.forEach((skin: any) => {
                //await actionsManager.executeActionInstant(BOCreateCollectionAction, values);
                console.log("Skin Name:", skin.name);
                console.log("Attachments:", skin.attachments);
                console.log("Minigame:", minigameName)

                if (skin.name == "default") { // Ignore default skin
                    return;
                }

                let collectionName = skin.name.split('/')[0];
                let prizeName = skin.name.split('/')[1];

                if (collections[collectionName] === undefined) {
                    collections[collectionName] = {
                        name: "TEMP_" + collectionName,
                        description: "TEMP_" + collectionName,
                        slotName: collectionName,
                        path: "DEPRECATED",
                        prizes: []
                    };
                }

                collections[collectionName].prizes.push(prizeName);
            });

            let data = {};
            data[minigameName] = collections;

            await actionsManager.executeActionInstant(BOImportAnimationAction, data);            
        }
    }

    if (!userModel.isAdmin) {
        return <></>;
    }

    return (
        <div>
            <div>
                <label>Nombre del minigame</label>
                <input type="text" placeholder="dressup" value={minigameName} onChange={handleMinigameNameChange} />
            </div>

            <h3>Animacion</h3>
            <FileUploader onFileLoad={handleJsonFile} />
        </div>
    )
};

export default ImportAnimation;