import { FaPlus } from "react-icons/fa"
import styled from "styled-components"

export const CardsContainer = styled.section`        
    padding: 1rem;
    display: flex;    
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 41rem;
    justify-content: center;
`

export const MainCardContainer = styled.section`    
    width: 30rem;
    padding: 1rem;
    background: #007cff12;
    margin: 0.2rem;
    border-radius: 0.5rem;
`

export const ContentCardContainer = styled.section`    
    width: 100%;
    display: flex;
`

export const TitleContentCardContainer = styled(ContentCardContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`   

export const CardContainerColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
`

export const CardImageContainer = styled(CardContainerColumn)`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;    
`

export const CardImage = styled.img`
    width: 7rem;
`


export const CardDescriptionContainer = styled(CardContainerColumn)`
    flex-grow: 1;
    width: 10rem;    
`

export const CardButtonContainer = styled(CardContainerColumn)`
    flex-grow: 1;    
`

export const CardTitleContainer = styled.h3`
    flex-grow: 1;
    align-items: center;
    margin: 0;
`
