import { ActionsManager } from "../../managers/ActionsManager";
import { UserModel } from "../../models/UserModel";
import { Action } from "../Action";
import { GetUserDetailsAction } from "./GetUserDetailsAction";
import { GetAllWonPrizesAction } from "./inventory/GetAllWonPrizesAction";
import { UpdateCreditsAction } from "./UpdateCreditsAction";
import { UpdateCurrentQueueAction } from "./UpdateCurrentQueueAction";

export class OnUserAuthorizedAction extends Action {
    protected _userModel: UserModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects
    
    protected _role: string;
    protected _id: string;
    
    public constructor(data) {
        super();
        this._role = data.role;
        this._id = data.id;
    }

    public setup(): void {
        
    }

    protected payload() {        
        console.log('### OnUserAuthorizedAction.payload', this._role, this._id)
        this._userModel.authorize(this._id, this._role);

        switch (this._role) {
            case 'admin':                
            case 'user':
                this._actionsManager.executeActionInstant(UpdateCreditsAction);
                this._actionsManager.executeActionInstant(UpdateCurrentQueueAction);
                this._actionsManager.executeActionInstant(GetUserDetailsAction);
                this._actionsManager.executeActionInstant(GetAllWonPrizesAction); // TODO: esta llamada es pesada y deberia hacerse paginada cuando sea necesario.                
                break;

        }
        this.finish(true);
    }

    public destroy(): void {
        
    }

}