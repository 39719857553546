import BaseApplication from "../../../../ilmare/BaseApplication";
import { BaseGameScene } from "../../../../ilmare/scenes/BaseGameScene";
import { DressUpLoadResourcesScene } from "./DressUpLoadResourcesScene";


export class DressUpBootScene extends BaseGameScene {
    constructor(owner: BaseApplication) {        
        super(owner, "DressUpBootScene");
    }

    public setup() {
        this._owner.createScene(
            DressUpLoadResourcesScene            
        );
    }

    public destroy() {
        // Implement your destroy logic here
    }

}

