import { CurrentQueueModel } from "../../models/CurrentQueueModel";
import { ServerAction } from "../ServerAction";

export class UpdateCurrentQueueAction extends ServerAction {
    protected _currentQueueModel: CurrentQueueModel = undefined; // injects

    constructor() {
        super("update-current-queue", "update-current-queue-completed", { });
    }

    public setup(): void {
        console.log("Update Current Queue Action");
    }

    protected processResponse(evtData: any) {
        console.log("### UpdateCurrentQueueAction.processResponse", evtData)
        switch (evtData.result) {
            case "queue-updated":
                this._currentQueueModel.update(evtData);
                break;
            case "not-in-a-queue":
                this._currentQueueModel.reset();
                break;                
        }
    }

    public destroy(): void {
        
    }

}