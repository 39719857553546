import { GameModel } from "../../ilmare/models/GameModel";

// generate a type for the CreditsBundle. It should have the following properties:
// id: string
// title: string
// description: string
// image: string
// path: string
// price: number
// credits: number
//
export type CreditsBundle = {
    id: string;
    title: string;
    description: string;
    image: string;
    path: string;
    price: number;
    currency: string;
    credits: number;
}

export class CreditsBundlesModel extends GameModel {
        
        protected _bundles: { [id: string]: CreditsBundle } = {};
        
        public setup() {
            
        }
    
        public getBundleById(id: string) {        
            if (!this._bundles[id]) {
                return null;    
            }
            return this._bundles[id];
        }
    
        public get bundles(): CreditsBundle[] {
            return Object.values(this._bundles);            
        }

        public populate(data: any) {
            for (let bundleData of data) {                
                this._bundles[bundleData.id] = bundleData;
            }
            this.notifyChange();
        }

        public addBundle(id: string, bundle: CreditsBundle) {
            this._bundles[id] = bundle;
            this.notifyChange();
        }
    
        public destroy() {
            
        }
}