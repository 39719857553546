import React, { FunctionComponent, useState } from "react";
import { FilterButton } from "./styles";
import { useObservableModel } from "../../../ilmare/models/ObservableModelState";
import { MinigamesModel } from "../../../game/models/MinigamesModel";

type MachinesListFiltersProps = {
    onFilterChange: (filters: FilterOptionsType) => void;
};

export type FilterOptionsType = {
    [key: string]: boolean;
};

type FilterOption = {
    id: string;
    name: string;
};

export const MachinesListFilters: FunctionComponent<MachinesListFiltersProps> = ({ onFilterChange }) => {

    const minigamesModel = useObservableModel<MinigamesModel>('_minigamesModel');
    const [selectedFilters, setSelectedFilters] = useState({});

    const handleFilterChange = (filterName: string) => {
        const isChecked = !selectedFilters[filterName];
        const newFilters = { ...selectedFilters, [filterName]: isChecked };
        setSelectedFilters(newFilters);
        onFilterChange(newFilters);
    };

    // filter options deberia ser cada minigame
    let filterOptions: FilterOption[] = [ ] ;
    minigamesModel.allMinigames.forEach(minigame => {
        filterOptions.push({
            id: minigame.id,
            name: minigame.name
        });
    });

    return (
        <div>
            {filterOptions.map(filterOption => (
                <FilterButton
                    key={filterOption.id}
                    isSelected={selectedFilters[filterOption.id] || false}
                    onClick={() => handleFilterChange(filterOption.id)}
                >
                    {filterOption.name}
                </FilterButton>
            ))}
        </div>
    );
};
