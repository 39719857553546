import { useEffect, useState } from 'react';
import { GameModel } from './GameModel';
import { useInjection } from '../../game/reactHooks/InjectHooks';

export function useObservableModel<T extends GameModel>(modelId: string) {
    
    const gameModel: T = useInjection<T>(modelId);
    
    const [, forceRender] = useState({}); 

    useEffect(() => {
        const handleChange = () => {
            forceRender({}); 
        };

        gameModel.subscribe(handleChange);

        return () => {
            gameModel.unsubscribe(handleChange);
        };
    }, [gameModel]);

    return gameModel;
}

export function useObservableModelInstance<T extends GameModel>(modelInstance: T) {
    
    const [, forceRender] = useState({}); 

    useEffect(() => {
        const handleChange = () => {
            forceRender({}); 
        };

        modelInstance.subscribe(handleChange);

        return () => {
            modelInstance.unsubscribe(handleChange);
        };
    }, [modelInstance]);

    return modelInstance;
}
