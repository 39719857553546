export abstract class Ticker {

    protected _timer: any;    

    constructor() {
        
    }

    protected abstract get timeInterval(): number;

    public start() {
        this._timer = setInterval(() => { 
            this.tick();
        }, this.timeInterval);
    }

    protected abstract tick(): void;
    
    public stop() {
        clearInterval(this._timer);
    }

}


