import React from "react";
import { ShowCreditsBundlesAction } from "../../../game/actions/credits/ShowCreditsBundlesAction";
import { CreditsModel } from "../../../game/models/CreditsModel";
import { useObservableModel } from "../../../ilmare/models/ObservableModelState";
import { CreditsIcon } from "./styles";
import { BaseCreditsDisplay } from "./BaseCreditsDisplay";

export const CreditsDisplay: React.FC<{ amount }> = ({ amount}) => {
    
    return <BaseCreditsDisplay icon={<CreditsIcon/>} amount={amount} />;
};