import { ActionsManager } from "../../../managers/ActionsManager";
import { CurrentMachineModel } from "../../../models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../../models/MachinesModel";
import { PrizeCollectionModel } from "../../../models/PrizeCollectionModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { ServerAction } from "../../ServerAction";

export class BOImportAnimationAction extends ServerAction {        
    constructor(data: any) {        
        super("bo-import-animation", "bo-import-animation-completed", { data: data });
    }
    
    public setup(): void {
        console.log("BO Import Animation Action");
    }

    protected processResponse(evtData: { result: string, data: any }) {
        if (evtData.result === "animation-imported") {
            // refresh the page

        }        
    }

    public destroy(): void {
        
    }

}