import React, { FunctionComponent, useState } from "react";
import { FilterOptionsType, MachinesListFilters } from "./MachinesListFilters";
import { MachineListItem } from "./MachineListItem";
import { MachinesModel } from "../../../game/models/MachinesModel";
import { useObservableModel } from "../../../ilmare/models/ObservableModelState";
import { StyledMachinesListContainer, StyledMachinesListGrid, StyledTabContainer } from './styles';
import { PrizeCollectionModel } from "../../../game/models/PrizeCollectionModel";
import { MinigamesModel } from "../../../game/models/MinigamesModel";

type MachinesListProps = {}

export const MachinesList: FunctionComponent<MachinesListProps> = () => {
    const machinesModel = useObservableModel<MachinesModel>('_machinesModel');
    const minigamesModel = useObservableModel<MinigamesModel>('_minigamesModel');
    const rows = [];

    const [filters, setFilters] = useState<FilterOptionsType>({});

    const handleFilterChange = (newFilters: FilterOptionsType) => {
        setFilters(newFilters);

        // Lógica para filtrar la lista de máquinas
    };

    const filteredMachines = machinesModel.machines.filter(machine => {
        for (const filter in filters) {
            let minigame = minigamesModel.getMinigameById(filter);
            if (filters[filter] && minigame.prizeCollections.indexOf(machine.prizesCollection) == -1) {
                return false;
            }
        }
        // Lógica de filtrado basada en 'filters'
        return true; // Cambiar con la lógica real
    });

    /*machinesModel.machines.forEach((machine) => {
        rows.push(
            <MachineListItem id={machine._id} key={machine._id} machine={machine} />
        );
    });*/

    return (
        <>
            <main>
                <StyledTabContainer>
                    <StyledMachinesListContainer>
                        <MachinesListFilters onFilterChange={handleFilterChange} />

                        <StyledMachinesListGrid>
                            {filteredMachines.map(machine => (
                                <MachineListItem key={machine._id} id={machine._id} machine={machine} />
                            ))}
                        </StyledMachinesListGrid>
                    </StyledMachinesListContainer>
                </StyledTabContainer>
            </main>
            <nav>
                {/* Navegación si es necesaria */}
            </nav>
        </>
    );
}
