import React, { FunctionComponent } from 'react';
import { UserNavigationBarContainer, UserNavigationBarInnerContainer, UserNavigationBarItemImage, UserNavigationBarItemLink } from './styles';
import { UserNavigationBarItem, UserNavigationBarItemTypes } from './UserNavigationBarItem';
import { useObservableModel } from '../../ilmare/models/ObservableModelState';
import { UserModel } from '../../game/models/UserModel';




type UserNavigationBarProps = {

}

export const UserNavigationBar: FunctionComponent<UserNavigationBarProps> = () => {

    const userModel = useObservableModel<UserModel>("_userModel");

    if (userModel.isLoggedIn) {
        return (
            <UserNavigationBarContainer>
                <UserNavigationBarInnerContainer>
                    <UserNavigationBarItem type={UserNavigationBarItemTypes.HOME}/>
                    <UserNavigationBarItem type={UserNavigationBarItemTypes.LEADERBOARDS}/>
                    <UserNavigationBarItem type={UserNavigationBarItemTypes.INVENTORY}/>
                    <UserNavigationBarItem type={UserNavigationBarItemTypes.USER_PROFILE}/>
                    <UserNavigationBarItem type={UserNavigationBarItemTypes.DOLL_DRESSUP_MINIGAME}/>

                </UserNavigationBarInnerContainer>
            </UserNavigationBarContainer>
        );
    } else {
        return <></>
    }

}