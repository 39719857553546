import { ConfigModel } from "../../models/ConfigModel";
import { ServerAction } from "../ServerAction";

export class GetConfigAction extends ServerAction {    
    protected _configModel: ConfigModel = undefined; // injects

    constructor(machineId: string) {        
        super("get-config", "get-config-completed", { });
    }

    public setup(): void {        
        console.log("Get Config");        
    }

    protected payload(): void {        
        super.payload();
    }

    protected processResponse(evtData: any) {        
        this._configModel.populate(evtData);
    }

    public destroy(): void {
        
    }

}
