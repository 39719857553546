import React, { useState, useEffect, FunctionComponent } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { UserModel } from '../../../game/models/UserModel';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { MachineDescriptor, MachinesModel } from '../../../game/models/MachinesModel';
import { BOEditMachineAction } from '../../../game/actions/backOffice/machines/BOEditMachineAction';
import { Loading } from '../../loading/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { BOAddButton, BOCollectionRow, BOContainer, BOError, BOFixedSizeImage, BOFixedSizeImageFromAtlas, BOFormButton, BOLabel, BOMachineRow, BOPrizeGrid, BOPrizeItem, BORemoveButton, BOStyledForm, BOTitle } from '../styles';
import { PrizeCollectionsModel } from '../../../game/models/PrizeCollectionsModel';
import { GetAllPrizeCollectionsAction } from '../../../game/actions/user/inventory/GetAllPrizeCollectionsAction';
import { PrizeCollectionModel } from '../../../game/models/PrizeCollectionModel';
import ManifestFS from '../../../manifest/ManifestFS';
import Path from '../../../utils/Path';
import { BOEditCollectionAction } from '../../../game/actions/backOffice/collections/BOEditCollectionAction';
import { ModalPopupModel } from '../../../game/models/ModalPopupModel';
import { InfoPopupPreset } from '../../modal/presets/InfoPopupPreset';
import { useActionOnMount } from '../../../game/reactHooks/actionHooks';
import InputFormField from '../common/InputFormField';
import OptionsFormField from '../common/OptionsFormField';
import { usePrizeCollectionsCss } from '../../../game/reactHooks/cssHooks';
import { BOAutoFillCollectionAction } from '../../../game/actions/backOffice/collections/BOAutoFillCollectionAction';

interface PrizeCollectionEditorEditProps {

}


export const PrizeCollectionEditorEdit: FunctionComponent<PrizeCollectionEditorEditProps> = (props) => {
    const prizeCollections = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');
    const userModel = useObservableModel<UserModel>('_userModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const modalPopupModel = useObservableModel<ModalPopupModel>('_modalPopupModel');
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const { id } = useParams<"id">();
    const [loaded, setLoaded] = useState<boolean>(false);

    usePrizeCollectionsCss("all", setLoaded);


    useActionOnMount(GetAllPrizeCollectionsAction);

    if (!userModel.isAdmin) {
        return <></>;
    }

    if (!loaded) {
        return <Loading />;
    }

    const collection: PrizeCollectionModel = prizeCollections.getCollectionById(id);
    if (!collection) {
        return <></>;
    }

    const collectionPaths = ManifestFS.getDirs('assets/collections').map((dir) => { return { id: 'assets/collections/' + dir, title: dir } });

    return (
        <div>
            <h1>Editar Colección de Premios</h1>
            {loading ? (
                <Loading />
            ) : error ? (
                <p>{error}</p>
            ) : (
                <>
                    <Formik
                        initialValues={{
                            id: collection.id,
                            title: collection.title,
                            description: collection.description,
                            image: collection.image,
                            path: collection.path,
                            slotName: collection.slotName,
                        }}
                        enableReinitialize={true}
                        onSubmit={async (values, { setSubmitting }) => {
                            setLoading(true);
                            await actionsManager.executeActionInstant(BOEditCollectionAction, values);
                            modalPopupModel.initWithContent(InfoPopupPreset, { title: 'Colección editada', message: 'La colección se ha editado correctamente' });
                            setLoading(false);
                        }}
                    >
                        {({ values, setFieldValue }) => (
                            <BOStyledForm>
                                <InputFormField label="Título" name="title" />
                                <InputFormField label="Descripción" name="description" />
                                <InputFormField label="Slot" name="slotName" />

                                <OptionsFormField label="Path" name="path" options={collectionPaths} onChange={(e) => {
                                    setFieldValue('image', ManifestFS.getFiles(e.target.value)[0]);
                                }} />
                                <BOLabel>{values.path}</BOLabel>

                                <OptionsFormField label="Imagen" name="image" options={ManifestFS.getFiles(values.path).map((file) => { return { id: file, title: file } })} />
                                <BOFixedSizeImage src={values.path + '/' + values.image} alt={values.title} />

                                <h2>Premios</h2>
                                <BOLabel>{Path.join(values.path, 'prizes')}</BOLabel>
                                <BOPrizeGrid>
                                    {collection.prizes.map((prize, index) => (
                                        <BOPrizeItem key={index}>
                                            <BOFixedSizeImageFromAtlas className={prize.cssClass} />
                                            <strong>{prize.title}</strong>
                                        </BOPrizeItem>
                                    ))}
                                </BOPrizeGrid>


                                <ErrorMessage name="prizes" component={BOError} />

                                <BOFormButton type="submit">Guardar</BOFormButton>

                                <BOAddButton type="button" onClick={async () => {
                                    let prizeTitles = collection.prizes.map((prize) => prize.title);
                                    await actionsManager.executeActionInstant(BOAutoFillCollectionAction, { id: values.id, slotName: values.slotName, prizeTitles: prizeTitles })
                                }}>AI Auto Fill</BOAddButton>                                
                            </BOStyledForm>
                        )}
                    </Formik>
                </>
            )}
        </div>
    )
}