import { FaArrowCircleLeft, FaBackward, FaEye, FaHome, FaInfo, FaPlus, FaPlusCircle, FaUserFriends } from "react-icons/fa"
import styled, { keyframes } from "styled-components"

const gradientAnimation = keyframes`
  0% {
    background-position: 300% 50%;
    filter: brightness(100%);
  }
  10% {
    background-position: 200% 50%;
    filter: brightness(130%);
  }
  20% {
    background-position: 100% 50%;
    filter: brightness(100%);
  }
  100% {
    background-position: 100% 50%;
    filter: brightness(100%);
  }
`;


export const ActionButton = styled.button`
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: center; // Centra horizontalmente
    align-items: center; // Centra verticalmente    
    background-color: rgb(127, 36, 97);
    background-image: linear-gradient(100deg, transparent 18%, rgba(255, 255, 255, 0.1) 29%, rgba(255, 255, 255, 0.1) 30%, rgba(255, 255, 255, 0.1) 31%, transparent 42%);
    background-size: 200% 150%;    
    font-weight: 800;
    color: white;
    text-transform: none;
    border-width: 1px 1px 0.5rem;
    border-style: solid;
    border-image: initial;
    border-radius: 0.5rem;
    border-color: rgb(99, 27, 78);    
    font-size: 1.125rem;
    user-select: none;
    -webkit-user-drag: none;    
    cursor: pointer;
    animation: ${gradientAnimation} 6s linear infinite;

    &:hover{        
        border-width: 3px 3px 0.5rem;
    }
`

export const PlayActionButton = styled(ActionButton)`
    background-color: rgb(17, 212, 43);
    gap: 1em;
`

export const StartActionButton = styled(ActionButton)`
    background-color: rgb(220, 120, 53);
    animation: ${gradientAnimation} 3s linear infinite;
`

export const ReserveActionButton = styled(ActionButton)`
    background-color: rgb(127, 36, 97);
`

export const InQueueActionButton = styled(ActionButton)`
    background-color: rgb(149, 172, 211);
    animation: none;
    
`

export const WaitingActionButton = styled(ActionButton)`
    background-color: rgb(149, 211, 172);
    animation: none;
    
`

export const CancelActionButton = styled(ActionButton)`
    background-color: rgb(245, 245, 245);
    border: none;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: rgb(177, 177, 177);
    animation: none;
`

export const InfoActionButton = styled.button`
    display: block;
    border: none;
    padding: 0.6rem 1rem;
    color: white;
    width: 8rem;
    margin: 0px auto 0px 0px;
    background-color: rgb(73, 22, 83);
    border-radius: 10rem;
    font-size: 0.75rem;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
    appearance: button;

    width: 100%;
    font-size: 0.875rem;
    margin: 0px auto 0.75rem;
    padding: 0.75rem 1rem
`


export const PlayAreaContainer = styled.div`
    @media (orientation: landscape) {
        max-width: 480px;
    }
    @media (orientation: portrait) {
        max-width: 90%;
    }    
    margin: 0px auto;
    pointer-events: none; // desactiva eventos de mouse sobre la animacion de la maquina
    
`

export const MachineAnimationContainer = styled.div`
    position: relative;
    padding-top: 0px;
    overflow: hidden;
    background-color: rgb(50, 34, 57);

    @media (orientation: landscape) {
        width: 100%;
        height: 100%;
    }
    @media (orientation: portrait) {
        width: 100%;
        height: 100%;
    }     
`

export const ButtonAreaContainer = styled.div`
    background-color: white;
    padding: 1rem 1rem 0px;
`

export const ActionButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 0.5rem;
    flex-direction: column;
`

export const GameStatusContainer = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
`

export const GameStatusItemContainer = styled.div`
    flex: 1 1 0%;
    padding: 0px 0.75rem;
    margin: 0.5rem;
`

export const GameStatusItemInnerContainer = styled.div`
    display: flex;
    flex-flow: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 700;
    font-size: 0.875rem;
`

export const GameStatusItemNumber = styled.span`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    border-radius: 5rem;
    background-color: rgb(245, 245, 245);
    color: rgb(83, 83, 83);
`

export const GameStatusItemText = styled.div`
`
export const OverlayContainer = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    padding-top: 0px;
    overflow: hidden;
    background-color: rgb(50, 34, 57);
    z-index: 1;
`

export const WindowWrapper = styled.div`
    display: block;
    overflow: visible;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    background-color: transparent;

`

export const WindowContainer = styled.div`

    position: flex;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    padding-top: 0px;
    overflow: hidden;
    background-color: rgb(245, 245, 245);
    z-index: 1;
`

export const MachineInfoTitle = styled.h1`
    color: rgb(53, 41, 64);
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
`

export const MachineInfoDescription = styled.p`
    color: rgb(51, 51, 51);
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    padding-left: 1rem;
`

export const MachineInfoPrizesTitle = styled.h2`
    color: rgb(51, 51, 51);
    font-size: 0.975rem;
    margin-bottom: 0.25rem;
    padding-left: 1rem;
    padding-top: 1rem;
`


export const PrizesListContainer = styled.div`
    flex: 1 1 0%;
    display: grid;
    gap: 1rem;
    grid-auto-rows: max-content;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    overflow: auto;
    background-color: rgb(245, 245, 245);
    padding: 1rem;
`

export const PrizeItemContainer = styled.div`
    width: 100%;
    display: flex;    
    -webkit-box-align: center;
    align-items: center;
`

export const PrizeItemIcon = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 0rem;
    border-style: none;
    overflow-clip-margin: content-box;
    overflow: clip;
`

export const PrizeItemTitle = styled.div`
    margin-left: 0.75rem;
    color: rgb(57, 57, 57);
    font-size: 16px;
    font-weight: 700;
`

export const PrizeItemProbability = styled.div`
    margin-left: 0.75rem;
    color: rgb(155, 57, 57);
    font-size: 24px;
    font-weight: 900;
`


export const AreasContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (orientation: landscape) {
    flex-direction: row;
    flex-wrap: wrap; // Permite que los elementos se envuelvan según el espacio disponible
  }
`;

export const InfoAreaContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
  height: 100%;
  justify-content: center; 

  > * {
    flex: 0 0 auto; // Esto hace que cada hijo tenga el mismo ancho
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoCapsule = styled.div`
  display: flex;
  align-items: center;
  background-color: #f2f2f2; // Un color de fondo genérico, ajústalo a tu gusto
  padding: 5px 10px;
  border-radius: 20px; // Esto da el aspecto de cápsula
  margin: 5px;  

  width: 7em;
`;

export const InfoCapsuleText = styled.span`
    margin-left: 20px;
    font-size: 1em;
    font-weight: 800;    
`;

export const InfoCapsuleEyeIcon = styled(FaEye)`
  width: 1.5em;
  height: 1.5em;
`;

export const InfoCapsuleQueueIcon = styled(FaUserFriends)`
  width: 1.5em;
  height: 1.5em;
`;

export const InfoButtonIcon = styled(FaInfo)`
  width: 1.5em;
  height: 1.0em;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`;


export const InfoButtonContainer = styled.div`
`  

export const InfoInnerButton = styled.button`
    grid-area: button / button / button / button;
    text-align: center;
    padding-top: 0.3rem;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    color: rgb(255, 255, 255);
    background-color: rgb(68, 68, 188);
    border-bottom: 0.1875rem solid rgb(78, 78, 198);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0.625rem;
    cursor: pointer;
`

export const BackToHomeButtonIcon = styled(FaHome)`
  width: 1.8em;
  height: 1.3em;
`;

export const BackToHomeButtonContainer = styled.div`
`  

export const BackToHomeInnerButton = styled.button`
    grid-area: button / button / button / button;
    text-align: center;
    padding-top: 0.3rem;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    color: rgb(242, 242, 242);
    background-color: rgb(154, 154, 192);
    border-bottom: 0.1875rem solid rgb(78, 78, 128);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0.625rem;
    cursor: pointer;
`

export const RelativeContainer = styled.div`
  position: relative;  
`  

export const Grid3ColumnContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1em; 
    width: 100%;
`;