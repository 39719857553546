import { PRNG } from "seedrandom";
import { GameActor } from "../../../ilmare/actors/GameActor";
import { GameEvent } from "../../events/MainSceneEvent";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../models/MachinesModel";
import { PrizeModel } from "../../models/PrizeModel";
import { UserModel } from "../../models/UserModel";
import { EventListenerService } from "../../services/EventListenerService";
import { IAnimationStateListener, IEvent, ITrackEntry, Spine } from '../../../ilmare/spine/SpineFacade';
import { Assets, Sprite } from "pixi.js";

export interface IManualAnimationDef {
    totalDuration: number,
    trackEntry: ITrackEntry
}

export class BallActor extends GameActor implements IAnimationStateListener {

    protected _resources: any;

    protected _eventListener: EventListenerService = undefined; // injects
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects    
    protected _userModel: UserModel = undefined; // injects

    protected _ball: Spine;
    protected _colorAnimation: string;
    protected _invertedColor: boolean = false;

    protected _container: any;    

    constructor(resources: any, colorAnimation: string, container: any) {
        super();
        this._resources = resources;
        this._colorAnimation = colorAnimation;
        this._container = container;        
    }

    public setup() {
        this.loadBall();
    }
    
    protected loadBall() {
        if (this._ball) {
            this._ball.destroy();
        }

        this._ball = new Spine(this._resources.ball.spineData)
        this._ball.x = 0;
        this._ball.y = 0;
        this._ball.scale.set(0.38);
        this._ball.state.timeScale = 4;        
        this._ball.state.setAnimation(0, "idle", true);
        /*this.initializeSpineAnimationAtPercent(this._ball, "rotations/simple", 0, 2);
        this.initializeSpineAnimationAtPercent(this._ball, "rotations/3d", 0, 3);*/
        //this._ball.state.setAnimation(2, "rotations/simple", false);
        //this._ball.state.setAnimation(3, "rotations/3d", false);
        //this.updateColorAnimation();
        this._ball.state.addListener(this);
        this._ball.autoUpdate = false;
        //this._owner.addChild(this._ball);
        this._ball.setParent(this._container);

    }

    event(entry: ITrackEntry, event: IEvent): void {
        if (event.data.name == "swapColors") {
            //console.log("SWAP COLORS");
            this._invertedColor = !this._invertedColor;

            this.updateColorAnimation();
        }
    }

    updateColorAnimation() {
        let path = "colors/";
        this._invertedColor ? path += "inverted/" : path += "normal/"; 
        let track = this._ball.state.setAnimation(0, path + this._colorAnimation, true);
        track.mixDuration = 0;
        this._ball.update(0);
    }

    public update(delta: number) {

    }

    protected onSwapColors(e) {
        console.log("swapColors", e);
    }

    /*protected onStartOtherPrizePresentation(wonPrize) {

        this._prize.state.setAnimation(0, "show_other", false);
        this._prize.state.setAnimation(1, "loop", true);
        this._prize.visible = true;

        let prize = this._prize;
        this._prize.state.tracks[0].listener = { 
            complete: function(trackEntry) { 
                prize.visible = false;
            }
        }        
        
    }*/

    public destroy() {        
        this._ball.destroy();
    }

    public onResize(canvasWidth: number, canvasHeight: number) {
    }

    initializeSpineAnimationAtPercent(spineAnimation, animationName, startPercent, trackIndex): IManualAnimationDef {
        const animation = spineAnimation.skeleton.data.findAnimation(animationName);
    
        // Obtén la duración total de la animación
        const totalDuration = animation.duration;

        // Calcula el tiempo de inicio basado en el porcentaje
        const startTime = totalDuration * startPercent;

        // Establece la animación
        const trackEntry = spineAnimation.state.setAnimation(trackIndex, animationName, true);

        // Configura el tiempo de inicio
        trackEntry.trackTime = startTime;

        // Configura la mezcla para mantener la animación en ese estado
        trackEntry.mixTime = 0;
        trackEntry.mixDuration = 0;
        spineAnimation.update(0);
        //spineAnimation.state.clearTrack(trackIndex);

        return { 
            totalDuration: totalDuration, 
            trackEntry: trackEntry 
        };
    }

    initializeSpineAnimationAt(spineAnimation, animationName, startTime, trackIndex) {
        const animation = spineAnimation.skeleton.data.findAnimation(animationName);
    
        if (animation) {
            const trackEntry = spineAnimation.state.setAnimation(trackIndex, animationName, true);
            
            // Establece el tiempo de inicio de la pista de animación
            trackEntry.trackTime = startTime;
    
            // Configura la mezcla para mantener la animación en ese estado
            trackEntry.mixTime = 0;
            trackEntry.mixDuration = 0;
            spineAnimation.update(0);
            spineAnimation.state.clearTrack(trackIndex);
        } else {
            console.error(`Animación "${animationName}" no encontrada.`);
        }
    }

    public set3DRotation(percent: number) {
        this.initializeSpineAnimationAtPercent(this._ball, "rotations/3d", percent / 100.0, 3);
    }

    public setRotation(percent: number) {
        this.initializeSpineAnimationAtPercent(this._ball, "rotations/simple", percent, 2);
    }
}
