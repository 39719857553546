import { GameModel } from "../../ilmare/models/GameModel";
import { MinigameModel } from "./MinigameModel";


export class MinigamesModel extends GameModel {
    public setup() {
        
    }

    protected _minigames: { [id: string]: MinigameModel } = {};        

    public addMinigame(id: string, minigame: MinigameModel): void {
        this._minigames[id] = minigame;
        this.notifyChange();
    }

    public getMinigameById(id: string): MinigameModel {
        if (!this._minigames[id]) {
            return null;    
        }
        return this._minigames[id];
    }

    public getMinigameByCodename(codename: string): MinigameModel {
        let minigame = Object.values(this._minigames).find((minigame: MinigameModel) => minigame.codename === codename);
        return minigame;
    }

    public get allMinigames(): MinigameModel[] {
        return Object.values(this._minigames);
    }

    public get allMinigameIds(): string[] {
        return Object.keys(this._minigames);
    }

    public updateMinigame(minigame: any) {
        if (!this._minigames[minigame._id]) {
            return;
        }
        this._minigames[minigame._id].populate(minigame);        
    }

    public destroy() {

    }

}
