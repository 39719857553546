import React, { FunctionComponent } from 'react';
import { useNavigate } from "react-router-dom";
import { PlayAtMachineAction } from '../../../game/actions/machines/PlayAtMachineAction';
import { ReserveMachineAction } from '../../../game/actions/machines/ReserveMachineAction';
import { LeaveCurrentQueueAction } from '../../../game/actions/queue/LeaveCurrentQueueAction';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { CurrentMachineModel, CurrentMachineStatus } from '../../../game/models/CurrentMachineModel';
import { MachinesModel } from '../../../game/models/MachinesModel';
import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { EventListenerService } from '../../../game/services/EventListenerService';
import { BackToHomeButtonContainer, BackToHomeButtonIcon, BackToHomeInnerButton } from './styles';
import { ModalPopupModel } from '../../../game/models/ModalPopupModel';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';

type BackToHomeButtonProps = {
    
}

export const BackToHomeButton: FunctionComponent<BackToHomeButtonProps> = ({})=> {

    const navigate = useNavigate();

    return (
        <BackToHomeButtonContainer>
            <BackToHomeInnerButton onClick={
                    (evt) => {
                        navigate("/");                    
                    } 
                }> 
                <BackToHomeButtonIcon />
            </BackToHomeInnerButton>
        </BackToHomeButtonContainer>
    )

}