import { utils } from "pixi.js";
import { IInjectable } from "../../ilmare/di/IInjectable";


export class EventListenerService extends utils.EventEmitter implements IInjectable {    
    public constructor() {
        super();
    }

    public setup() {

    }

    public destroy() {
        
    }
}
