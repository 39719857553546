import React, { FunctionComponent, useState } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { BONavigationBarContainer, BONavigationBarInnerContainer, BONavigationBarItemImage, BONavigationBarItemLink, BOHamburgerIcon, BOHamburgerMenuContainer, BOMenu, BOMenuItem } from '../styles';
import { UserModel } from '../../../game/models/UserModel';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';




type BONavigationBarProps = {

}

export const BONavigationBar: FunctionComponent<BONavigationBarProps> = () => {

    const userModel = useObservableModel<UserModel>('_userModel');
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    if (userModel.isAdmin) {
        return (
            <BOHamburgerMenuContainer>
                <BOHamburgerIcon src="/assets/icons/menu.svg" alt="Menu" onClick={toggleMenu} />
                <BOMenu open={isOpen}>
                    <BOMenuItem onClick={() => navigate("/bo/config")}>Editar Config</BOMenuItem>
                    <BOMenuItem onClick={() => navigate("/bo/machines")}>Editar Machines</BOMenuItem>
                    <BOMenuItem onClick={() => navigate("/bo/collections")}>Editar Collections</BOMenuItem>
                    <BOMenuItem onClick={() => navigate("/bo/prizes")}>Editar Prizes</BOMenuItem>                    
                    <BOMenuItem onClick={() => navigate("/bo/import/animation")}>Import Animation</BOMenuItem>
                </BOMenu>
            </BOHamburgerMenuContainer>
        )
    } else {
        return <></>
    }


}