import { EventListenerService } from "../../game/services/EventListenerService";
import { DependencyInjector } from "./DependencyInjector";

export class DependencyInjectorService {

    protected _injector: DependencyInjector;

    protected static _instance: DependencyInjectorService;
    public static get instance(): DependencyInjectorService
    {
        if (!DependencyInjectorService._instance) {
            DependencyInjectorService._instance = new DependencyInjectorService();
        }
        return DependencyInjectorService._instance;
    }

    protected constructor() {

    }

    public setup() {
        // dependency injection
        this._injector  = new DependencyInjector();

        this._injector.map("_injector", this._injector);

        this._injector.process();
    }

    public get injector(): DependencyInjector {
        return this._injector;
    }

    public injectIntoObject<T>(obj: T): T {
        let anyObj: any = obj;

        if (!Reflect.has(anyObj, "setup")) {
            obj["setup"] = () => {};
        }
        if (!Reflect.has(anyObj, "destroy")) {
            obj["destroy"] = () => {};
        }
        this._injector.initialize(anyObj);
        return obj;
    }

}

