import { SaveMinigameStatusAction } from "../../../actions/minigames/SaveMinigameStatusAction";

export class SaveDressUpStatusAction extends SaveMinigameStatusAction {
    public setup() {

    }

    protected processResponse(evtData) {
        // TODO: check for errors or show a saved message
        console.log('SaveDressUpStatusAction', evtData);
    }


    public destroy() {
    }
}