import { CreditsBundlesModel } from "../../models/CreditsBundlesModel";
import { ServerAction } from "../ServerAction";

export class GetCreditsBundlesAction extends ServerAction {

    protected _creditsBundlesModel: CreditsBundlesModel = undefined; // injects

    constructor() {
        super('get-credits-bundles', 'get-credits-bundles-completed');
    }

    public setup(): void {
        console.log("Get Credits Bundles");
    }

    protected payload(): void {        
        super.payload();
    }

    protected processResponse(evtData: any) {      
        this._creditsBundlesModel.populate(evtData);
    }

    public destroy(): void {
        
    }

}