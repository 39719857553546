import styled from "styled-components"

export const LogInLink = styled.a`
    font-family: Heebo, Helvetica, Arial, sans-serif;
    color: rgb(255, 177, 0);
    font-weight: 700;
    font-size: 0.875rem;
    text-transform: uppercase;
    padding-top: 1px;
    transition: all 0.2s ease 0s;
`

export const RegisterLink = styled.a`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 2rem;
    padding: 1px 0.75rem 0px;
    background: rgb(255, 177, 0);
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 10rem;
    cursor: pointer;
    font-family: Heebo, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    margin-left: 0.5rem;
    font-size: 0.875rem;
    transition: all 0.2s ease 0s;
`