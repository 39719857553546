import { ServerErrorModel } from "../../models/ServerErrorModel";
import { Action } from "../Action";

export class DismissServerErrorAction extends Action {    
    protected _serverErrorModel: ServerErrorModel = undefined; // injects

    public setup(): void {
        console.log("Dismiss Server Error Action");
    }

    protected payload(): void {

        this._serverErrorModel.clear();

        this.finish(true);
    }

    public destroy(): void {
        
    }

}