
import BaseApplication from "../../ilmare/BaseApplication";
import { DependencyInjectorService } from "../../ilmare/di/DependencyInjectorService";
import { BaseGameScene } from "../../ilmare/scenes/BaseGameScene";
import { BackgroundActor } from "../actors/background/BackgroundActor";
import { BallActor } from "../actors/ball/BallActor";
import { BallAtMachineActor } from "../actors/ball/BallAtMachineActor";
import { DebugActor } from "../actors/debug/DebugActor";
import { MachineActor } from "../actors/machine/MachineActor";
import { CurrentMachineModel } from "../models/CurrentMachineModel";
import { MachinesModel } from "../models/MachinesModel";
import { EventListenerService } from "../services/EventListenerService";
import { ServerService } from "../services/ServerService";
import seedrandom, { PRNG } from 'seedrandom';


export class MainScene extends BaseGameScene
{
    protected _server: ServerService = undefined; // injects
    protected _eventListener: EventListenerService = undefined; // injects
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects
    protected _machinesModel: MachinesModel = undefined; // injects

    protected _resources: any;
    protected _rng: PRNG;

    //protected _machinesListActor: MachinesListActor;


    constructor(owner: BaseApplication, resources: any) {
        super(owner, "MainScene");        
        this._resources = resources;
        
    }


    public setup() {

        let backgroundActor = new BackgroundActor(this._resources);
        this.addActor(backgroundActor);

        let machineActor = new MachineActor(this._resources);
        this.addActor(machineActor);

        // initialize the machine

        this._rng = seedrandom(this._currentMachineModel.currentMachineId);
        let machineModel = this._machinesModel.getMachineById(this._currentMachineModel.currentMachineId);
        
        let animationBalls: number = 37;
        let totalPrizes = Object.values(machineModel.initialPrizes).reduce((a, b) => a + b, 0);
        // lastOneIndex should be between animationBalls - 5 and animationBalls - 1
        let possibleLastOnes = [25, 29, 7, 19, 15, 0]; // this comes from the animation
        let lastOneIndex = possibleLastOnes[Math.floor(this._rng.double() * possibleLastOnes.length)];

        // ordeno los premios por cantidad, dejo el last one para el final y los mas comunes al principio
        let sortedPrizesArray = Object.entries(machineModel.initialPrizes)
            .map(([id, quantity]) => ({ id, quantity }))
            .sort((a, b) => b.quantity - a.quantity);


        let bag = {};
        let ballIndex = 0;
        for (let prize of sortedPrizesArray) {            
            if (ballIndex == sortedPrizesArray.length - 1) {
                bag["ballLastOne"] = 1;
                this._currentMachineModel.prizesAnimationTypes[prize.id] = "ballLastOne";
            } else {
                bag["ball" + ballIndex] = Math.floor(prize.quantity * animationBalls / totalPrizes);
                this._currentMachineModel.prizesAnimationTypes[prize.id] = "ball" + ballIndex;
            }            
            ballIndex++;
        }

        ballIndex = 0;
        let currentAnimationBalls: number = Number(Object.values(bag).reduce((a: any, b: any) => a + b, 0));
        while (currentAnimationBalls < animationBalls) {
            bag["ball" + ballIndex] += 1;
            if (ballIndex > sortedPrizesArray.length - 3) {
                ballIndex = 0;
            } else {
                ballIndex++;
            }
            currentAnimationBalls = Number(Object.values(bag).reduce((a: any, b: any) => a + b, 0));
        }

        for (let i = 0; i < animationBalls; i++) {
            let ballName = "";
            if (i == lastOneIndex) {
                bag["ballLastOne"] -= 1;
                ballName = "ballLastOne";
            } else {
                let ballIndex = -1;
                do {
                    ballIndex = Math.floor(this._rng.double() * (sortedPrizesArray.length - 1));
                } while(bag["ball" + ballIndex] <= 0);
                bag["ball" + ballIndex] -= 1;    
                ballName = "ball" + ballIndex;
            }

            let ball = new BallAtMachineActor(this._resources, ballName, machineActor.getBallContainer(i), this._rng);
            DependencyInjectorService.instance.injector.initialize(ball);    
        }

        //this.addActor();
        // add only on debug
        this.addActor(new DebugActor());

    }


    public destroy() {

    }
}
