import { GameEvent } from "../../events/MainSceneEvent";
import { ActionsManager } from "../../managers/ActionsManager";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../models/MachinesModel";
import { PrizeCollectionsModel } from "../../models/PrizeCollectionsModel";
import { Action } from "../Action";
import { ServerAction } from "../ServerAction";
import { GetPrizeCollectionAction } from "../user/inventory/GetPrizeCollectionAction";

export class ChooseMachineAction extends ServerAction {
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects   
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects
    
    constructor(machineId: string) {        
        super("choose-machine", "choose-machine-completed", { machineId: machineId });
    }
    
    public setup(): void {
        console.log("Choose Machine Action");
    }

    protected processResponse(evtData: any) {
        switch (evtData.result) {
            case "machine-chosen":
                this._currentMachineModel.init(evtData.machineId, evtData.status, evtData.queuePosition, evtData.fillLevel);

                // get the prize collection if it's not loaded yet
                let descriptor: MachineDescriptor = this._machinesModel.getMachineById(evtData.machineId);
                if (!this._prizeCollectionsModel.getCollectionById(descriptor.prizesCollection)) {
                    this._actionsManager.executeActionInstant(GetPrizeCollectionAction, {collectionId: descriptor.prizesCollection});
                }                
                break;
            case "already-playing-another-machine":
                break;                
        }

    }

    public destroy(): void {
        
    }

}