export default {
    "assets": {
        "backgrounds": {
            "sectionHeader.svg": true
        },
        "balls": {
            "ball_1.atlas": true,
            "ball_1.json": true,
            "ball_1.png": true
        },
        "bundles": {
            "bundle0.png": true,
            "bundle1.png": true,
            "bundle2.png": true,
            "bundle3.png": true,
            "bundle4.png": true,
            "bundle5.png": true
        },
        "collections": {
            "dressup": {
                "dressup-0.png": true,
                "dressup.css": true,
                "dressup.json": true
            },
            "pets": {
                "pets.png": true
            },
            "plushies": {
                "plushies - copia - copia.png": true,
                "plushies - copia.png": true,
                "plushies.png": true,
                "prizes": {
                    "bunny.png": true,
                    "bunny_locked.png": true,
                    "cat.png": true,
                    "cat_locked.png": true,
                    "edi.png": true,
                    "elephant.png": true,
                    "elephant_locked.png": true,
                    "elk.png": true,
                    "elk_locked.png": true,
                    "fox.png": true,
                    "fox_locked.png": true,
                    "hamster.png": true,
                    "hamster_locked.png": true,
                    "horse.png": true,
                    "horse_locked.png": true,
                    "ladybug.png": true,
                    "ladybug_locked.png": true,
                    "lion.png": true,
                    "lion_locked.png": true,
                    "monkey.png": true,
                    "monkey_locked.png": true,
                    "penguin.png": true,
                    "penguin_locked.png": true,
                    "rat.png": true,
                    "rat_locked.png": true,
                    "sheep.png": true,
                    "sheep_locked.png": true,
                    "teddy.png": true,
                    "teddy_locked.png": true,
                    "tiger.png": true,
                    "tiger_locked.png": true,
                    "turtle.png": true,
                    "turtle_locked.png": true
                }
            }
        },
        "icons": {
            "add.svg": true,
            "credits.png": true,
            "doll.svg": true,
            "home.svg": true,
            "inventory.svg": true,
            "leaderboards.svg": true,
            "menu.svg": true,
            "settings.svg": true,
            "user.svg": true
        },
        "logo": {
            "logo.svg": true
        },
        "machines": {
            "animations": {
                "background.atlas": true,
                "background.json": true,
                "background.png": true,
                "machine_1.atlas": true,
                "machine_1.json": true,
                "machine_1.png": true,
                "machine_1_back.atlas": true,
                "machine_1_back.json": true,
                "machine_1_back.png": true,
                "machine_1_front.atlas": true,
                "machine_1_front.json": true,
                "machine_1_front.png": true,
                "machine_2.atlas": true,
                "machine_2.json": true,
                "machine_2.png": true,
                "machine_3.atlas": true,
                "machine_3.json": true,
                "machine_3.png": true,
                "machine_4.atlas": true,
                "machine_4.json": true,
                "machine_4.png": true,
                "prize.atlas": true,
                "prize.json": true,
                "prize.png": true
            },
            "thumbnails": {
                "machine_1.jpeg": true,
                "machine_2.jpeg": true,
                "machine_3.jpeg": true,
                "machine_4.jpeg": true
            }
        },
        "minigames": {
            "dressup": {
                "doll.atlas": true,
                "doll.json": true,
                "doll.png": true
            }
        },
        "particles": {
            "shine": {
                "emitter.json": true,
                "star01.png": true,
                "star02.png": true
            }
        }
    }
}