import styled, { css } from 'styled-components';

export const StyledTabContainer = styled.div`
    flex: 1 1 0%;
`;

export const StyledMachinesListContainer = styled.div`
    max-width: 72rem;
    margin: 0px auto;
    padding: 0px 1rem;
`;

export const StyledMachinesListGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-bottom: 1rem;

    @media (orientation: portrait) {        
        grid-template-columns: repeat(1, 1fr);
        gap: 0.25rem;
    }
`;

export const MachinesListGridCell = styled.a`
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: white;
    border-radius: 0.5rem;
    transition: all 0.2s ease 0s;
    filter: grayscale(0%);

    text-decoration: none;
    color: rgb(89, 26, 101);
`;

export const MachineCardImage = styled.div`
    overflow: hidden;
    height: 0px;
    padding-top: 75%;
    background-size: cover;
`;

export const MachineCardInfo = styled.div`
    padding: 0.5rem;
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: white;
    border-radius: 0.5rem;
    transition: all 0.2s ease 0s;
    filter: grayscale(0%); 
`;

export const MachineCardTitle = styled.div`
    font-size: 1.275rem;
    line-height: 1.35rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    display: block;

    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: white;
    border-radius: 0.5rem;
    transition: all 0.2s ease 0s;
    filter: grayscale(0%);
    font-weight: bold;
`;

export const MachineCardStatusContainer = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
`;

type MachineCardStatusProps = {
    hasQueue: boolean;
};

export const MachineCardStatus = styled.div<MachineCardStatusProps>`
    padding: 0.25rem 0px;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.675rem;
    color: ${props => props.hasQueue ? '#df8325' : '#77c67a'};    
`;

export const MachineCardCostContainer = styled.div`
    display: flex; 
    align-items: center;
    background-color: #f8f9fa;
    padding: 0.25em 0.5em;
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 0.5em;
`;

export const MachineCardCost = styled.div`
    font-size: 1em;
    color: black;
    font-weight: bold;    
`;

export const MachineCardCostIcon = styled.img`
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.2em;
`;

export const MachineCardPlayers = styled.div`
    padding: 0.25rem 0px;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.675rem;
    color: gray;
`;

type FilterButtonProps = {
    isSelected: boolean;
};

export const FilterButton = styled.button<FilterButtonProps>`
    background-color: ${props => props.isSelected ? '#007bff' : 'white'};
    color: ${props => props.isSelected ? 'white' : 'black'};
    border: 0.125em solid #007bff; // 2px equivalen aproximadamente a 0.125em
    border-radius: 0.25em; // Ajusta según tus necesidades
    padding: 0.5em 1em; // Ajusta según tus necesidades
    margin: 0.3125em; // Ajusta según tus necesidades
    cursor: pointer;
    outline: none;
    font-size: 1em; // Asegúrate de que el tamaño de fuente sea adecuado

    &:hover {
        background-color: ${props => props.isSelected ? '#0056b3' : '#f8f9fa'};
    }

    ${props => props.isSelected && css`
        font-weight: bold;
    `}
`;
