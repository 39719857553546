import styled from "styled-components"

export const UserNavigationBarContainer = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    overflow-x: hidden;
`

export const UserNavigationBarInnerContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 2.75rem);
    justify-items: center;
    padding: 0.5rem 0px;
`

export const UserNavigationBarItemLink = styled.a`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    transition: all 0.2s ease 0s;
    border-radius: 10rem;

    text-decoration: none;
    color: rgb(89, 26, 101);
    cursor: pointer;    

    background-color: transparent;
`

export const UserNavigationBarItemImage = styled.img`
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    border-style: none;
`