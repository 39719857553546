import styled from "styled-components";

export const DressUpButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width:100%;
`;

export const CategoryList = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    text-align: center;
    margin-top: -1px;
    -webkit-overflow-scrolling: touch;
`;

export const CategoryButton = styled.button<{ isSelected: boolean, backgroundColor: string, selectedBackgroundColor: string }>`
    font-family: Heebo, Helvetica, Arial, sans-serif;
    color: rgb(17, 17, 69);
    font-weight: 700;
    font-size: ${({ isSelected }) => (isSelected ? '1em' : '0.875em')};    

    margin: 0;
    height: ${({ isSelected }) => (isSelected ? '2.1em' : '2em')};
    min-width: ${({ isSelected }) => (isSelected ? '8em' : '7em')};
    max-width: 8em;
    overflow: hidden;
    padding: 0; 
    cursor: pointer;
    border: none;    
    transition: background-color 0.3s;
    font-size: 1rem;
    background-color: ${({ isSelected, backgroundColor, selectedBackgroundColor }) => (isSelected ? selectedBackgroundColor : backgroundColor)};

    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;        
`;

export const ItemList = styled.div<{ backgroundColor: string }>`
    flex: 1;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(auto-fill, 5em);
    gap: 10px;      
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    border-bottom: none;
    background-color: ${({ backgroundColor }) => backgroundColor};
    justify-content: center;
`;

export const Item = styled.div<{ isSelected: boolean }>`
    padding: 10px;
    border-bottom: ${({ isSelected }) => (isSelected ? '10px solid #ff0000;' : '1px solid #ccc;')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ isSelected }) => (isSelected ? '#e0e0e0' : 'transparent')};
`;

export const ItemImage = styled.div`
    width: 100%;
    height: auto;
    display: block;
`;
