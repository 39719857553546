import { ServerEvent } from "../events/ServerEvent";
import { Action } from "./Action";

export abstract class ServerAction extends Action {

    protected _messageToSend: string;
    protected _messageToSendParams: object;
    protected _messageToListen: string;

    // TODO: add the capability to listen to several events to listen for possible outcomes
    constructor(messageToSend: string, messageToListen: string, messageToSendParams: object = null) {
        super();

        this._messageToSend = messageToSend;
        this._messageToListen = messageToListen;
        this._messageToSendParams = messageToSendParams
    }

    protected abstract processResponse(evtData);

    protected payload() {
        this._eventListener.addListener(this._messageToListen, this.onResponse, this);
        this._eventListener.emit(ServerEvent.SEND_MESSAGE, this._messageToSend, this._messageToSendParams);    
    }

    protected onResponse(evtData) {    
        this._eventListener.removeListener(this._messageToListen, this.onResponse, this);

        this.processResponse(evtData);

        this.finish(true);
    }

}