import React, { useState, useEffect, FunctionComponent } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { UserModel } from '../../../game/models/UserModel';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { MachineDescriptor, MachinesModel } from '../../../game/models/MachinesModel';
import { BOEditMachineAction } from '../../../game/actions/backOffice/machines/BOEditMachineAction';
import { Loading } from '../../loading/Loading';
import { useParams } from 'react-router-dom';
import InputFormField from '../common/InputFormField';
import { BOError, BOFixedSizeImage, BOFormButton, BORemoveButton, BOStyledForm } from '../styles';
import OptionsFormField from '../common/OptionsFormField';
import Path from '../../../utils/Path';
import AssetPaths from '../../../config/AssetPaths';
import ManifestFS from '../../../manifest/ManifestFS';

interface MachineEditorProps {
    id?: string;
}


export const MachineEditor: FunctionComponent<MachineEditorProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const userModel = useObservableModel<UserModel>('_userModel');
    const machinesModel = useObservableModel<MachinesModel>('_machinesModel');

    const { id } = useParams<"id">();


    const machine = machinesModel.getMachineById(id);


    if (!userModel.isAdmin) {
        return <></>;
    }

    const thumbnails = ManifestFS.getFiles(AssetPaths.machines.thumbnails).map((file) => { return { id: file, title: file } });
    const animations = ManifestFS.getFiles(AssetPaths.machines.animations).filter((file)=>{
        return file.endsWith(".json");
    }).map((file) => { return { id: file.split('.')[0], title: file } });


    return (
        <div>
            <div>
                <h1>
                    Editar maquina
                </h1>
                {loading ?
                    <Loading />
                    :
                    error ?
                        <p>{error}</p>
                        :
                        <Formik
                            initialValues={{
                                title: machine ? machine.title : '',
                                description: machine ? machine.description : '',
                                creditsPerPlay: machine ? machine.creditsPerPlay : 0,
                                thumbnail: machine ? machine.thumbnail : "",
                                animation: machine ? machine.animation : "",
                            }}
                            enableReinitialize={true}
                            onSubmit={(values, { setSubmitting }) => {                                
                                setLoading(true);

                                actionsManager.executeActionInstant(BOEditMachineAction, values);                                    
                            }}
                            validate={(values) => {
                                let errors: any = {};                            
                                return errors;
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <BOStyledForm>

                                    <InputFormField label="Titulo" name="title" />
                                    <InputFormField label="Descripcion" name="description" />
                                    <InputFormField label="Creditos por partida" name="creditsPerPlay" type="number" />                                    
                                    <OptionsFormField label="Imagen" name="thumbnail" options={ thumbnails }  />
                                    <BOFixedSizeImage src={Path.join(AssetPaths.machines.thumbnails, values.thumbnail)} alt={values.title} />

                                    <OptionsFormField label="Animacion" name="animation" options={ animations }  />                          
                                    <BOFixedSizeImage src={Path.join(AssetPaths.machines.animations, values.animation.split('.')[0] + '.png')} alt={values.title} />   

                                    <BOFormButton type='submit'>
                                        Editar
                                    </BOFormButton>
                                </BOStyledForm>)}
                        </Formik>
                }
            </div>
        </div>
    );
}



