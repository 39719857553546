import { ActionsManager } from "../../../managers/ActionsManager";
import { MachineDescriptor, MachinesModel } from "../../../models/MachinesModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { ServerAction } from "../../ServerAction";

export class BORefillMachineAction extends ServerAction {    
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects
    
    constructor(data: MachineDescriptor) {
        super("bo-refill-machine", "bo-refill-machine-completed", { data: data });
        debugger;
    }
    
    public setup(): void {
        console.log("BO Refill Machine Action");
    }

    protected processResponse(evtData: { result: string, machine: MachineDescriptor }) {
        if (evtData.result === "machine-refilled") {
            this._machinesModel.updateMachine(evtData.machine);
        }        
    }

    public destroy(): void {
        
    }

}