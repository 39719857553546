
import React, { FunctionComponent, useState } from "react";
import { MachineDescriptor, MachinesModel } from "../../../game/models/MachinesModel";
import {  MachineInfoDescription, MachineInfoPrizesTitle, MachineInfoTitle, PrizeItemContainer, PrizeItemIcon, PrizeItemProbability, PrizeItemTitle, PrizesListContainer, WindowContainer, WindowWrapper } from "./styles";
import { useObservableModel } from "../../../ilmare/models/ObservableModelState";
import { PrizeCollectionsModel } from "../../../game/models/PrizeCollectionsModel";
import { usePrizeCollectionsCss } from "../../../game/reactHooks/cssHooks";
import { Loading } from "../../loading/Loading";


type MachineInfoProps = {
    id: string;
}

export const MachineInfo: FunctionComponent<MachineInfoProps> = (params) => {

    const { id } = params; // id de la maquina actual

    const machinesModel = useObservableModel<MachinesModel>('_machinesModel');    
    const prizesCollection = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');
    const [loaded, setLoaded] = useState<boolean>(false);
    
    let machine: MachineDescriptor = machinesModel.getMachineById(id);
    let currentPrizesCollection = prizesCollection.getCollectionById(machine.prizesCollection);

    usePrizeCollectionsCss([currentPrizesCollection.minigame.codename], setLoaded);
    
    let total = 0;
    let prizesData = [];

    for (let prizeId in machine.initialPrizes) {
        let prize = currentPrizesCollection.getPrizeById(prizeId);
        prizesData.push({
            prize: prize,
            quantity: machine.initialPrizes[prizeId]
        });        
        total += machine.initialPrizes[prizeId];
    }

    let prizesList = prizesData.map((prizeData) => {        
        return <PrizeItemContainer key={prizeData.prize.id}>
            <PrizeItemIcon className={prizeData.prize.cssClass} />            
            <div>
                <PrizeItemTitle>{prizeData.prize.title}</PrizeItemTitle>                
                <PrizeItemProbability>{((prizeData.quantity / total) * 100).toFixed(2)}%</PrizeItemProbability>
            </div>
        </PrizeItemContainer>
    });

    // TODO: add a link to the prize collection
    // TODO: show as locked the prizes that the user doesn't have

    if (!loaded) {
        return <WindowWrapper>
            <WindowContainer>
                <Loading />
            </WindowContainer>
        </WindowWrapper>
    }

    return <WindowWrapper>
        <WindowContainer>
            <MachineInfoTitle>{machine.title}</MachineInfoTitle>
            <MachineInfoDescription>{machine.description}</MachineInfoDescription>

            <MachineInfoPrizesTitle>Prize Probabilities</MachineInfoPrizesTitle>
            <PrizesListContainer>                    
                {prizesList}
            </PrizesListContainer>


        </WindowContainer>
    </WindowWrapper>


}

