import { GameActor } from "../../../ilmare/actors/GameActor";
import { SpineAdvancedAnimation } from "../../../ilmare/animations/SpineAdvancedAnimation";
import { GameEvent } from "../../events/MainSceneEvent";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../models/MachinesModel";
import { PrizeModel } from "../../models/PrizeModel";
import { UserModel } from "../../models/UserModel";
import { EventListenerService } from "../../services/EventListenerService";
import { IAnimationStateListener, IBone, IEvent, ISlot, ITrackEntry, Spine } from '../../../ilmare/spine/SpineFacade';
import { Assets, Container, Sprite } from "pixi.js";
import { Emitter, upgradeConfig } from '@pixi/particle-emitter'
import { BallAsPrizeActor } from "../ball/BallAsPrizeActor";
import { DependencyInjectorService } from "../../../ilmare/di/DependencyInjectorService";
import { MinigamesModel } from "../../models/MinigamesModel";
import { MinigameModel } from "../../models/MinigameModel";


export class MachineActor extends GameActor implements IAnimationStateListener {

    protected _resources: any;

    protected _eventListener: EventListenerService = undefined; // injects
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects    
    protected _userModel: UserModel = undefined; // injects
    protected _minigamesModel: MinigamesModel = undefined; // injects    

    protected _machineBack: SpineAdvancedAnimation;
    protected _machineFront: Spine;
    protected _prize: Spine;
    protected _currentPrizeSprite: Sprite;

    protected _animations: Spine[] = [];

    protected _currentFillLevel: number = 0;
    protected _shineEmitter: Emitter;
    protected _particleContainer: Container;

    protected _currentBall: BallAsPrizeActor;
    protected _currentBall3DTrack;
    protected _currentBallRotationTrack;

    constructor(resources: any) {
        super();
        this._resources = resources;
    }

    public setup() {        
        
        this.loadCurrentMachine();

        this._eventListener.addListener(GameEvent.START_PRIZE_PRESENTATION, this.onStartPrizePresentation, this);
        //this._eventListener.addListener(GameEvent.START_OTHER_PRIZE_PRESENTATION, this.onStartPrizePresentation, this); // when other player gets a prize
        this._eventListener.addListener(GameEvent.START_OTHER_PRIZE_PRESENTATION, this.onStartOtherPrizePresentation, this); // when other player gets a prize
        this._eventListener.addListener(GameEvent.UPDATE_MACHINE_FILL_LEVEL, this.onUpdateMachineFillLevel, this); 
    }

    protected getMinigameForCurrentMachine() {
        for (let id of this._minigamesModel.allMinigameIds) {
            let minigame: MinigameModel = this._minigamesModel.getMinigameById(id);
            let prizeCollectionId = this._machinesModel.getMachineById(this._currentMachineModel.currentMachineId).prizesCollection;

            if (minigame.prizeCollections.indexOf(prizeCollectionId) != -1) {
                return minigame;
            }
        }
        return null;
    }

    protected loadCurrentMachine() {
        if (this._machineBack) {
            this._machineBack.destroy();
        }

        let skinName = "default";
        let minigame = this.getMinigameForCurrentMachine();
        if (minigame) {
            skinName = minigame.codename;            
        }

        this._machineBack = new SpineAdvancedAnimation(this._resources.machineBack.spineData);
        this._machineBack.skeleton.setSkinByName(skinName);
        this._machineBack.x = Math.floor(this._owner.owner.screen.width / 2);
        this._machineBack.y = Math.floor(this._owner.owner.screen.height / 2);
        this._machineBack.state.setAnimation(0, "idle", true);
        this._currentFillLevel = this._currentMachineModel.fillLevel;
        this._machineBack.initializeAtPercent("progress", 1 - this._currentMachineModel.fillLevel, 1);
        //this._machineBack.state.timeScale = 0.025;
        

        this._machineFront = new Spine(this._resources.machineFront.spineData);
        this._machineFront.skeleton.setSkinByName(skinName);
        this._machineFront.x = Math.floor(this._owner.owner.screen.width / 2);
        this._machineFront.y = Math.floor(this._owner.owner.screen.height / 2);
        this._machineFront.state.setAnimation(0, "idle", true);
        this._machineFront.autoUpdate = false;
        


        this._prize = new Spine(this._resources.prize.spineData);
        this._prize.x = Math.floor(this._owner.owner.screen.width / 2);
        this._prize.y = Math.floor(this._owner.owner.screen.height / 2);
        this._prize.visible = false;
        this._prize.state.setAnimation(0, "hide", false);
        this._prize.state.addListener(this);
        this._prize.autoUpdate = false;
        
        this._particleContainer = new Container();

        this._owner.mainContainer.addChild(this._machineBack);
        this._owner.mainContainer.addChild(this._prize);
        this._owner.mainContainer.addChild(this._machineFront);
        this._owner.mainContainer.addChild(this._particleContainer);


        this._animations.push(this._machineBack);
        this._animations.push(this._machineFront);
        this._animations.push(this._prize);
        
        // TODO: optimizar esto
        Assets.load(['/assets/particles/shine/star01.png', '/assets/particles/shine/star02.png', '/assets/particles/shine/emitter.json']).then((resources) => {
            let slot: ISlot = this._prize.skeleton.findSlot(`particles`);            
            this._shineEmitter = new Emitter(slot.currentSprite, upgradeConfig(resources['/assets/particles/shine/emitter.json'], [
                resources['/assets/particles/shine/star01.png'], resources['/assets/particles/shine/star02.png']
            ]));
            this._shineEmitter.playOnce(); 
        });


    }

    public update(delta: number) {
        for (let animation of this._animations) {
            animation.update(delta);
        }
        if (this._shineEmitter) {
            this._shineEmitter.update(delta);
        }
        if (this._currentBall) {
            let bone: any = this._prize.skeleton.findBone('ballGuide');            
            this._currentBall.rotate3D(bone.y / 100.0);
            //console.log(bone.y);
        }
    }

    protected onUpdateMachineFillLevel(newFillLevel: number) {
        this.updateMachineFillLevel(newFillLevel);
    }

    protected onStartPrizePresentation(wonPrize: PrizeModel) {

        console.log("### onStartPrizePresentation", wonPrize);

        this._owner.mainContainer.addChild(this._machineBack);
        this._owner.mainContainer.addChild(this._prize);
        this._owner.mainContainer.addChild(this._machineFront);
        
        let prizeTexture = this._resources.prizeCollection.textures[wonPrize.texturePath];

        this._prize.hackTextureBySlotName("prize", prizeTexture, prizeTexture.orig);
        this._prize.state.setAnimation(0, "show", false);
        this._prize.state.setAnimation(1, "loop", true);
        this._prize.state.setAnimation(2, "tier/gold", true);
        this._prize.visible = true;

        let prize = this._prize;
        this._prize.state.tracks[0].listener = {
            complete: (trackEntry) => {
                prize.visible = false;
                if (this._currentBall) {
                    this._currentBall.destroy();
                }
                this._currentBall = null;
            }
        };

        this._currentBall = new BallAsPrizeActor(
            this._resources, 
            this._currentMachineModel.prizesAnimationTypes[wonPrize.id], 
            (this._prize.skeleton.findSlot(`ball`) as ISlot).currentSprite
        );
        DependencyInjectorService.instance.injector.initialize(this._currentBall);
        this._currentBall.init();

    }

    protected onStartOtherPrizePresentation(wonPrizeId: string) {

        console.log("### onStartOtherPrizePresentation", wonPrizeId);

        this._owner.mainContainer.addChild(this._machineBack);
        this._owner.mainContainer.addChild(this._prize);
        this._owner.mainContainer.addChild(this._machineFront);

        this._prize.state.setAnimation(0, "show_other", false);
        this._prize.state.setAnimation(1, "loop", true);
        this._prize.visible = true;

        let prize = this._prize;
        this._prize.state.tracks[0].listener = {
            complete: (trackEntry) => {
                prize.visible = false;
                if (this._currentBall) {
                    this._currentBall.destroy();
                }
                this._currentBall = null;
            }
        };

        this._currentBall = new BallAsPrizeActor(
            this._resources, 
            this._currentMachineModel.prizesAnimationTypes[wonPrizeId], 
            (this._prize.skeleton.findSlot(`ball`) as ISlot).currentSprite
        );
        DependencyInjectorService.instance.injector.initialize(this._currentBall);
        this._currentBall.init();        

    }

    public updateMachineFillLevel(fillLevel: number) {
        console.log("updateMachineFillLevel", this._currentFillLevel, "->", fillLevel)
        this._machineBack.playFromToPercent("progress", 1 - this._currentFillLevel, 1 - fillLevel, 1);
        this._currentFillLevel = fillLevel;
    }

    public getBallContainer(index: number) {
        return (this._machineBack.skeleton.findSlot(`ball${index}`) as ISlot).currentSprite;
    }

    public destroy() {
        for (let animation of this._animations) {
            animation.destroy();
        }
        this._animations = [];
    }

    public onResize(canvasWidth: number, canvasHeight: number) {
        console.log("onResize", canvasWidth, canvasHeight);
        for (let animation of this._animations) {
            animation.x = Math.floor(canvasWidth / 2);
            animation.y = Math.floor(canvasHeight / 2);
        }
    }

    complete(entry: ITrackEntry): void {
        /*if (entry.animation.name == "progress") {
            console.log("complete", entry);
        }*/
    }

    event(entry: ITrackEntry, event: IEvent): void {
        if (event.data.name == "bringToFront") {
            this._owner.mainContainer.addChild(this._machineBack);            
            this._owner.mainContainer.addChild(this._machineFront);
            this._owner.mainContainer.addChild(this._prize);
        }
    }    
}
