import {createGlobalStyle} from "styled-components"

export default createGlobalStyle`

* {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    box-sizing: border-box;
}    

html {
    height: 100%;
    font-size: 18px;
    line-height: 20px;    
    @media (min-resolution: 2dppx) {
        font-size: 36px;
        line-height: 40px;
    }
    @media only screen and (orientation: landscape) {
        font-size: 18px;
        line-height: 20px;
    }


    font-family: Muli, Helvetica, Arial, sans-serif;    
    text-size-adjust: 100%;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    box-sizing: border-box;
}

body {
    color: darkblue;

    height: 100%;
    background-color: rgb(245, 245, 245);
}

a {
    text-decoration: none;
    color: rgb(89, 26, 101);
    cursor: pointer;
}

div {
    display: block;
}

img {
    border-style: none;
}

h1,h2,h3,h4,h5,h6 {
    font-family: Heebo, Helvetica, Arial, sans-serif;
    font-weight: 700;
}

.container {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0px;
}

.mainElement {
    width: 100%;
    height: 100%;            
    position: absolute;
    top: 0;
    left: 0;    
}

.reactContainer {
    z-index: 1;    
}

.phaserCanvas {
    z-index: 0;    
    display: block;
}

.modal {
    font-size: 12px;
}
.modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}
.modal > .content {
    width: 100%;
    padding: 10px 5px;
}
.modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}
.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}
`