import React, { FunctionComponent } from 'react';

import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { UserModel } from '../../../game/models/UserModel';
import { LogoutActionButton, ProfileHeaderContainer, ProfileHeaderTitle, ProfileInformationContainer, ProfileInformationHeader, ProfileInformationList, ProfileInformationListItem, ProfileInformationListItemValue } from './styles';
import { useNavigate } from 'react-router-dom';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { OnUserLogoutAction } from '../../../game/actions/user/OnUserLogoutAction';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';

type ProfileViewProps = {

}

export const ProfileView: FunctionComponent<ProfileViewProps> = () => {
    
    const userModel = useObservableModel<UserModel>('_userModel');    
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    let navigate = useNavigate();

    if (!userModel.isLoggedIn) {
        return <></>
    }

    let items = {
        name: "Name",
        email: "Email",
        picture: "Picture",
        role: "Role"        
    }    

    let key = 0;
    const listItems = [];
    for (let itemId in items) {
        listItems.push(
            <ProfileInformationListItem key={key}>
                <ProfileInformationListItem>{items[itemId]}:</ProfileInformationListItem>
                <ProfileInformationListItemValue>{userModel[itemId]}</ProfileInformationListItemValue>
            </ProfileInformationListItem>
        );
        key++;
    }

    // TODO: services should have a different behaviour
    for (let service in userModel.services) {
        listItems.push(
            <ProfileInformationListItem key={key}>
                <ProfileInformationListItem>{service}:</ProfileInformationListItem>
                <ProfileInformationListItemValue>{userModel.services[service].toString()}</ProfileInformationListItemValue>
            </ProfileInformationListItem>
        );
        key++;
    }

    //auth.signout(() => navigate("/"));

    return <div>
        <ProfileHeaderContainer>
            <ProfileHeaderTitle>Profile</ProfileHeaderTitle>
        </ProfileHeaderContainer>
            <ProfileInformationContainer>
            <ProfileInformationHeader>Information</ProfileInformationHeader>
            <ProfileInformationList>
                {listItems}
            </ProfileInformationList>
        </ProfileInformationContainer>
        <LogoutActionButton onClick={async () => {
            actionsManager.executeActionInstant(OnUserLogoutAction);   
        }}>Logout</LogoutActionButton>
    </div>
}

