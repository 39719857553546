import React from "react";
import { Action } from "../../../game/actions/Action";
import { ActionsManager } from "../../../game/managers/ActionsManager";
import { useInjection } from "../../../game/reactHooks/InjectHooks";
import { AddCreditsButton, AddCreditsButtonContainer, AddCreditsIcon, CreditsAmount, CreditsAmountContainer, CreditsContainer, CreditsIcon } from "./styles";

type BaseCreditsItemProps = {
    icon: any,
    amount: number,
    onClickAction: new (...params) => Action
}


export const BaseCreditsItem: React.FC<BaseCreditsItemProps> = ({ icon, amount, onClickAction }) => {
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    return (
        <CreditsContainer>
            {icon}
            <CreditsAmountContainer>
                <CreditsAmount>{amount}</CreditsAmount>
            </CreditsAmountContainer>
            <AddCreditsButtonContainer>
                <AddCreditsButton onClick={
                    (evt) => {
                        actionsManager.executeActionInstant(onClickAction);
                        return;
                    }}>
                    <AddCreditsIcon></AddCreditsIcon>
                </AddCreditsButton>

            </AddCreditsButtonContainer>

        </CreditsContainer>


    )
};

