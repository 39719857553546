import Manifest from "./Manifest";

type ManifestNode = {
    [key: string]: boolean | ManifestNode;
};

class VirtualFS {
    private manifest: ManifestNode;

    constructor(manifestPath: string) {
        this.manifest = Manifest as ManifestNode;
    }

    exists(path: string): boolean {
        if (path.startsWith('/')) {
            path = path.slice(1);
        }
        const parts = path.split('/');
        let currentNode: ManifestNode | boolean = this.manifest;

        for (const part of parts) {
            if (typeof currentNode !== 'object' || !currentNode.hasOwnProperty(part)) {
                return false;
            }
            currentNode = currentNode[part];
        }
        return true;
    }

    private getNode(path: string): ManifestNode {        
        if (path.startsWith('/')) {
            path = path.slice(1);
        }

        const parts = path.split('/');
        let currentNode: ManifestNode | boolean = this.manifest;

        for (const part of parts) {
            if (typeof currentNode !== 'object' || !currentNode.hasOwnProperty(part)) {
                throw new Error(`Path does not exist: ${path}`);
            }
            currentNode = currentNode[part];
        }

        if (typeof currentNode !== 'object') {
            throw new Error(`Not a directory: ${path}`);
        }

        return currentNode;
    }

    readDir(path: string): string[] {
        return Object.keys(this.getNode(path));
    }

    getFiles(path: string): string[] {
        let node = this.getNode(path);
        return Object.keys(node).filter(key => typeof node[key] === 'boolean');        
    }

    getDirs(path: string): string[] {
        let node = this.getNode(path);
        return Object.keys(node).filter(key => typeof node[key] !== 'boolean');        
    }
}

export default new VirtualFS("./assets.json");