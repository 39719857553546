import { io, Socket } from "socket.io-client";
import { ServerEvent } from "../events/ServerEvent";
import { IInjectable } from "../../ilmare/di/IInjectable";
import { EventListenerService } from "./EventListenerService";
import { ActionsManager } from "../managers/ActionsManager";
import { Action } from "../actions/Action";

export class ServerService implements IInjectable {

    protected _eventListener: EventListenerService = undefined;  // injects
    protected _actionsManager: ActionsManager = undefined; // injects
    
    protected _socket: Socket; 

    public constructor() {
        
    }

    public setup() {
        this.registerListeners();
    }

    public connect(): Socket {

        //const { loginData } = getLoginData();

        let url = `${process.env.BACKEND_URL}:${process.env.BACKEND_PORT}`;
        if (process.env.BACKEND_PORT == "80") {
            url = `${process.env.BACKEND_URL}`;
        }
        //this._socket = io(`${process.env.BACKEND_URL}:${process.env.BACKEND_PORT}`, { 
        this._socket = io(`${url}`, { 
            transports: ['websocket', 'polling', 'flashsocket'],  
            extraHeaders: {
                "my-custom-header": "abcd" // left as reference
            },
            //auth: { token: `Bearer ${loginData.data.token.accessToken}` } // TODO: how to pass the token with firebase?

        });

        return this._socket;
    }

    public registerIncomingMessage(message: string, actionClass: new (...params) => Action) {
        this._socket.on(message, (...args) => {
            console.log("Receiving message " + message);
            let paramsObj = args.length > 0 ? args[0] : {};
            paramsObj.message = message;
            this._actionsManager.executeActionInstant(actionClass, paramsObj);
        });
    }

    protected registerListeners() {
        // TODO: try to iterate through all ServerEvents
        console.log("### ServerService.registerListeners");
        this._eventListener.addListener(ServerEvent.SEND_MESSAGE, this.onRedirectToServer, this);
    }

    protected onRedirectToServer(messageName: string, ...args) {
        console.log("### ServerService.onRedirectToServer", messageName, args);        
        this._socket.emit(messageName, args);
    }

    public destroy() {

    }
}