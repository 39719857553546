import React, { FunctionComponent } from 'react';
import { ModalPopupModel } from '../../game/models/ModalPopupModel';
import { ServerErrorModel } from '../../game/models/ServerErrorModel';
import { useInjection } from '../../game/reactHooks/InjectHooks';
import { ModalPopup } from './ModalPopup';
import { ServerError } from './ServerError';
import { ModalRootContainer } from './styles';
import { useObservableModel } from '../../ilmare/models/ObservableModelState';

export const ModalRoot: FunctionComponent<any> = () => {
    const serverErrorModel = useObservableModel<ServerErrorModel>('_serverErrorModel');
    const modalPopupModel = useObservableModel<ModalPopupModel>('_modalPopupModel');


    if (serverErrorModel.hasError) {
        return <ModalRootContainer>
            <ServerError/>
        </ModalRootContainer>
    } else {
        if (modalPopupModel.hasPopup) {
            return <ModalRootContainer>
                <ModalPopup/>
            </ModalRootContainer>
        } else {
            return <div></div>
        }
        
    }

}
