import styled from "styled-components"
import { ActionButton } from "../../mainContent/machine/styles"

export const ProfileHeaderContainer = styled.div`
    position: relative;
    text-align: center;
    background-image: url(assets/backgrounds/sectionHeader.svg);
    background-size: cover;
    background-position: center center;
`

export const ProfileHeaderTitle = styled.h1`
    margin: 1.25rem 0px;
    font-size: 2.5rem;
    color: rgb(255, 255, 255);
    font-weight: 800;
`

export const ProfileInformationContainer = styled.div`
    max-width: 72rem;
    margin: 0px auto;
    padding: 0px 1rem;
}`

export const ProfileInformationHeader = styled.div`
    color: rgb(89, 26, 101);
    font-size: 0.75rem;
    font-weight: 800;
    text-transform: uppercase;
    margin: 1rem auto 1.5rem;
`

export const ProfileInformationList = styled.div`
    display: flex;
    flex-direction: column;
`

export const ProfileInformationListItem = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding-bottom: 0.5rem;
`

export const ProfileInformationListItemLabel = styled.div`
    flex: 0 0 30%;
    font-weight: 700;
`

export const ProfileInformationListItemValue = styled.div`
    flex: 0 0 70%;
    padding-left: 2rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
    overflow: auto;
    overflow-wrap: break-word;
`

export const LogoutActionButton = styled(ActionButton)`
    background-color: rgb(190, 195, 185);
    border: none;
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: darkblue;
`