import { CreditsBundles } from "../../../reactComponents/credits/bundles/CreditsBundles";
import { ModalPopupModel } from "../../models/ModalPopupModel";
import { Action } from "../Action";

export class ShowCreditsBundlesAction extends Action {    
    // TODO: si el usuario no esta logueado, deberia ir a la pantalla de login o a un popup explicando que se tiene que loguear

    protected _modalPopupModel: ModalPopupModel = undefined; // injects

    public setup(): void {
        console.log("Show Credits Bundles Action");
    }

    protected payload(): void {

        this._modalPopupModel.initWithContent(CreditsBundles, {});

        this.finish(true);
    }

    public destroy(): void {
        
    }

}
