import { ServerAction } from "../ServerAction";


// TODO: implement when it's more clear
export abstract class SaveMinigameStatusAction extends ServerAction {

    constructor(data: { minigameId: string, minigameState: any }) {
        super("save-minigame-status", "save-minigame-status-completed", { minigameId: data.minigameId, state: data.minigameState });
    }
    
}