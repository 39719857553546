import { useEffect, useState } from 'react';
import ManifestFS from '../../manifest/ManifestFS';
import AssetPaths from '../../config/AssetPaths';

export function usePrizeCollectionsCss(minigameCodenames: string[] | string, setLoaded: (loaded: boolean) => void) {

    const [links, setLinks] = useState<HTMLLinkElement[] | null>(null);

    useEffect(() => {

        let collectionDirs = ManifestFS.getDirs(AssetPaths.collections.basePath);        
        let collections = collectionDirs.filter(dir => {
            return ManifestFS.exists(`${AssetPaths.collections.basePath}/${dir}/${dir}.css`);
        });

        for (let collection of collections) {
            if (minigameCodenames && minigameCodenames != "all" && !minigameCodenames.includes(collection)) {
                continue;
            }
            const link = document.createElement('link');
            link.href = `${AssetPaths.collections.basePath}/${collection}/${collection}.css`;
            link.type = 'text/css';
            link.rel = 'stylesheet';            
            document.head.appendChild(link);
            setLinks(links => links ? [...links, link] : [link]);
            setLoaded(true);
        }

        return () => {
            if (links) {
                for (let link of links) {
                    document.head.removeChild(link);
                }
            }
        };
    }, []);
}
