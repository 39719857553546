import React, { FunctionComponent } from 'react';
import { useNavigate } from "react-router-dom";
import { ChooseMachineAction } from '../../../game/actions/machines/ChooseMachineAction';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { MachineDescriptor } from '../../../game/models/MachinesModel';
import { useInjection } from '../../../game/reactHooks/InjectHooks';
import AssetPaths from '../../../config/AssetPaths';
import Path from '../../../utils/Path';
import { MachinesListGridCell, MachineCardInfo, MachineCardImage, MachineCardStatus, MachineCardStatusContainer, MachineCardTitle, MachineCardPlayers, MachineCardCostIcon, MachineCardCostContainer, MachineCardCost } from './styles';
import { CreditsIcon } from '../../header/styles';


type MachineItemProps = {
    id: String,
    machine: MachineDescriptor
}

export const MachineListItem: FunctionComponent<MachineItemProps> = ({ id, machine }) => {

    //const [state, setState] = useState({selected: false});
    const navigate = useNavigate();
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    return <MachinesListGridCell onClick={() => {
        actionsManager.executeActionInstant(ChooseMachineAction, id).then(() => {
            navigate("/machine/view/" + id);
        });
    }}>

        <MachineCardCostContainer>
            <MachineCardCostIcon src="/assets/icons/credits.png"></MachineCardCostIcon>
            <MachineCardCost>{machine.creditsPerPlay}</MachineCardCost>
        </MachineCardCostContainer>

        <MachineCardImage style={{ backgroundImage: `url('${Path.join(AssetPaths.machines.thumbnails, machine.thumbnail)}` }} />
        <MachineCardInfo>
            <MachineCardTitle>
                {machine.title}
            </MachineCardTitle>
            <MachineCardStatusContainer>
                <MachineCardStatus hasQueue={machine.queueLength > 0}>
                    {machine.queueLength === 0 ? "PLAY NOW!" : "PLAYING..."}
                </MachineCardStatus>

                <MachineCardPlayers>
                    {machine.viewersLength} WATCHING
                </MachineCardPlayers>


            </MachineCardStatusContainer>
        </MachineCardInfo>
    </MachinesListGridCell>
}  
