import React, { FunctionComponent, useEffect, useState } from "react"
import { CurrentMachineModel } from "../../../game/models/CurrentMachineModel";
import { MachinesModel } from "../../../game/models/MachinesModel";
import { InfoCapsule, InfoCapsuleEyeIcon, InfoCapsuleQueueIcon, InfoCapsuleText } from "./styles";
import { useObservableModel, useObservableModelInstance } from "../../../ilmare/models/ObservableModelState";

export const MachineInfoQueue: FunctionComponent = ()=> {
    
    const currentMachineModel = useObservableModel<CurrentMachineModel>('_currentMachineModel');
    const machinesModel = useObservableModel<MachinesModel>('_machinesModel');

    /*const [queueLength, setQueueLength] = useState(0);
    const machineModel = useObservableModelInstance(machinesModel.getMachineById(currentMachineModel.currentMachineId));*/

    return (
        <InfoCapsule>
            <InfoCapsuleQueueIcon />
            <InfoCapsuleText>{machinesModel.getMachineById(currentMachineModel.currentMachineId).queueLength}</InfoCapsuleText>
        </InfoCapsule>
    )
    
}