
import React, { createContext, useContext, useState, useEffect, FunctionComponent } from 'react';

export type Orientation = 'portrait' | 'landscape';
const OrientationContext = createContext<Orientation | undefined>(undefined);

export const useOrientation = (): Orientation => {
    const context = useContext(OrientationContext);
    if (!context) {
        throw new Error("useOrientation must be used within an OrientationProvider");
    }
    return context;
};

export const OrientationProvider: FunctionComponent<{children: any}> = ({ children }) => {
    const [orientation, setOrientation] = useState<Orientation>(
        window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
    );

    useEffect(() => {
        const handleResize = () => {
            setOrientation(window.innerHeight > window.innerWidth ? 'portrait' : 'landscape');
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <OrientationContext.Provider value={orientation}>
            {children}
        </OrientationContext.Provider>
    );
};