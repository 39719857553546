import React, { FunctionComponent } from 'react';
import { ModalDialog, ModalDialogCallToActionButton, ModalDialogDescription, ModalDialogIcon, ModalDialogTitle } from '../modal/styles';
import { useInjection } from '../../game/reactHooks/InjectHooks';
import { ActionsManager } from '../../game/managers/ActionsManager';
import { ModalPopupModel } from '../../game/models/ModalPopupModel';
import { BuyCreditsAction } from '../../game/actions/user/BuyCreditsAction';
import { useObservableModel } from '../../ilmare/models/ObservableModelState';
import { ShowCreditsBundlesAction } from '../../game/actions/credits/ShowCreditsBundlesAction';

export const InsufficientCreditsDialog: FunctionComponent<any> = () => {

    const modalPopupModel = useObservableModel<ModalPopupModel>('_modalPopupModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    // TODO: localize
    const title = "Oops!";
    const message = "Insuficcient credits!";
    const button = {
        title: "BUY MORE CREDITS!",
        action: ShowCreditsBundlesAction//BuyCreditsAction
    }

    return <ModalDialog>
            <ModalDialogIcon></ModalDialogIcon>
            <ModalDialogTitle>{title}</ModalDialogTitle>
            <ModalDialogDescription>{message}</ModalDialogDescription>
            <ModalDialogCallToActionButton onClick={() => actionsManager.executeActionInstant(button.action)}>{button.title}</ModalDialogCallToActionButton>
        </ModalDialog>
    ;
}