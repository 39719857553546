import { GameActor } from "../../../ilmare/actors/GameActor";
import { GameEvent } from "../../events/MainSceneEvent";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { CurrentQueueModel } from "../../models/CurrentQueueModel";
import { MachinesModel } from "../../models/MachinesModel";
import { PrizeCollectionsModel } from "../../models/PrizeCollectionsModel";
import { UserModel } from "../../models/UserModel";
import { EventListenerService } from "../../services/EventListenerService";
import { ConfigModel } from "../../models/ConfigModel";

// TODO: migrate to pixi.js

export class DebugActor extends GameActor {
    protected _eventListener: EventListenerService = undefined; // injects
    protected _userModel: UserModel = undefined; // injects
    protected _keyboard;
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _currentQueueModel: CurrentQueueModel = undefined; // injects
    protected _configModel: ConfigModel = undefined; // injects    
    
    public setup() {
        
        /*this._keyboard = this._owner.input.keyboard.addKeys({
            'prize': Phaser.Input.Keyboard.KeyCodes.P,

        });*/

        document.addEventListener("keydown", this.onKeyDown.bind(this));
        document.addEventListener("keyup", this.onKeyUp.bind(this));        

    }

    private onKeyDown(e: KeyboardEvent): void {        

    }

    private onKeyUp(e: KeyboardEvent): void {        
        if (e.key === "p") {
            let prizes = Object.keys(this._currentMachineModel.prizesAnimationTypes);
            let prizeId = prizes[Math.floor(Math.random() * prizes.length)]; 
            
            let collection = this._prizeCollectionsModel.getCollectionById(this._machinesModel.getMachineById(this._currentMachineModel.currentMachineId).prizesCollection);
            let prize = collection.getPrizeById(prizeId);

            this._eventListener.emit(GameEvent.START_PRIZE_PRESENTATION, prize);
        }
        if (e.key === "a") {
            this._currentQueueModel.reset()
            this._currentMachineModel.updateStatus({machineId: this._currentMachineModel.currentMachineId, result: "watching"});            
        }
        if (e.key === "s") {
            this._currentQueueModel.timeLeft = this._configModel.data.TIME_TO_PLAY / 1000;
            this._currentMachineModel.updateStatus({machineId: this._currentMachineModel.currentMachineId, result: "ready-to-play"});            
        }
        if (e.key === "d") {            
            this._currentQueueModel.update({machineId: this._currentMachineModel.currentMachineId, queuePosition: 2, queueLength: 5, timeLeft: 0})
            this._currentMachineModel.updateStatus({machineId: this._currentMachineModel.currentMachineId, result: "waiting-in-queue"});            
        }
    }

    public update() {
    }

    public destroy() {
        document.removeEventListener("keydown", this.onKeyDown.bind(this));
        document.removeEventListener("keyup", this.onKeyUp.bind(this));        
        
    }

    public onResize(canvasWidth: number, canvasHeight: number) {
    }

}