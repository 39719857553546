import React, { FunctionComponent } from "react";

type LoadingProps = {

}

export const Loading: FunctionComponent<LoadingProps> = (props)=> {
    return <div>
        Loading...
    </div>
}
