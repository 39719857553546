import { Socket } from "socket.io-client";
import { ServerService } from "../../services/ServerService";
import { Action } from "../Action";

export class ConnectToServerAction extends Action {
    protected _server: ServerService = undefined; // injects

    public setup(): void {
        console.log("Connect To Server Action");
    }

    protected payload() {        
        let socket: Socket = this._server.connect();

        socket.on('connect', () => {
            this.finish(true);
        });
    }

    public destroy(): void {
        
    }

}