import { GameModel } from "../../ilmare/models/GameModel";

export class CurrentQueueModel extends GameModel {
    
    protected _machineId: string;    
    protected _queuePosition: number;
    protected _queueLength: number;
    protected _timeLeft: number;

    public setup() {
        
    }

    public update(evtData) {
        this._machineId = evtData.machineId;
        this._queuePosition = evtData.queuePosition;
        this._queueLength = evtData.queueLength;
        this._timeLeft = evtData.timeLeft;
        this.notifyChange();
    }

    public reset() {        
        this._machineId = undefined;
        this._queuePosition = undefined;
        this._queueLength = undefined;
        this._timeLeft = undefined;
        this.notifyChange();
    }

    public destroy() {
        
    }

    public get isWaitingInAQueue(): boolean {
        return this._machineId != undefined && this._queuePosition != undefined;
    }

    public get isPlaying(): boolean {
        return this._machineId != undefined && this._queuePosition != undefined && this._queuePosition == 0 && this._timeLeft != undefined && this._timeLeft > 0;
    }

    public get machineId(): string {
        return this._machineId;
    }

    public get queuePosition(): number {
        return this._queuePosition;
    }

    public get queueLength(): number {
        return this._queueLength;
    }

    public get timeLeft(): number {
        return this._timeLeft;
    }

    public set timeLeft(val: number) {
        this._timeLeft = val > 0 ? val : 0;
        this.notifyChange();
    }
}