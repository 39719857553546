import { Spine } from "../spine/SpineFacade";
import { GameActor } from "./GameActor";

export abstract class MiniGameActor extends GameActor{
    protected _animations: Spine[] = [];
    protected _positionOffset: { x: number, y: number } = { x: 0, y: 0 };
    
    protected addAnimation(animation: Spine) {
        animation.x = Math.floor(this._owner.owner.screen.width / 2) + this._positionOffset.x;
        animation.y = Math.floor(this._owner.owner.screen.height / 2) + this._positionOffset.y;
        this._owner.mainContainer.addChild(animation);

        this._animations.push(animation);
    }
    
    protected removeAnimation(animation: Spine) {        
        this._owner.mainContainer.removeChild(animation);
        this._animations = this._animations.filter(a => a !== animation);
    }

    public update(delta: number) {
        for (let animation of this._animations) {
            animation.update(delta);
        }
    }

    public destroy() {
        for (let animation of this._animations) {
            this._owner.mainContainer.removeChild(animation);
            animation.destroy();
        }
    }

    onResize(canvasWidth, canvasHeight) {
        for (let animation of this._animations) {
            animation.x = Math.floor(canvasWidth / 2) + this._positionOffset.x;
            animation.y = Math.floor(canvasHeight / 2) + this._positionOffset.y;
        }
    }
}