import React, { useState } from 'react';
//import LoginBox from './LoginBox';
import { useNavigate } from 'react-router-dom';
//import './LoginBox.css';
import { BoldLink, Button, ButtonAsLink, InfoText, Input, LoginBox, LoginContainer, MutedLink, SocialLoginButton } from './styles';

// DEPRECATED

export const Login = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        /*e.preventDefault();
        const { data, error } = await apiPost('/auth/login', { data: { email, password } });
        if (data?.data?.token) {
            localStorage.setItem('ld', btoa(JSON.stringify(data)));
            navigate('/');
            if (onLogin) {
                onLogin();
            }
        } else {
            console.error('Login error:', error);
        }*/
    };

    // Simulando autenticación social (deberías implementarlo en tu backend)
    const handleSocialLogin = (socialNetwork) => {
        console.log(`Log in with ${socialNetwork} not implemented yet.`);
        // Aquí iría la lógica para autenticar con la red social
    };

    return (
        <LoginContainer>
            <LoginBox>
                <form onSubmit={handleSubmit}>
                    <Input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <MutedLink href="#">Forgot your password?</MutedLink>

                    <Button type="submit">Login</Button>
                </form>
                <SocialLoginButton onClick={() => handleSocialLogin('Google')}>
                    Login with Google
                </SocialLoginButton>
                <SocialLoginButton onClick={() => handleSocialLogin('Facebook')}>
                    Login with Facebook
                </SocialLoginButton>
                
                <InfoText>
                    Don't have an account? <BoldLink onClick={() => navigate('/register')}>Sign up</BoldLink>
                </InfoText>
            </LoginBox>
        </LoginContainer>
    );
};
