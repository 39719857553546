import React, { FunctionComponent } from 'react';
import { InfoButtonContainer, InfoButtonIcon, InfoInnerButton } from './styles';
import { ModalPopupModel } from '../../../game/models/ModalPopupModel';
import { MachineInfo } from './MachineInfo';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';

type InfoButtonProps = {
    id: string
}

export const InfoButton: FunctionComponent<InfoButtonProps> = ({id})=> {

    const modalPopupModel = useObservableModel<ModalPopupModel>('_modalPopupModel');

    return (
        <InfoButtonContainer>
            <InfoInnerButton onClick={
                (evt) => {
                    modalPopupModel.initWithContent(MachineInfo, {id: id});
                } 
            }> 
                <InfoButtonIcon />
            </InfoInnerButton>            

        </InfoButtonContainer>
    )

}