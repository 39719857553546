import React from 'react'
import BaseApplication from '../BaseApplication';
import { BaseGameScene } from '../scenes/BaseGameScene';
import { Assets } from 'pixi.js';

type SpineAnimationComponentProps = {
    PixiApplicationClass: new (config: any) => BaseApplication;
    BootSceneClass: new (owner: BaseApplication, name: string) => BaseGameScene;
};

export default class SpineAnimationComponent extends React.Component<SpineAnimationComponentProps, any> {

    protected _application: BaseApplication | null = null;
    protected _canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
    protected _containerRef: React.RefObject<HTMLDivElement> = React.createRef()

    componentDidMount() {

        const { PixiApplicationClass, BootSceneClass } = this.props;

        const canvas = this._canvasRef.current;
        const container = this._containerRef.current;

        console.log("XXXX componentDidMount: ", container?.clientWidth, container?.clientHeight, canvas?.clientWidth, canvas?.clientHeight);

        if (!canvas || !container) {
            return;
        }

        this._application = new PixiApplicationClass({
            view: canvas,
            width: container.clientWidth,
            height: container.clientHeight,
            backgroundColor: 0xff0000,
            resolution: 1
            //resolution: window.devicePixelRatio || 1,
        });

        this._application.setup();
        this._application.createScene(BootSceneClass);

        window.addEventListener('resize', this.handleResize);

        this.handleResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);        

        // TODO: esto no pareceria funcionar
        Assets.unloadBundle('machine');

        if (this._application) {            
            this._application.destroy();
        }        
    }

    handleResize = () => {
        /*const canvas = this._canvasRef.current;
        if (this._application && canvas) {            
            this._application.renderer.resize(canvas.clientWidth, canvas.clientHeight);
            this._application.onResize(canvas.clientWidth, canvas.clientHeight);
        }*/
        const container = this._containerRef.current;
        const canvas = this._canvasRef.current;

        //console.log("XXXX handleResize: ", container?.clientWidth, container?.clientHeight, canvas?.clientWidth, canvas?.clientHeight);

        if (container && canvas && this._application) {
            this._application.renderer.resize(container.clientWidth, container.clientHeight);
            this._application.onResize(container.clientWidth, container.clientHeight);
        }

    }

    render(): React.ReactNode {        
        return <div ref={this._containerRef} style={{ width: '100%', height: '100%', backgroundColor: "#FFFF00" }}>
            <canvas ref={this._canvasRef}></canvas>
        </div>        

//<canvas ref={this._canvasRef}></canvas>
        //return <canvas ref={this._canvasRef} style={{ width: '100%', height: '100%' }} />;
    }
}