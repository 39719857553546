import React, { FunctionComponent } from 'react';
import { Field, ErrorMessage } from 'formik';
import { BOError, BOInputField, BOLabel } from '../styles';


interface InputFormFieldProps {
  label: string;
  name: string;
  type?: string;
}

const InputFormField: FunctionComponent<InputFormFieldProps> = ({ label, name, type = 'text' }) => {
  return (
    <>
      <BOLabel htmlFor={name}>{label}</BOLabel>
      <Field as={BOInputField} name={name} type={type} />
      <ErrorMessage name={name} component={BOError} />
    </>
  );
};

export default InputFormField;