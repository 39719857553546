import { Socket } from "socket.io-client";
import { ServerService } from "../../services/ServerService";
import { Action } from "../Action";
import { ActionsManager } from "../../managers/ActionsManager";
import { ChooseMachineAction } from "../machines/ChooseMachineAction";

export class RestoreStateAction extends Action {
    protected _server: ServerService = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects

    public setup(): void {
        console.log("Restore State Action");
    }

    protected payload() {
        // si estoy restaurando desde una maquina
        // pattern matching a /machine/view/xxxxxxxx        
        let group = window.location.href.match(/(.*\/machine\/view\/)([0-9a-f]+)/);
        if (group && group[2]) {
            this._actionsManager.queueAction(ChooseMachineAction, group[2]);
        }
        this.finish(true);
    }

    public destroy(): void {
        
    }

}