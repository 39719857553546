import { GameModel } from "../../ilmare/models/GameModel";
import { PrizeModel } from "./PrizeModel";

export class PrizeCollectionModel extends GameModel {
    
    protected _id: string;
    protected _prizes: PrizeModel[] = [];
    protected _title: string;
    protected _description: string;
    protected _image: string;
    protected _path: string;
    protected _slotName: string;
    protected _minigame: any;

    public get id(): string {
        return this._id;
    }

    public get title(): string {
        return this._title;
    }

    public get description(): string {
        return this._description;
    }

    public get image(): string {
        return this._image;
    }

    // DEPRECATED
    public get path(): string {
        return this._path;
    }

    public get prizes(): PrizeModel[] {
        return this._prizes;
    }

    public get slotName(): string {
        return this._slotName;
    }

    public get minigame(): any {
        return this._minigame;
    }

    public setup() {
        
    }

    public populate(data: any) {        
        this._id = data._id;
        this._title = data.title;
        this._description = data.description;
        this._image = data.image;
        this._path = data.path;
        this._slotName = data.slotName;
        this._minigame = data.minigame;
        
        for (let prizeData of data.prizes) {
            let prize = new PrizeModel();
            prize.populateGenericPrize(data, prizeData);
            this._prizes.push(prize);
        }   
        this.notifyChange();     
    }

    public addPrize(prize: PrizeModel) {
        this._prizes.push(prize);
        this.notifyChange();
    }

    public getPrizeById(id: string): PrizeModel {
        for (let prize of this._prizes) {
            if (prize.id == id) {
                return prize;
            }
        }
        return null;
    }

    public destroy() {
        
    }
}