import { GameModel } from "../../ilmare/models/GameModel";

export interface MachineStatusDescriptor {

}

export interface MachineDescriptor {
    _id: string,
    animation: string,
    thumbnail: string,
    title: string,
    description: string,
    creditsPerPlay: number,
    queueLength: number,
    viewersLength: number,
    initialPrizes: { [key: string]: number },
    prizesCollection: string,
    state: string // "published", "draft", "deleted"
}

export class MachinesModel extends GameModel {
    
    protected _machineDescriptors: MachineDescriptor[] = [];
    
    public init(machineDescriptors) {
        this._machineDescriptors = machineDescriptors.slice();
        this.notifyChange();
    }

    public addMachine(machineDescriptor: MachineDescriptor) {
        this._machineDescriptors.push(machineDescriptor);
        this.notifyChange();
    }

    public updateMachine(machineDescriptor: MachineDescriptor) {
        for (let i = 0; i < this._machineDescriptors.length; i++) {
            if (this._machineDescriptors[i]._id == machineDescriptor._id) {
                this._machineDescriptors[i] = machineDescriptor;
                this.notifyChange();
                return;
            }
        }
    }

    public removeMachine(id: string) {
        for (let i = 0; i < this._machineDescriptors.length; i++) {
            if (this._machineDescriptors[i]._id == id) {
                this._machineDescriptors.splice(i, 1);
                this.notifyChange();
                return;
            }
        }
    }

    public get machines() {
        return this._machineDescriptors;
    }

    public get machinesCount() {
        return this._machineDescriptors.length;
    }

    public getMachineByIndex(index: number) {
        return this._machineDescriptors[index];
    }

    public getMachineById(id: string) {
        for (let descriptor of this._machineDescriptors) {
            if (descriptor._id == id) {
                return descriptor;
            }
        }

        return null;
    }

    public updateQueueLength(id: string, newLength: number) {
        console.log("$%$%$%$%$ updateQueueLength", id, newLength);
        this.getMachineById(id).queueLength = newLength;
        this.notifyChange();
    }

    public updateViewersLength(id: string, newLength: number) {
        console.log("$%$%$%$%$ updateViewersLength", id, newLength);
        this.getMachineById(id).viewersLength = newLength;
        this.notifyChange();
    }

    public setup() {
        
    }

    public destroy() {
        
    }

}