import { GameModel } from "../../ilmare/models/GameModel";
import Path from "../../utils/Path";

export class PrizeModel extends GameModel {

    get id(): string { return this._id; };
    get description(): string { return this._description; };
    get cssClass(): string { return this._cssClass; };    
    get skin(): string { return this._skin; };
    get title(): string { return this._title; };  
    get transactionId(): string { return this._transactionId; };
    get transactionDescription(): string { return this._transactionDescription; };
    get date(): Date { return this._date; };
    get collection(): string { return this._collection; };
    get rawData(): any { return this._rawData; };
    get texturePath(): string { return this._texturePath; };


    protected _id: string;
    protected _description: string;
    protected _cssClass: string;
    protected _skin: string;
    protected _title: string;    
    protected _transactionId: string;
    protected _transactionDescription: string;
    protected _date: Date;
    protected _collection: string;    
    protected _rawData: any;
    protected _texturePath: string;
    

    public setup() {
        
    }

    public populateWonPrize(data: any) {
        this._id = data.prize._id;
        this._transactionId = data._id;
        this._transactionDescription = data.description;
        this._title = data.prize.title;
        this._description = data.prize.description;

        this._skin = `${data.prize.skin}`;
        this._cssClass = data.prizeCollection.minigame ? 
            `${data.prizeCollection.minigame.codename}-${this._skin.replaceAll('/', '-')}` :
            `${this._skin.replaceAll('/', '-')}`;        
        this._texturePath = data.prizeCollection.minigame ? 
            `${data.prizeCollection.minigame.codename}/${this._skin}.${data.prize.imageExtension}` :
            `${this._skin}.${data.prize.imageExtension}`;

        this._date = new Date(data.createdAt);
        this._collection = data.prize.prizeCollection; 
        this._rawData = data;

        this.notifyChange();
    }
    
    /* TODO:
    - volar todo lo que no se use mas como el imagePath    
    - simplificar este modelo y eliminar los que no se usa (o marcar como DEPRECATED para que no se rompa nada)
    */


    public populateGenericPrize(collectionData: any, data: any) {
        this._id = data._id;
        this._transactionId = undefined;
        this._transactionDescription = undefined;
        this._title = data.title;
        this._description = data.description;

        this._skin = `${data.skin}`;        
        this._cssClass = collectionData.minigame ? 
            `${collectionData.minigame.codename}-${this._skin.replaceAll('/', '-')}` :
            `${this._skin.replaceAll('/', '-')}`;
        this._texturePath = collectionData.minigame ? 
            `${collectionData.minigame.codename}/${this._skin}.${data.imageExtension}` :
            `${this._skin}.${data.imageExtension}`;

        this._date = new Date(data.createdAt);        
        this._collection = collectionData._id;        
        this._rawData = data;        

        this.notifyChange();
    }
    
    public destroy() {
        
    }

}