import React, { FunctionComponent, useEffect, useState } from 'react';
import { ActionsManager } from '../../game/managers/ActionsManager';
import { ModalPopupModel } from '../../game/models/ModalPopupModel';
import { useInjection } from '../../game/reactHooks/InjectHooks';
import { ModalContent, ModalDialog, ModalDialogCallToActionButton, ModalDialogCloseButton, ModalDialogDescription, ModalDialogIcon, ModalDialogTitle } from './styles';
import { DismissModalPopupAction } from '../../game/actions/popups/DismissModalPopupAction';
import { useObservableModel } from '../../ilmare/models/ObservableModelState';


export interface ModalPopupProps {
    
}

export const ModalPopup: FunctionComponent<ModalPopupProps> = (data: ModalPopupProps) => {
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    
    const modalPopupModel = useObservableModel<ModalPopupModel>('_modalPopupModel');


    if (modalPopupModel.hasPopup) {

        return <div>
            <ModalContent>
                <ModalDialogCloseButton onClick={() => actionsManager.executeActionInstant(DismissModalPopupAction)}>X</ModalDialogCloseButton>
                {modalPopupModel.content(modalPopupModel.contentParams)}
            </ModalContent>
        </div>
    } else {
        return <div/>
    }

}