import { GameEvent } from "../../events/MainSceneEvent";
import { MachinesModel } from "../../models/MachinesModel";
import { Action } from "../Action";

export class QueueSizeUpdatedAction extends Action {
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _data: any;

    public constructor(data: any) {
        super();
        this._data = data;
    }

    public setup(): void {
        console.log("Machine Queue Updated Action");
    }

    protected payload() {    
        this._machinesModel.updateQueueLength(this._data.machineId, this._data.queueSize);

        this.finish(true);
    }

    public destroy(): void {
        
    }    
}