import { MinigameModel } from "../../models/MinigameModel";
import { MinigamesModel } from "../../models/MinigamesModel";
import { ServerAction } from "../ServerAction";

export class GetMinigamesAction extends ServerAction {
    protected _minigamesModel: MinigamesModel = undefined; // injects

    constructor() {
        super("get-minigames", "get-minigames-completed", { });
    }

    public setup(): void {
        console.log("Get Minigames");
    }

    protected processResponse(evtData: any) {
        console.log('get-minigames-completed');

        for (let minigame of evtData.minigames) {
            let minigameModel = new MinigameModel();
            minigameModel.populate(minigame);

            this._minigamesModel.addMinigame(minigame._id, minigameModel);
        }

    }

    public destroy(): void {
        
    }
}