import { IAnimationStateListener, ITrackEntry, IEvent, Spine } from '../../ilmare/spine/SpineFacade';

type TrackControl = {
    trackEntry: ITrackEntry,
    startTime: number,
    endTime: number,
}

export class SpineAdvancedAnimation extends Spine implements IAnimationStateListener {

    protected _trackControls: TrackControl[] = []

    constructor(spineData: any) {
        super(spineData);
        
        this.autoUpdate = false;
    }

    // dt in seconds
    public update(dt: number) {
        super.update(dt);

        let finishedTracks: number[] = [];
        for (let trackControl of this._trackControls) {
            if (trackControl && trackControl.trackEntry.trackTime >= trackControl.endTime) {
                this.state.clearTrack(trackControl.trackEntry.trackIndex);
                finishedTracks.push(trackControl.trackEntry.trackIndex);
            }
        }
        // clean up finished tracks
        this._trackControls = this._trackControls.filter((trackControl) => {
            return !finishedTracks.includes(trackControl.trackEntry.trackIndex);
        });
    }

    public event(entry: ITrackEntry, event: IEvent): void {
        
    }

    public initializeAtPercent(animationName, startPercent, trackIndex) {
        const animation = this.skeleton.data.findAnimation(animationName);

        if (animation) {
            // Obtén la duración total de la animación
            const totalDuration = animation.duration;

            // Calcula el tiempo de inicio basado en el porcentaje
            const startTime = totalDuration * startPercent;

            // Establece la animación
            const trackEntry = this.state.setAnimation(trackIndex, animationName, true);

            // Configura el tiempo de inicio
            trackEntry.trackTime = startTime;

            // Configura la mezcla para mantener la animación en ese estado
            trackEntry.mixTime = 0;
            trackEntry.mixDuration = 0;
            this.update(0);
            this.state.clearTrack(trackIndex);
        } else {
            console.error(`Animación "${animationName}" no encontrada.`);
        }
    }

    initializeAt(animationName, startTime, trackIndex) {
        const animation = this.skeleton.data.findAnimation(animationName);

        if (animation) {
            const trackEntry = this.state.setAnimation(trackIndex, animationName, true);

            // Establece el tiempo de inicio de la pista de animación
            trackEntry.trackTime = startTime;

            // Configura la mezcla para mantener la animación en ese estado
            trackEntry.mixTime = 0;
            trackEntry.mixDuration = 0;
            this.update(0);
            this.state.clearTrack(trackIndex);
        } else {
            console.error(`Animación "${animationName}" no encontrada.`);
        }
    }


    public playFromToPercent(animationName, startPercent, endPercent, trackIndex) {
        // return if start and end are the same (or almost the same)
        if (Math.abs(endPercent - startPercent) < 0.01) {
            return;
        }

        const animation = this.skeleton.data.findAnimation(animationName);

        if (animation) {
            // Obtén la duración total de la animación
            const totalDuration = animation.duration;

            let trackControl: TrackControl = {
                trackEntry: this.state.setAnimation(trackIndex, animationName, false),
                startTime: totalDuration * startPercent,
                endTime: totalDuration * endPercent,
            }

            // Configura los tiempos de inicio y fin
            trackControl.trackEntry.trackTime = trackControl.startTime;
            trackControl.trackEntry.mixTime = 0;
            trackControl.trackEntry.mixDuration = 0;

            // Actualiza la animación para aplicar los cambios
            this.update(0);

            this._trackControls[trackIndex] = trackControl;
        } else {
            console.error(`Animación "${animationName}" no encontrada.`);
        }

    }
}