import firebaseConfig from "../../../firebase/firebaseConfig";
import { ServerEvent } from "../../events/ServerEvent";
import { ActionsManager } from "../../managers/ActionsManager";
import { UserModel } from "../../models/UserModel";
import { Action } from "../Action";
import { GetUserDetailsAction } from "./GetUserDetailsAction";
import { GetAllWonPrizesAction } from "./inventory/GetAllWonPrizesAction";
import { UpdateCreditsAction } from "./UpdateCreditsAction";
import { UpdateCurrentQueueAction } from "./UpdateCurrentQueueAction";

export class OnGetTokenAction extends Action {
    protected _userModel: UserModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects
   
    public constructor(data) {
        super();
    }

    public setup(): void {
        
    }

    protected payload() {
        // retrieve local token from firebase to send to server
        const user = firebaseConfig.auth.currentUser;

        console.log('### OnGetTokenAction', user);

        if (user) {
            user.getIdToken().then((token) => {
                this._eventListener.emit(ServerEvent.SEND_MESSAGE, 'token-updated', { token: token });
            });
        }
       this.finish(true);
    }

    public destroy(): void {
        
    }

}