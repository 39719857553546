import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { PlayAtMachineAction } from '../../../game/actions/machines/PlayAtMachineAction';
import { ReserveMachineAction } from '../../../game/actions/machines/ReserveMachineAction';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { CurrentMachineModel, CurrentMachineStatus } from '../../../game/models/CurrentMachineModel';
import { MachinesModel } from '../../../game/models/MachinesModel';
import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { EventListenerService } from '../../../game/services/EventListenerService';
import { InQueueActionButton, PlayActionButton, ReserveActionButton, StartActionButton, WaitingActionButton } from './styles';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { CreditsDisplay } from '../../credits/display/CreditsDisplay';


type PlayButtonProps = {
    id: string
}

export const PlayButton: FunctionComponent<PlayButtonProps> = ({ id }) => {

    const currentMachine = useObservableModel<CurrentMachineModel>('_currentMachineModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const machinesModel = useObservableModel<MachinesModel>('_machinesModel');
    const [isDisabled, setIsDisabled] = useState(false);

    let machine = machinesModel.getMachineById(id);

    let actionMessage: string;

    switch (currentMachine.status) {
        case CurrentMachineStatus.WATCHING:
            actionMessage = "PLAY!";

            return <PlayActionButton onClick={
                (evt) => {
                    actionsManager.executeActionInstant(ReserveMachineAction, id);
                }
            }>
                <div>{actionMessage}</div>
                <CreditsDisplay amount={machine.creditsPerPlay} />
            </PlayActionButton>;

        case CurrentMachineStatus.IN_QUEUE:
            actionMessage = "QUEUE POSITION: " + currentMachine.queuePosition;
            return <InQueueActionButton>
                {actionMessage}
            </InQueueActionButton>;

        case CurrentMachineStatus.WAITING:
            actionMessage = "WAITING...";
            return <WaitingActionButton disabled={true}>
                {actionMessage}
            </WaitingActionButton>;


        case CurrentMachineStatus.PLAYING:
            actionMessage = "START!";
            return <StartActionButton onClick={
                (evt) => {
                    actionsManager.executeActionInstant(PlayAtMachineAction, id);
                    
                    currentMachine.forceWaitingState();
                }
            } disabled={isDisabled}>
                {actionMessage}
            </StartActionButton>;
    }

    return <></>
}