import { DependencyInjectorService } from "../../ilmare/di/DependencyInjectorService";
import { IInjectable } from "../../ilmare/di/IInjectable";
import { Action } from "../actions/Action";


export class ActionFactory implements IInjectable {

    setup(): void {
        
    }

    public createAction(actionClass: new (...args) => Action, ...params): Action {
        let action: Action = new actionClass(params.length > 0 ? params[0] : {});

        DependencyInjectorService.instance.injector.initialize(action);

        return action;
    }

    destroy(): void {
        
    }

}