import { PRNG } from "seedrandom";
import { GameActor } from "../../../ilmare/actors/GameActor";
import { GameEvent } from "../../events/MainSceneEvent";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../models/MachinesModel";
import { PrizeModel } from "../../models/PrizeModel";
import { UserModel } from "../../models/UserModel";
import { EventListenerService } from "../../services/EventListenerService";
import { IAnimationStateListener, IEvent, ITrackEntry, Spine } from '../../../ilmare/spine/SpineFacade';
import { Assets, Sprite } from "pixi.js";
import { BallActor } from "./BallActor";


export class BallAtMachineActor extends BallActor implements IAnimationStateListener {

    protected _rng: PRNG;

    constructor(resources: any, colorAnimation: string, container: any, rng: PRNG) {
        super(resources, colorAnimation, container);
        this._rng = rng;
    }

    public setup() {
        this.loadBall();

        this.initializeSpineAnimationAtPercent(this._ball, "rotations/simple", this._rng.double(), 2);
        this.initializeSpineAnimationAtPercent(this._ball, "rotations/3d", this._rng.double(), 3);
        if (this._rng.double() > 0.5) {
            this._invertedColor = true;
        }
        this.updateColorAnimation();
    }

}
