import "reflect-metadata";
import React from 'react'
import ReactDOM from 'react-dom/client'
//import './index.css'
import { BrowserRouter, HashRouter, useNavigate } from "react-router-dom";
import { Root } from "./reactComponents/Root";
import { DependencyInjectorService } from "./ilmare/di/DependencyInjectorService";
import { SetupController } from './game/setup/SetupController';
import { DataController } from "./game/controllers/DataController";
import { TimersTicker } from "./game/managers/ModelsUpdater";
import { ModalRoot } from "./reactComponents/modal/ModalRoot";
import { OrientationProvider } from "./reactComponents/orientation/OrientationContext";
import { SpineBase } from '@pixi-spine/base';
import { applySpineMixin } from 'pixi-heaven';


applySpineMixin(SpineBase.prototype); // spine-heaven setup (required for spine animation tinting)

const setup = new SetupController();
setup.initialize();

const dataController = new DataController();
DependencyInjectorService.instance.injector.initialize(dataController);
dataController.start();

const timersTicker = new TimersTicker();
DependencyInjectorService.instance.injector.initialize(timersTicker);
timersTicker.start();

const root = ReactDOM.createRoot(document.getElementById('app-root'));
root.render(
    <BrowserRouter>
        <OrientationProvider>
            <Root />
            <ModalRoot />
        </OrientationProvider>
    </BrowserRouter>
);
