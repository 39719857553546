import { GameEvent } from "../../../events/MainSceneEvent";
import { ActionsManager } from "../../../managers/ActionsManager";
import { UserModel } from "../../../models/UserModel";
import { ServerAction } from "../../ServerAction";

export class GetAllWonPrizesAction extends ServerAction {
    protected _actionsManager: ActionsManager = undefined; // injects
    protected _userModel: UserModel = undefined; // injects

    constructor() {
        super("get-user-won-prizes", "get-user-won-prizes-completed");
    }

    public setup(): void {
        
    }

    protected processResponse(evtData: any) {
        console.log('get-user-won-prizes-completed');
        this._userModel.updateWonPrizes(evtData.prizes);
    }

    public destroy(): void {
        
    }

}