import React from "react";
import { ShowCreditsBundlesAction } from "../../../game/actions/credits/ShowCreditsBundlesAction";
import { CreditsModel } from "../../../game/models/CreditsModel";
import { useObservableModel } from "../../../ilmare/models/ObservableModelState";
import { BaseCreditsItem } from "./BaseCreditsItem";
import { CreditsIcon } from "./styles";

export const CreditsItem: React.FC<{ }> = ({ }) => {
    
    const creditsModel = useObservableModel<CreditsModel>('_creditsModel');

    return <BaseCreditsItem icon={<CreditsIcon/>} amount={creditsModel?.creditsAmount} onClickAction={ShowCreditsBundlesAction} />;
};