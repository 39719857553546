import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { MachinesModel } from "../../models/MachinesModel";
import { Action } from "../Action";

export class UpdateMachineViewersAction extends Action {    
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects
    protected _data: any;

    public constructor(data: any) {
        super();
        this._data = data;
    }

    public setup(): void {
        console.log("Update Machine Viewers Action");
    }

    protected payload(): void {        
        /*if (this._currentMachineModel.currentMachineId == this._data.machineId) {
            this._currentMachineModel.updateWatching(this._data.viewers);                        
        }*/
        this._machinesModel.updateViewersLength(this._data.machineId, this._data.viewers);   

        this.finish(true);
    }


    public destroy(): void {
        
    }

}