import { PRNG } from "seedrandom";
import { GameActor } from "../../../ilmare/actors/GameActor";
import { GameEvent } from "../../events/MainSceneEvent";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../models/MachinesModel";
import { PrizeModel } from "../../models/PrizeModel";
import { UserModel } from "../../models/UserModel";
import { EventListenerService } from "../../services/EventListenerService";
import { Assets, Sprite } from "pixi.js";
import { Spine } from '../../../ilmare/spine/SpineFacade';


export class BackgroundActor extends GameActor {

    protected _resources: any;

    protected _eventListener: EventListenerService = undefined; // injects
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects    
    protected _userModel: UserModel = undefined; // injects

    protected _background: Spine;

    constructor(resources: any) {
        super();
        this._resources = resources;
    }

    public setup() {
        this.loadBackground();
    }
    
    protected loadBackground() {
        if (this._background) {
            this._background.destroy();
        }

        this._background = new Spine(this._resources.background.spineData)
        this._background.x = Math.floor(this._owner.owner.screen.width / 2);
        this._background.y = Math.floor(this._owner.owner.screen.height / 2);
        this._background.state.setAnimation(0, "idle", true);
        this._background.autoUpdate = true;
        this._owner.mainContainer.addChild(this._background);
    }


    public update(delta: number) {
        this._background.update(delta);
    }

    public onResize(canvasWidth: number, canvasHeight: number) {
        this._background.x = Math.floor(canvasWidth / 2);
        this._background.y = Math.floor(canvasHeight / 2);
    }
    
    public destroy() {
        if (this._background) {
            this._background.destroy();
        }
    }

}