import React, { useState } from 'react';
import styled from 'styled-components';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { ConfigModel } from '../../../game/models/ConfigModel';
import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { BOEditConfigAction } from '../../../game/actions/backOffice/config/BOEditConfigAction';

// Define the Config type
type Config = {
    [key: string]: any;
};

// Define the props for the component
interface ConfigEditorProps {
}

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Field = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

const Label = styled.label`
  flex: 1;
  font-weight: bold;
`;

const Input = styled.input`
  flex: 2;
  padding: 5px;
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

// ConfigEditor Component
const ConfigEditorEdit: React.FC<ConfigEditorProps> = () => {
    const configModel = useObservableModel<ConfigModel>('_configModel');
    const [editedConfig, setEditedConfig] = useState<Config>({ ...configModel.data });
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    const handleChange = (key: string, value: any) => {
        setEditedConfig((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleSave = () => {
        actionsManager.executeActionInstant(BOEditConfigAction, editedConfig);
    };

    const renderInput = (key: string, value: any) => {
        if (typeof value === 'number') {
            return (
                <Input
                    type="number"
                    value={value}
                    onChange={(e) => handleChange(key, Number(e.target.value))}
                />
            );
        }

        if (typeof value === 'string') {
            return (
                <Input
                    type="text"
                    value={value}
                    onChange={(e) => handleChange(key, e.target.value)}
                />
            );
        }

        return null;
    };

    return (
        <Container>
            {Object.keys(editedConfig).map((key) => (
                <Field key={key}>
                    <Label>{key}</Label>
                    {renderInput(key, editedConfig[key])}
                </Field>
            ))}
            <Button onClick={handleSave}>Save</Button>
        </Container>
    );
};

export default ConfigEditorEdit;
