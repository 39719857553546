import React, { FunctionComponent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PrizeCollectionModel } from '../../../game/models/PrizeCollectionModel';
import { PrizeCollectionsModel } from '../../../game/models/PrizeCollectionsModel';
import { UserModel } from '../../../game/models/UserModel';
import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { GetPrizeCollectionAction } from '../../../game/actions/user/inventory/GetPrizeCollectionAction';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { CollectionContainer, CollectionTitle, CollectionTitleContainer } from './styles';
import { PrizeView } from './PrizeView';
import { usePrizeCollectionsCss } from '../../../game/reactHooks/cssHooks';
import { Loading } from '../../loading/Loading';

type CollectionViewProps = {
}

type CollectionViewData = {
    collection: PrizeCollectionModel,
}

export const PrizeCollectionView: FunctionComponent<CollectionViewProps> = (props) => {

    // al montar el componente, se debe obtener la coleccion de premios y sus premios
    const { id } = useParams<"id">();
    const userModel = useObservableModel<UserModel>('_userModel');
    const prizeCollectionsModel = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const [collectionViewData, setCollectionViewData] = useState<CollectionViewData | null>(null);    
    const [loaded, setLoaded] = useState<boolean>(false);
    
    usePrizeCollectionsCss("all", setLoaded);

    useEffect(() => {
        async function fetchData() {
            await actionsManager.executeActionInstant(GetPrizeCollectionAction, { collectionId: id });
            const collection: PrizeCollectionModel = prizeCollectionsModel.getCollectionById(id);
            setCollectionViewData({ collection: collection });            
        }

        if (!collectionViewData) {
            const collection: PrizeCollectionModel = prizeCollectionsModel.getCollectionById(id);
            if (collection) {                                                
                setCollectionViewData({ collection: collection });                
            } else {
                fetchData();
            }
        }

    }, [collectionViewData]);

    if (!loaded || !collectionViewData) {
        return (
            <Loading/>
        );
    } else {
        const collection: PrizeCollectionModel = prizeCollectionsModel.getCollectionById(id);        
        return (            
            <CollectionContainer>
                <CollectionTitleContainer>
                    <CollectionTitle>{collection.title}</CollectionTitle>
                </CollectionTitleContainer>
                {collectionViewData.collection.prizes.map((prize) => (
                    <PrizeView
                        key={prize.id}
                        prize={prize}
                        isUnlocked={userModel.hasWonPrize(prize.id)}
                        onClick={() => {}}
                    />
                ))}
            </CollectionContainer>
        );
    }

}