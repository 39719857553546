import '../ilmare/spine/SpineFacade' // Do this once at the very start of your code. This registers the loader!

import { BaseGameScene } from '../ilmare/scenes/BaseGameScene';
import { Application } from 'pixi.js';

export default class BaseApplication extends Application {    
    protected _scenes: BaseGameScene[];
    protected _currentWidth: number;
    protected _currentHeight: number;

    constructor(config: any) {
        super(config);
        this._scenes = [];        
    }

    public setup() {           
        this.ticker.add(this.update, this);        
    }

    public createScene(sceneClass: new (...args) => BaseGameScene, ...params): BaseGameScene {
        let scene = new sceneClass(this, ...params);
        this._scenes.push(scene);
        scene.initialize();
        this.stage.addChild(scene);
        scene.onResize(this._currentWidth, this._currentHeight);
        return scene;
    }
    
    protected update(delta: number) {                
        for (const scene of this._scenes) {
            if (scene.visible) {
                scene.update(this.ticker.elapsedMS * 0.001);
            }
        }
    }

    public onResize(canvasWidth: number, canvasHeight: number) {
        this._currentWidth = canvasWidth;
        this._currentHeight = canvasHeight;
        for (const scene of this._scenes) {
            scene.onResize(canvasWidth, canvasHeight);
        }
    }
}