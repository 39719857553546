export default {
    machines: {
        thumbnails: '/assets/machines/thumbnails',
        animations: '/assets/machines/animations'
    },
    prizes: {
        thumbnails: '/assets/prizes/thumbnails',
        images: '/assets/prizes/images'
    },
    ball: {
        animations: '/assets/balls'        
    },
    collections: {
        basePath: '/assets/collections',
    },
    miniGames: {
        dressUp: {
            animations: '/assets/minigames/dressup'
        }
    }
}

