import styled from "styled-components"

export const FooterContainer = styled.div`
    background-color: rgb(89, 26, 101);
    display: block;
`

export const FooterInnerContainer = styled.div`
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: grid;
    grid-auto-flow: column;
    height: 3.5rem;
    background-color: rgb(89, 26, 101);
    transition: bottom 0.3s ease 0s;
    z-index: 999;
`

export const BottomBarItemContainer = styled.div`
    display: flex;
`

