import React, { useState, useEffect, FunctionComponent } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { UserModel } from '../../../game/models/UserModel';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { MachineDescriptor, MachinesModel } from '../../../game/models/MachinesModel';
import { BOCreateMachineAction } from '../../../game/actions/backOffice/machines/BOCreateMachineAction';
import { BOEditMachineAction } from '../../../game/actions/backOffice/machines/BOEditMachineAction';
import { BODeleteMachineAction } from '../../../game/actions/backOffice/machines/BODeleteMachineAction';
import { Loading } from '../../loading/Loading';
import { useParams } from 'react-router-dom';
import InputFormField from '../common/InputFormField';
import { BOError, BOFixedSizeImage, BOFixedSizeImageFromAtlas, BOFormButton, BOPrizeDetails, BORemoveButton, BOStyledForm } from '../styles';
import InitialPrizesFieldArray from './InitialPrizesFieldArray';
import { PrizeCollectionsModel } from '../../../game/models/PrizeCollectionsModel';
import { GetAllPrizeCollectionsAction } from '../../../game/actions/user/inventory/GetAllPrizeCollectionsAction';
import OptionsFormField from '../common/OptionsFormField';
import { useActionOnMount } from '../../../game/reactHooks/actionHooks';
import ManifestFS from '../../../manifest/ManifestFS';
import Path from '../../../utils/Path';
import AssetPaths from '../../../config/AssetPaths';
import { PrizeModel } from '../../../game/models/PrizeModel';


interface MachineCreatorProps {
    
}


export const MachineCreator: FunctionComponent<MachineCreatorProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [machine, setMachine] = useState<MachineDescriptor | null>(null);
    const [allPrizes, setAllPrizes] = useState<{id: string, title: string}[]>([]);
    const [selectedLastOne, setSelectedLastOne] = useState<PrizeModel>(null);

    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const userModel = useObservableModel<UserModel>('_userModel');
    const machinesModel = useObservableModel<MachinesModel>('_machinesModel');
    const prizeCollectionsModel = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');

    useActionOnMount(GetAllPrizeCollectionsAction);

    if (!userModel.isAdmin) {
        return <></>;
    }

    const allCollections = prizeCollectionsModel.allCollections;

    if (allCollections.length == 0) {
        return <></>;
    }

    const thumbnails = ManifestFS.getFiles(AssetPaths.machines.thumbnails).map((file) => { return { id: file, title: file } });
    const animations = ManifestFS.getFiles(AssetPaths.machines.animations).filter((file)=>{
        return file.endsWith(".json");
    }).map((file) => { return { id: file.split('.')[0], title: file } });

    return (
        <div>
            <h1>
                Crear nueva maquina
            </h1>
            {loading ?
                <Loading />
                :
                error ?
                    <p>{error}</p>
                    :
                    <Formik
                        initialValues={{
                            title: "Title",
                            description: "Description",
                            creditsPerPlay: 100,
                            initialPrizes: [],
                            prizesCollection: allCollections.length > 0 ? allCollections[0].id : "",
                            thumbnail: "machine_1.jpeg",
                            animation: "machine_1",
                            lastOne: ""
                        }}
                        enableReinitialize={true}
                        onSubmit={(values, { setSubmitting }) => {
                            setLoading(true);

                            actionsManager.executeActionInstant(BOCreateMachineAction, values);
                        }}
                        validate={(values) => {
                            let errors: any = {};

                            // Comprobar si hay IDs duplicados en initialPrizes
                            const ids = values.initialPrizes.map(prize => prize.id);
                            const uniqueIds = new Set(ids);

                            if (ids.length !== uniqueIds.size) {
                                errors.initialPrizes = "Los IDs de los premios iniciales no deben ser duplicados";
                            }

                            return errors;
                        }}
                    >
                        {({ values, setFieldValue }) => (
                            <BOStyledForm>

                                <InputFormField label="Titulo" name="title" />
                                <InputFormField label="Descripcion" name="description" />
                                <InputFormField label="Creditos por partida" name="creditsPerPlay" type="number" />
                                <OptionsFormField label="Coleccion" name="prizesCollection" options={prizeCollectionsModel.allCollections} onChange={(e) => {
                                    setFieldValue('initialPrizes', []);

                                    const collection = prizeCollectionsModel.getCollectionById(e.target.value);
                                    if (collection) {                                        
                                        const prizes = collection.prizes.map((prize: PrizeModel) => { return { id: prize.id, title: prize.title } });
                                        setAllPrizes(prizes);
                                    }                                    
                                }} />

                                <OptionsFormField label="Last One" name="lastOne" options={allPrizes} onChange={(e) => {
                                    const collection = prizeCollectionsModel.getCollectionById(values.prizesCollection);
                                    setSelectedLastOne(collection.getPrizeById(e.target.value));
                                }}/>
                                <BOPrizeDetails>
                                    <p>{selectedLastOne?.title}</p>
                                    <BOFixedSizeImageFromAtlas className={selectedLastOne?.cssClass}/>
                                </BOPrizeDetails>                                

                                <InitialPrizesFieldArray values={values} setFieldValue={setFieldValue} />
                                <ErrorMessage name="initialPrizes" component={BOError} />

                                <OptionsFormField label="Imagen" name="thumbnail" options={ thumbnails }  />
                                <BOFixedSizeImage src={Path.join(AssetPaths.machines.thumbnails, values.thumbnail)} alt={values.title} />

                                <OptionsFormField label="Animacion" name="animation" options={ animations }  />                          
                                <BOFixedSizeImage src={Path.join(AssetPaths.machines.animations, values.animation.split('.')[0] + '.png')} alt={values.title} />      

                                <BOFormButton type='submit'>
                                    Crear
                                </BOFormButton>
                            </BOStyledForm>)}
                    </Formik>
            }
        </div>
    );
}



