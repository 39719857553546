import React, { FunctionComponent } from 'react';
import { useNavigate } from "react-router-dom";
import { UserNavigationBarItemImage, UserNavigationBarItemLink } from './styles';


export enum UserNavigationBarItemTypes {
    HOME,
    USER_PROFILE,
    LEADERBOARDS,
    INVENTORY,
    DOLL_DRESSUP_MINIGAME,
}

type UserNavigationBarItemProps = {
    type: UserNavigationBarItemTypes
}

export const UserNavigationBarItem: FunctionComponent<UserNavigationBarItemProps> = (props) => {

    const navigate = useNavigate();

    let imgSrc = "";
    let navigateTo = "";

    switch (props.type) {
        case UserNavigationBarItemTypes.HOME: 
            imgSrc = "/assets/icons/home.svg";
            navigateTo = "/";
            break;
        case UserNavigationBarItemTypes.USER_PROFILE: 
            imgSrc = "/assets/icons/user.svg";
            navigateTo = "/user/profile/view";
            break;
        case UserNavigationBarItemTypes.LEADERBOARDS: 
            imgSrc = "/assets/icons/leaderboards.svg";
            navigateTo = "/leaderboards/view";
            break;
        case UserNavigationBarItemTypes.INVENTORY: 
            imgSrc = "/assets/icons/inventory.svg";
            navigateTo = "/user/inventory/view";
            break;
        case UserNavigationBarItemTypes.DOLL_DRESSUP_MINIGAME: 
            imgSrc = "/assets/icons/doll.svg";
            navigateTo = "/game/dressup";
            break;

    }

    return (
        <UserNavigationBarItemLink onClick={
                (evt) => {
                    navigate(navigateTo);
                }
            }>

            <UserNavigationBarItemImage src={imgSrc}/>
        </UserNavigationBarItemLink>                
    );
}