import { GameModel } from "../../ilmare/models/GameModel";

export enum CurrentMachineStatus {
    PLAYING,
    IN_QUEUE,
    WATCHING,
    WAITING
}

export class CurrentMachineModel extends GameModel {

    protected _currentMachineId: string;
    protected _status: CurrentMachineStatus;
    protected _queue: string[];
    protected _queuePosition: number;
    protected _fillLevel: number;
    protected _prizesAnimationTypes: any = {};

    public init(machineId: string, status: string, queuePosition: number, fillLevel: number) {
        this._currentMachineId = machineId;

        switch (status) {
            case "playing":
                this._status = CurrentMachineStatus.PLAYING;
                break;
            case "in-queue":
                this._status = CurrentMachineStatus.IN_QUEUE;
                break;
            case "watching":
                this._status = CurrentMachineStatus.WATCHING;
                break;
            case "waiting":
                this._status = CurrentMachineStatus.WAITING;
                break;
        }

        this._queuePosition = queuePosition;
        this._fillLevel = fillLevel;
        this.notifyChange();
    }

    public updateStatus(evt: any) {
        if (this._currentMachineId != evt.machineId) {            
            return; //throw new Error("Cannot update a different machine");
        }

        this._queuePosition = evt.queuePosition ? evt.queuePosition : this._queuePosition;

        switch (evt.result) {
            case "ready-to-play":
                this._status = CurrentMachineStatus.PLAYING;
                break;
            case "waiting-in-queue":
            case "added-to-queue":
                this._status = CurrentMachineStatus.IN_QUEUE;
                break;
            case "watching":
                this._status = CurrentMachineStatus.WATCHING;
                break;
            case "unable-to-add-to-queue":
                if (evt.reason === "already-in-this-queue") {
                    if (this._queuePosition == 0) {
                        this._status = CurrentMachineStatus.PLAYING;
                    } else {
                        this._status = CurrentMachineStatus.IN_QUEUE;
                    }                    
                } else {
                    this._status = CurrentMachineStatus.WATCHING;
                }                
                break;
            case "waiting":
                this._status = CurrentMachineStatus.WAITING;
                break;
        }
        this.notifyChange();
    }

    public forceWaitingState() {
        this._status = CurrentMachineStatus.WAITING;
        this.notifyChange();
    }

    public forcePlayingState() {
        this._status = CurrentMachineStatus.PLAYING;
        this.notifyChange();
    }

    public updateFillLevel(fillLevel: number) {
        this._fillLevel = fillLevel;
        this.notifyChange();
    }

    public updateQueuePosition(position: number) {
        this._queuePosition = position;
        this.notifyChange();
    }

    public updateQueue(evt: any) {

    }

    public get queuePosition(): number {
        return this._queuePosition;
    }

    public get currentMachineId() {
        return this._currentMachineId;
    }

    public get status(): CurrentMachineStatus {
        return this._status;
    }

    public get initialized(): boolean {
        return this._currentMachineId !== undefined;
    }

    public get fillLevel(): number {
        return this._fillLevel;
    }

    public get prizesAnimationTypes() {
        return this._prizesAnimationTypes;
    }

    public setup() {
    
    }

    public destroy() {

    }
}