import { GameEvent } from "../../events/MainSceneEvent";
import { ActionsManager } from "../../managers/ActionsManager";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { PrizeModel } from "../../models/PrizeModel";
import { UserModel } from "../../models/UserModel";
import { EventListenerService } from "../../services/EventListenerService";
import { Action } from "../Action";
import { ServerAction } from "../ServerAction";
import { UpdateCreditsAction } from "../user/UpdateCreditsAction";

export class PlayAtMachineAction extends ServerAction {
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects   
    protected _eventListener: EventListenerService = undefined; // injects
    protected _userModel: UserModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects
    
    constructor(machineId: string) {        
        super("play-at-machine", "play-at-machine-completed", { machineId: machineId });
    }
    
    public setup(): void {
        console.log("Play At Machine Action");
    }

    protected processResponse(evtData: any) {

        this._actionsManager.executeActionInstant(UpdateCreditsAction);

        
        switch(evtData.result) {
            case "prize-awarded":                
                let prizeModel: PrizeModel = this._userModel.addWonPrize(evtData.prize);

                this._eventListener.emit(GameEvent.START_PRIZE_PRESENTATION, prizeModel);
                break;
            case "error":
                // el popup error lo maneja automaticamente, aca solo tengo que cerrar que hacer con el action
                this._currentMachineModel.forcePlayingState();
                break;
        }

    }

    public destroy(): void {
        
    }

}