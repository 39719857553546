import React, { useState, useEffect, FunctionComponent } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { UserModel } from '../../../game/models/UserModel';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { MachineDescriptor, MachinesModel } from '../../../game/models/MachinesModel';
import { BOEditMachineAction } from '../../../game/actions/backOffice/machines/BOEditMachineAction';
import { Loading } from '../../loading/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import InputFormField from '../common/InputFormField';
import { BOAddButton, BOCollectionRow, BOContainer, BOError, BOFixedSizeImage, BOFormButton, BOMachineRow, BORemoveButton, BOStyledForm, BOTitle } from '../styles';
import { BOUpdateMachineStateAction } from '../../../game/actions/backOffice/machines/BOUpdateMachineStateAction';
import { BODeleteMachineAction } from '../../../game/actions/backOffice/machines/BODeleteMachineAction';
import Path from '../../../utils/Path';
import AssetPaths from '../../../config/AssetPaths';
import { BORefillMachineAction } from '../../../game/actions/backOffice/machines/BORefillMachineAction';

interface MachinesEditorListProps {    
}


export const MachinesEditorList: FunctionComponent<MachinesEditorListProps> = (props) => {
    const machinesModel = useObservableModel<MachinesModel>('_machinesModel');
    const userModel = useObservableModel<UserModel>('_userModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const navigate = useNavigate();

    if (!userModel.isAdmin) {
        return <></>;
    }

    return (
        <BOContainer>
            <BOTitle>
                Machines List
                <BOAddButton onClick={() => navigate("/bo/machine/create")}>Crear</BOAddButton>
            </BOTitle>
            {machinesModel.machines.map((machine) => (
                <BOMachineRow key={machine._id}>
                    <div><BOFixedSizeImage src={`${Path.join(AssetPaths.machines.thumbnails, machine.thumbnail)}`} alt={machine.title}  /></div>
                    
                    <div>
                        <strong>{machine.title}</strong>
                        <p>{machine.description}</p>
                    </div>
                    <div>
                        <span>{machine.creditsPerPlay} Credits</span>
                    </div>
                    <div>
                        <span>{machine.state}</span>
                    </div>
                    <div>
                        <BOFormButton onClick={() => navigate(`/bo/machine/edit/${machine._id}`)}>Edit</BOFormButton>
                        {machine.state === 'published' && (
                            <BOFormButton onClick={async () => actionsManager.executeActionInstant(BOUpdateMachineStateAction, machine._id, "draft")}>Unpublish</BOFormButton>
                        )}
                        {machine.state === 'draft' && (
                            <BOFormButton onClick={async () => actionsManager.executeActionInstant(BOUpdateMachineStateAction, machine._id, "published")}>Publish</BOFormButton>
                        )}
                        <BOFormButton onClick={async () => actionsManager.executeActionInstant(BORefillMachineAction, machine._id)}>Refill</BOFormButton>
                        {machine.state !== 'deleted' && (
                            <BORemoveButton onClick={async () => actionsManager.executeActionInstant(BODeleteMachineAction, machine._id)}>Delete</BORemoveButton>
                        )}
                        
                    </div>
                </BOMachineRow>
            ))}
        </BOContainer>
    );
}