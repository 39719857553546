import { FaPlus } from "react-icons/fa"
import styled from "styled-components"

export const AddCreditsButton = styled.button`
    grid-area: button / button / button / button;
    text-align: center;
    padding-top: 0.2rem;
    width: 1.3em;
    height: 1.3em;
    font-size: 1.875rem;
    color: rgb(255, 255, 255);
    background-color: rgb(102, 187, 102);
    border-bottom: 0.1875rem solid rgb(65, 117, 65);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0.625rem;
    cursor: pointer;
`

export const CreditsContainer = styled.div`
    position: relative;
    display: grid;
    grid-template: "icon points" 2rem / 1.25rem auto;
    color: rgb(255, 255, 255);
`;

export const CreditsIcon = styled.div`
  width: 2em;
  height: 2em;
  margin: 0em;
  margin-left: 0em;
  background-image: url('/assets/icons/credits.png');
  background-size: cover;
  background-repeat: no-repeat;
`;

export const CreditsAmountContainer = styled.div`
    font-size: 0.875rem;
`;

export const CreditsAmount = styled.p`
    padding-left: 0.7em;
    padding-right: 0.7em;
    text-align: center;
    font-size: 1.25rem;
    font-family: Heebo, Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin-top: 0.3em;
    animation: 0s ease 0s 1 normal none running none;
`;


export const AddCreditsIcon = styled(FaPlus)`
  width: 0.8em;
  height: 0.8em;
`;

export const AddCreditsButtonContainer = styled.div`
  margin-top: -0.15em;
`;

