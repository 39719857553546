import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { CurrentMachineModel } from "../../../game/models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../../game/models/MachinesModel";
import { PlayButton } from "./PlayButton";
import { ActionButtonsContainer, Grid3ColumnContainer, InfoAreaContent, MachineAnimationContainer } from "./styles";
import { InfoButton } from "./InfoButton";
import { useObservableModel } from "../../../ilmare/models/ObservableModelState";
import SpineAnimationComponent from "../../../ilmare/animations/SpineAnimationComponent";
import { BootScene } from "../../../game/scenes/BootScene";
import MachineApplication from "../../../game/MachineApplication";
import { MachineInfoViewers } from "./MachineInfoViewers";
import { MachineInfoQueue } from "./MachineInfoQueue";
import { CreditsItem } from "../../credits/refillableDisplay/CreditsItem";
import { BackToHomeButton } from "./BackToHomeButton";
import { CancelButton } from "./CancelButton";
import { TimeLeftBar } from "./TimeLeftBar";
import { GameLayout } from "../../layout/GameLayout";
import { Loading } from "../../loading/Loading";
import { PrizeCollectionsModel } from "../../../game/models/PrizeCollectionsModel";


type MachineViewProps = {

}


export const MachineView: FunctionComponent<MachineViewProps> = () => {

    const { id } = useParams<"id">();
    const machinesModel = useObservableModel<MachinesModel>('_machinesModel');
    const currentMachineModel = useObservableModel<CurrentMachineModel>('_currentMachineModel');
    const prizesCollection = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');

    let machine: MachineDescriptor = machinesModel.getMachineById(id);
    if (prizesCollection && machine) {
        let currentPrizesCollection = prizesCollection.getCollectionById(machine.prizesCollection);
        
        if (currentMachineModel.initialized && currentPrizesCollection) {
            return (
                <GameLayout
                    playArea={
                        // key in SpineAnimationComponent forces umount the component on id change
                        <MachineAnimationContainer>
                            <SpineAnimationComponent BootSceneClass={BootScene} PixiApplicationClass={MachineApplication} key={id} />
                        </MachineAnimationContainer>
                    }
                    infoArea={
                        <Grid3ColumnContainer>
                            <BackToHomeButton />
                            <InfoAreaContent>
                                <MachineInfoViewers />
                                <MachineInfoQueue />
                            </InfoAreaContent>
                            <InfoButton id={id} />
                        </Grid3ColumnContainer>
                    }
                    currenciesArea={
                        <CreditsItem />
                    }
                    buttonArea={
                        <ActionButtonsContainer>
                            <PlayButton id={id} />
                            <TimeLeftBar max={1} color={'#007bff'} />
                            <CancelButton id={id} />
                        </ActionButtonsContainer>
                    }
                />
            );
        }
    }
 
    return <Loading />;
 
}

