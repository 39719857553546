import React, { useState, useEffect, FunctionComponent } from 'react';
import { CurrentMachineModel, CurrentMachineStatus } from '../../../game/models/CurrentMachineModel';
import styled, { keyframes } from "styled-components"
import { CurrentQueueModel } from '../../../game/models/CurrentQueueModel';
import { ConfigModel } from '../../../game/models/ConfigModel';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { format, addSeconds } from 'date-fns';

type TimeLeftBarProps = {
    //value: number, 
    max: number,
    color: number | string,
}


type TimeLeftBarContainerProps = {
    colora: number | string,
}

const barAnimation = keyframes`
    0% {
        background-position: 50px 0;
    }
    100% {
        background-position: 0 0;
    }
}` 

const ProgressBarContainer = styled.div`
    position: relative;
    width: 90%;
    height: 1.5em;
    background-color: #eee;
    border-radius: 0.5em;
    margin: 0 auto;
    overflow: hidden;
`;

const ProgressBar = styled.div<TimeLeftBarContainerProps>`
    height: 100%;
    background-color: ${props => props.colora};
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0));
    background-size: 50px 50px;
    animation: ${barAnimation} 2s linear infinite;
    border-radius: 0.5em;
    width: 0%; 
    transition: width 0.5s ease;
`;

const ProgressText = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    z-index: 1;
    text-align: center;
    font-size: 1.25rem;
    font-family: Heebo, Helvetica, Arial, sans-serif;
    font-weight: 700;
    animation: 0s ease 0s 1 normal none running none;  
`;

export const TimeLeftBar: FunctionComponent<TimeLeftBarProps> = ({ max, color }) => {
    const currentMachine = useObservableModel<CurrentMachineModel>('_currentMachineModel');
    const currentQueue = useObservableModel<CurrentQueueModel>('_currentQueueModel');
    const config = useObservableModel<ConfigModel>('_configModel');

    //console.log("TUVIEJA", currentQueue.timeLeft, currentMachine.status == CurrentMachineStatus.PLAYING);

    if (currentMachine.status === CurrentMachineStatus.PLAYING) {        
        let timeIsUndefined = currentQueue.timeLeft === undefined;
        let hasValidTime = currentQueue.timeLeft && currentQueue.timeLeft > 0;

        let value = hasValidTime ? currentQueue.timeLeft / (config.data.TIME_TO_PLAY / 1000.0) : (timeIsUndefined ? 0.0 : 1.0);        
        let dynamicColor = hasValidTime && currentQueue.timeLeft <= 10 ? '#dc3545' : color;

        return (
            <ProgressBarContainer>
                <ProgressBar colora={dynamicColor} style={{ width: `${value * 100.0}%` }} />
                { hasValidTime ? <ProgressText>{format(addSeconds(new Date(0), currentQueue.timeLeft + 1), 'mm:ss')}</ProgressText> : (timeIsUndefined ? <ProgressText>00:00</ProgressText> : <></>) }
            </ProgressBarContainer>
        );
    } else {
        return <></>;
    }
};