import { GameModel } from "../../ilmare/models/GameModel";

export class ServerErrorModel extends GameModel {
    public title: string;
    public message: string;    
    public hasError: boolean = false;
    public iconPath: string;

    public static errorDefs = [
        {
            reason: "machine-empty",
            iconPath: "",
            title: "Oops!",
            message: "Machine has no more prizes!"
        }, 
        {
            reason: "already-in-this-queue",
            iconPath: "",
            title: "Information",
            message: "You are already in this queue"
        },
        {
            reason: "already-in-another-queue",
            iconPath: "",
            title: "Information",
            message: "You can only join one queue at a time"
        }
    ];

    protected findDefFor(reason) {
        for (let def of ServerErrorModel.errorDefs) {
            if (reason == def.reason) {
                return def;
            }
        }
        return null;
    }

    public canHandle(evtData: any) {
        return true; //this.findDefFor(evtData.reason); // Can handle any error message
    }

    public init(evtData: any) {        

        this.hasError = true;

        let def = this.findDefFor(evtData.reason);
        if (def) {
            this.iconPath = def.iconPath;
            this.title = def.title;
            this.message = def.message;

        } else {
            this.iconPath = "";
            this.title = "Unknown Error";
            this.message = evtData.reason;
        }

        this.notifyChange();
    }
    
    public clear() {
        this.title = undefined;
        this.message = undefined;
        this.hasError = false;

        this.notifyChange();
    }

    public setup() {
        
    }

    public destroy() {
        
    }

}