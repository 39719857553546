import { InfoPopupPreset } from "../../../../reactComponents/modal/presets/InfoPopupPreset";
import { ActionsManager } from "../../../managers/ActionsManager";
import { ConfigModel } from "../../../models/ConfigModel";
import { CurrentMachineModel } from "../../../models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../../models/MachinesModel";
import { ModalPopupModel } from "../../../models/ModalPopupModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { ServerAction } from "../../ServerAction";

export class BOEditConfigAction extends ServerAction {            
    protected _configModel: ConfigModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects    
    protected _modalPopupModel: ModalPopupModel = undefined; // injects
    
    constructor(data: any) {        
        super("bo-edit-config", "bo-edit-config-completed", { data: data });
    }
    
    public setup(): void {
        console.log("BO Edit Config Action");
    }

    protected processResponse(evtData: { result: string, config: any }) {
        if (evtData.result === "config-edited") {            
            this._configModel.populate(evtData.config);
            this._modalPopupModel.initWithContent(InfoPopupPreset, { title: 'Configuracion editada', message: 'La configuracion se ha editado correctamente' });
        }
    }

    public destroy(): void {
        
    }

}