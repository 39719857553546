import { GameModel } from "../../ilmare/models/GameModel";

export class CreditsModel extends GameModel {
    
    protected _creditsAmount: number = 0;
    
    public get creditsAmount() {        
        return this._creditsAmount;
    }

    public set creditsAmount(val: number) {        
        this._creditsAmount = val;
        this.notifyChange();
    }

    public get reactCreditsAmount(): Readonly<number> {
        return this._creditsAmount;
    }

    public setup() {
        
    }

    public destroy() {
        
    }

}