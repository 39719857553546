import { GameEvent } from "../../../events/MainSceneEvent";
import { ActionsManager } from "../../../managers/ActionsManager";
import { PrizeCollectionModel } from "../../../models/PrizeCollectionModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { UserModel } from "../../../models/UserModel";
import { ServerAction } from "../../ServerAction";

export class GetPrizeCollectionAction extends ServerAction {
    protected _actionsManager: ActionsManager = undefined; // injects
    protected _userModel: UserModel = undefined; // injects
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects

    constructor(data: { collectionId: string }) {
        super("get-prize-collection", "get-prize-collection-completed", { collectionId: data.collectionId });
    }

    public setup(): void {
        
    }

    protected processResponse(evtData: any) {        
        console.log('get-prize-collection-completed');

        let prizeCollection = new PrizeCollectionModel();
        prizeCollection.populate(evtData.collection);

        this._prizeCollectionsModel.addCollection(evtData.collection._id, prizeCollection);
    }

    public destroy(): void {
        
    }

}