import { IInjectable } from "../di/IInjectable";
import { BaseGameScene } from "../scenes/BaseGameScene";

export abstract class GameActor implements IInjectable {    
    protected _owner: BaseGameScene;
    
    constructor() {
        
    }

    public set owner(scene: BaseGameScene) {
        this._owner = scene;
    }

    public abstract update(delta: number);

    public abstract setup();
    public abstract destroy();

    public abstract onResize(canvasWidth: number, canvasHeight: number);
}