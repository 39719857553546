import { IInjectable } from "../di/IInjectable";
import { BaseGameScene } from "../scenes/BaseGameScene";

export abstract class GameController implements IInjectable {

    protected _owner: BaseGameScene;
    
    constructor() {
        
    }

    public set owner(scene: BaseGameScene) {
        this._owner = scene;
    }

    public abstract setup();
    public abstract destroy();
    
}