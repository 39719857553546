import { IInjectable } from "../di/IInjectable";
import { EventEmitter } from 'events';

export abstract class GameModel implements IInjectable {

    private eventEmitter = new EventEmitter();

    protected notifyChange() {
        this.eventEmitter.emit('change', this);
    }

    public subscribe<T extends GameModel>(callback: (model: T) => void) {
        this.eventEmitter.on('change', callback);
    }

    public unsubscribe<T extends GameModel>(callback: (model: T) => void) {
        this.eventEmitter.off('change', callback);
    }

    public abstract setup();
    public abstract destroy();

    public serialize() {
        const { eventEmitter, ...serializedObject } = this;
        return serializedObject;
    }

    public unserialize(data: any) {
        Object.assign(this, data);
    }    

}