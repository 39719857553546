import { MachinesModel } from "../../models/MachinesModel";
import { ServerAction } from "../ServerAction";


export class UpdateMachinesAction extends ServerAction {
    protected _machinesModel: MachinesModel = undefined; // injects

    constructor() {
        super("get-machines", "get-machines-completed");
    }

    public setup(): void {
        console.log("Update Machines Action");
    }

    protected processResponse(evtData: any) {
        this._machinesModel.init(evtData.machines);
    }    

    public destroy(): void {
        
    }

}