import { GameEvent } from "../../events/MainSceneEvent";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { CurrentQueueModel } from "../../models/CurrentQueueModel";
import { Action } from "../Action";

export class QueueUpdatedAction extends Action {
    protected _currentQueueModel: CurrentQueueModel = undefined; // injects
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects
    protected _data: any;

    public constructor(data: any) {
        super();
        this._data = data;
    }

    public setup(): void {
        console.log("Queue Updated Action");
    }

    protected payload() {
        console.log("### QueueUpdatedAction.payload", this._data);
        this._currentQueueModel.update(this._data);
        this._currentMachineModel.updateStatus(this._data);        

        this.finish(true);
    }

    public destroy(): void {
        
    }

}