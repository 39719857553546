import { FaArrowCircleLeft, FaBackward, FaEye, FaHome, FaInfo, FaPlus, FaPlusCircle, FaUserFriends } from "react-icons/fa"
import styled, { keyframes } from "styled-components"



export const InfoArea = styled.div`
  flex: 0 0 auto;
  padding: 0.5em;
  background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (orientation: landscape) {    
    flex: 0 0 auto;
  }
  @media (orientation: portrait) {    
    flex: 0 0 auto;
    padding: 0.5em;
  }

`;

export const PlayArea = styled.div`
  flex: 1;
  background-color: lightcoral;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (orientation: landscape) {    
    height: 100%;
  }
  @media (orientation: portrait) {    
    flex: 7;
  }

`;

export const CurrenciesArea = styled.div`
  flex: 0 0 auto;
  background-color: lightyellow;
  display: flex;
  justify-content: center;
  align-items: center;  

  @media (orientation: landscape) {    
    flex: 0 0 auto;
  }
  @media (orientation: portrait) {    
    flex: 0 0 auto;
  }

`;


export const ButtonsArea = styled.div`
  flex: 1;  
  display: flex;
  background: radial-gradient(circle at center, #d13a5e 0%, #c12a4e 50%, #e14a6e 100%);
  justify-content: center;
  align-items: center;
  padding: 1em;
  overflow: scroll;

  @media (orientation: landscape) {    
    flex: 3;
  }
  @media (orientation: portrait) {    
    flex: 3;
  }

`;

export const AreasContainerLandscape = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

export const AreasContainerPortrait = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;  
`;
