import { ActionsManager } from "../../../managers/ActionsManager";
import { MachineDescriptor, MachinesModel } from "../../../models/MachinesModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { ServerAction } from "../../ServerAction";

export class BODeleteMachineAction extends ServerAction {    
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects
    
    constructor(id: string) {        
        super("bo-delete-machine", "bo-delete-machine-completed", { id: id });
    }
    
    public setup(): void {
        console.log("BO Delete Machine Action");
    }

    protected processResponse(evtData: { result: string, machineId: string }) {
        if (evtData.result === "machine-deleted") {
            this._machinesModel.removeMachine(evtData.machineId);
        }        
    }

    public destroy(): void {
        
    }

}