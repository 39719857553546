import BaseApplication from "../../ilmare/BaseApplication";
import { DependencyInjectorService } from "../../ilmare/di/DependencyInjectorService";
import { BaseGameScene } from "../../ilmare/scenes/BaseGameScene";
import { CurrentMachineModel } from "../models/CurrentMachineModel";
import { MachinesModel } from "../models/MachinesModel";
import { PrizeCollectionsModel } from "../models/PrizeCollectionsModel";
import { EventListenerService } from "../services/EventListenerService";
import { ServerService } from "../services/ServerService";
import { LoadCommonResourcesScene } from "./LoadCommonResourcesScene";

export class BootScene extends BaseGameScene 
{
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects

    constructor(owner: BaseApplication) {        
        super(owner, "BootScene");
    }

    public setup() {        
        let machine = this._machinesModel.getMachineById(this._currentMachineModel.currentMachineId);
        let collection = this._prizeCollectionsModel.getCollectionById(machine.prizesCollection)
        
        this._owner.createScene(
            LoadCommonResourcesScene, 
            this._machinesModel.getMachineById(this._currentMachineModel.currentMachineId).animation,
            collection.minigame.codename
        );
    }

    public destroy() {
        
    }
}
