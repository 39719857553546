import { Action } from "../Action";

export class ForwardEmitAction extends Action {

    protected _message: string;
    protected _args;

    constructor(...args) {
        super();
        this._message = args[0].message;
        this._args = args[0];
    }

    public setup(): void {
        console.log("Forwarding message " + this._message);
    }

    protected payload() {
        this._eventListener.emit(this._message, this._args);
        this.finish(true);
    }

    public destroy(): void {
        
    }

}