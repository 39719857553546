import { GameActor } from "../actors/GameActor";
import { DependencyInjectorService } from "../di/DependencyInjectorService";
import { IInjectable } from "../di/IInjectable";
import BaseApplication from "../BaseApplication";
import { Container } from "pixi.js";


export abstract class BaseGameScene extends Container implements IInjectable {

    protected _owner: BaseApplication;
    protected _name: string;
    protected _actors: Array<GameActor>;
    protected _mainContainer: Container;

    public constructor(owner: BaseApplication, name: string) {
        super();

        this._name = name;
        this._owner = owner;
        this._actors = [];
        this._mainContainer = new Container();
        this._owner.stage.addChild(this._mainContainer);
    }

    public abstract setup();
    public abstract destroy();

    public get owner(): BaseApplication {
        return this._owner;
    }

    public get mainContainer(): Container {
        return this._mainContainer;
    }

    public initialize() {        
        DependencyInjectorService.instance.injector.initialize(this);
    }

    public addActor(actor: GameActor) {
        actor.owner = this;        

        DependencyInjectorService.instance.injector.initialize(actor);

        this._actors.push(actor);
    }

    public update(delta: number): void {               

        for (let actor of this._actors) {
            actor.update(delta);
        }
    }

    public onResize(canvasWidth: number, canvasHeight: number) {

        this._mainContainer.pivot.x = canvasWidth / 2;
        this._mainContainer.pivot.y = canvasHeight / 2;
        this._mainContainer.x = canvasWidth / 2;
        this._mainContainer.y = canvasHeight / 2;

        // calcular la escala basandonos en un viewport de 40x80
        let scaleX = canvasWidth / 700;
        let scaleY = canvasHeight / 1050;
        let scale = Math.min(scaleX, scaleY);
        this._mainContainer.scale.x = this._mainContainer.scale.y = scale;        
        //this._mainContainer.scale.x = this._mainContainer.scale.y = 0.5;

        for (let actor of this._actors) {    
            actor.onResize(canvasWidth, canvasHeight);
        }
    }

}
