import { ActionsManager } from "../../../managers/ActionsManager";
import { CurrentMachineModel } from "../../../models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../../models/MachinesModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { ServerAction } from "../../ServerAction";

export class BOAutoFillCollectionAction extends ServerAction {        
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects    
    
    constructor(data: any) {        
        super("bo-auto-fill-collection", "bo-auto-fill-collection-completed", { data: data });
    }
    
    public setup(): void {
        console.log("BO Auto Fill Collection Action");
    }

    protected processResponse(evtData: { result: string, collection: any }) {
        if (evtData.result === "collection-edited") {
            debugger;
            this._prizeCollectionsModel.updateCollection(evtData.collection);
        }
    }

    public destroy(): void {
        
    }

}