import { ActionsManager } from "../../managers/ActionsManager";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { ServerErrorModel } from "../../models/ServerErrorModel";
import { ServerAction } from "../ServerAction";
import { UpdateCurrentQueueAction } from "../user/UpdateCurrentQueueAction";

export class ReserveMachineAction extends ServerAction {
    protected _machineId: string;
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects
    protected _serverErrorModel: ServerErrorModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects
    
    constructor(machineId: string) {
        super('reserve-machine', 'reserve-machine-completed', { machineId: machineId });
        this._machineId = machineId;
    }

    public setup() {
        
    }

    protected processResponse(evtData: any) {
        this._currentMachineModel.updateStatus(evtData);
        if (evtData.result == "unable-to-add-to-queue") {
            this._serverErrorModel.init(evtData);
        }
        this._actionsManager.executeActionInstant(UpdateCurrentQueueAction);
    }

    public destroy(): void {
        
    }

}