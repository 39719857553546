import React, { FunctionComponent, useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import { CreditsModel } from "../../game/models/CreditsModel";
import { useInjection } from "../../game/reactHooks/InjectHooks";
import { AuthStatus } from "../auth/AuthStatus";
import { CurrentQueuePanel } from '../mainContent/queue/CurrentQueuePanel';
import { FooterContainer, FooterInnerContainer, BottomBarItemContainer } from './styles';
import { useOrientation } from '../orientation/OrientationContext';
import { UserNavigationBar } from '../navigation/UserNavigationBar';
import { BONavigationBar } from '../backOffice/navigation/BONavigationBar';
import { isMobile } from 'react-device-detect';


type FooterProps = {

}

export const Footer: FunctionComponent<FooterProps> = () => {

    const orientation = useOrientation();

    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const [showFooter, setShowFooter] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (lastScrollY > currentScrollY) {
                setShowFooter(true);
            } else {
                setShowFooter(false);
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]
    );

    return showFooter ? (
        <FooterContainer>
            <FooterInnerContainer>
                <BottomBarItemContainer>
                    <CurrentQueuePanel />
                </BottomBarItemContainer>
                { isMobile && orientation != 'landscape' ? <UserNavigationBar /> : <></>}                
            </FooterInnerContainer>
        </FooterContainer>
    ) : <></>;

}