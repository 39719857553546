import firebaseConfig from "../../../firebase/firebaseConfig";
import { Action } from "../Action";

export class OnUserLogoutAction extends Action {
    
    public constructor(data) {
        super();
    }

    public setup(): void {
        
    }

    protected payload() {

        firebaseConfig.auth.signOut().then(() => {
            window.location.reload(); // reload the page to reset the socket listeners    
        }).catch((error) => {
            console.error('Error while logging out', error);
        });

        this.finish(true);        
    }

    public destroy(): void {
        
    }

}