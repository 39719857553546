import { DependencyInjectorService } from '../../ilmare/di/DependencyInjectorService';
import { EventListenerService } from '../services/EventListenerService';
import { ServerService } from '../services/ServerService';
import { CreditsModel } from '../models/CreditsModel';
import { MachinesModel } from '../models/MachinesModel';
import { GameController } from '../../ilmare/controllers/GameController';
import { CurrentMachineModel } from '../models/CurrentMachineModel';
import { UserModel } from '../models/UserModel';
import { ActionFactory } from '../factory/ActionFactory';
import { ActionsManager } from '../managers/ActionsManager';
import { ServerErrorModel } from '../models/ServerErrorModel';
import { CurrentQueueModel } from '../models/CurrentQueueModel';
import { ConfigModel } from '../models/ConfigModel';
import { ModalPopupModel } from '../models/ModalPopupModel';
import { PrizeCollectionsModel } from '../models/PrizeCollectionsModel';
import { CreditsBundlesModel } from '../models/CreditsBundlesModel';
import { MinigamesModel } from '../models/MinigamesModel';
import { DressUpModel } from '../miniGames/dressUp/models/DressUpModel';


export class SetupController extends GameController {

    public initialize() {
        this.setupInjection();
        this.setupServices();
        this.setupModels();
        this.setupFactories();
        this.setupManagers();
        this.setupControllers();
        
        DependencyInjectorService.instance.injector.process();
    }

    public setup() {

    }

    protected setupInjection() {
        DependencyInjectorService.instance.setup();
    }

    protected setupServices() {
        DependencyInjectorService.instance.injector.map("_eventListener", new EventListenerService());
        DependencyInjectorService.instance.injector.map("_server", new ServerService());
    }

    protected setupModels() {
        DependencyInjectorService.instance.injector.map("_configModel", new ConfigModel());
        DependencyInjectorService.instance.injector.map("_creditsModel", new CreditsModel());
        DependencyInjectorService.instance.injector.map("_machinesModel", new MachinesModel());
        DependencyInjectorService.instance.injector.map("_currentMachineModel", new CurrentMachineModel());
        DependencyInjectorService.instance.injector.map("_userModel", new UserModel());
        DependencyInjectorService.instance.injector.map("_serverErrorModel", new ServerErrorModel());
        DependencyInjectorService.instance.injector.map("_currentQueueModel", new CurrentQueueModel());
        DependencyInjectorService.instance.injector.map("_modalPopupModel", new ModalPopupModel());
        DependencyInjectorService.instance.injector.map("_prizeCollectionsModel", new PrizeCollectionsModel());
        DependencyInjectorService.instance.injector.map("_creditsBundlesModel", new CreditsBundlesModel());
        DependencyInjectorService.instance.injector.map("_minigamesModel", new MinigamesModel());
        
        DependencyInjectorService.instance.injector.map("_dressUpModel", new DressUpModel());
    }

    protected setupFactories() {
        DependencyInjectorService.instance.injector.map("_actionFactory", new ActionFactory());
    }

    protected setupManagers() {
        DependencyInjectorService.instance.injector.map("_actionsManager", new ActionsManager());
    }

    protected setupControllers() {
    }

    public destroy() {
    }    

}
