import { IInjectable } from "../../ilmare/di/IInjectable";
import { Action } from "../actions/Action";
import { ActionFactory } from "../factory/ActionFactory";

export class ActionsManager implements IInjectable {
    protected _actionFactory: ActionFactory = undefined; // injects
    protected _actionsQueue: Action[];
    protected _currentAction: Action;

    constructor() {
        this._actionsQueue = [];
    }

    setup(): void {
        
    }

    public async executeActionInstant(actionClass: new (...params) => Action, ...params) {
        let paramsObj = params.length > 0 ? params[0] : {};
        let action = this._actionFactory.createAction(actionClass, paramsObj);
        return action.execute();
    }

    public queueActions(actionClasses: (new () => Action)[]) {
        for (let actionClass of actionClasses) {
            this.queueAction(actionClass);
        }
    }

    public queueAction(actionClass: new (...params) => Action, ...params) {
        let paramsObj = params.length > 0 ? params[0] : {};
        let action = this._actionFactory.createAction(actionClass, paramsObj);
        this._actionsQueue.push(action);

        if (this._actionsQueue.length === 1 && !this._currentAction) {
            this.executeNextAction();
        }        
    }

    protected executeNextAction() {
        if (this._actionsQueue.length > 0 && !this._currentAction) {
            this._currentAction = this._actionsQueue.shift();
            console.log("&%&%&% Executing action: ", this._currentAction);
            this._currentAction.execute().then((result: boolean) => {
                this._currentAction = null;
                this.executeNextAction();
            });    
        }
    }

    destroy(): void {
        
    }


}