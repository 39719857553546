//import 'pixi-spine';

import { Assets } from 'pixi.js';
import { BaseGameScene } from '../../../../ilmare/scenes/BaseGameScene';
import BaseApplication from '../../../../ilmare/BaseApplication';
import Path from '../../../../utils/Path';
import AssetPaths from '../../../../config/AssetPaths';
import { DressUpMainScene } from './DressUpMainScene';


export class DressUpLoadResourcesScene extends BaseGameScene 
{
    constructor(owner: BaseApplication) {
        super(owner, "DressUpLoadResourcesScene");        
    }

    public setup() {


        Assets.addBundle('dressup', {
            dolls: Path.join(AssetPaths.miniGames.dressUp.animations, 'doll.json'),            
        });


        Assets.loadBundle('dressup').then((resources) => {            
            this._owner.createScene(DressUpMainScene, resources);
        });
        
    }

    public preload(): void {        
      

    } 


    public destroy() {
        
    }

}
