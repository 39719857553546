import React, { FunctionComponent } from "react"
import { CurrentMachineModel } from "../../../game/models/CurrentMachineModel";
import { MachinesModel } from "../../../game/models/MachinesModel";
import { InfoCapsule, InfoCapsuleEyeIcon, InfoCapsuleText } from "./styles";
import { useObservableModel } from "../../../ilmare/models/ObservableModelState";

export const MachineInfoViewers: FunctionComponent = ()=> {
    
    const currentMachineModel = useObservableModel<CurrentMachineModel>('_currentMachineModel');
    const machinesModel = useObservableModel<MachinesModel>('_machinesModel');    

    return (
        <InfoCapsule>
            <InfoCapsuleEyeIcon />
            <InfoCapsuleText>{machinesModel.getMachineById(currentMachineModel.currentMachineId).viewersLength}</InfoCapsuleText>
        </InfoCapsule>
    )
    
}