import React, { Component } from "react";
import { LogoImage, LogoLink } from "./styles";


export default class Logo extends Component <any, any> {
    
    render() {        
        return (
            <LogoLink href="/">
                <LogoImage src="/assets/logo/logo.svg"></LogoImage>
            </LogoLink>
        );
    }
}