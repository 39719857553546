import { GameModel } from "../../ilmare/models/GameModel";

export class ConfigModel extends GameModel {
    
    protected _data: {
        TIME_TO_PLAY: number,
        TIME_TO_CHECK_PRIZE: number,
    };

    public populate(data) {
        this._data = {
            TIME_TO_PLAY: data.TIME_TO_CHECK_PRIZE,
            TIME_TO_CHECK_PRIZE: data.TIME_TO_PLAY,
        };
        this.notifyChange();
    }

    public get data() {        
        return this._data;
    }

    public setup() {
        
    }

    public destroy() {
        
    }

}