import { Skin, Spine } from "../../../../ilmare/spine/SpineFacade";
import { GameActor } from "../../../../ilmare/actors/GameActor";
import { EventListenerService } from "../../../services/EventListenerService";
import { DressUpEvents } from "../events/DressUpEvents";
import { Assets, Sprite } from "pixi.js";
import { DollModel } from "../models/DollModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { PrizeModel } from "../../../models/PrizeModel";
import { PrizeCollectionModel } from "../../../models/PrizeCollectionModel";
import { ActionsManager } from "../../../managers/ActionsManager";
import { DressUpModel } from "../models/DressUpModel";
import { MiniGameActor } from "../../../../ilmare/actors/MiniGameActor";


export class DollActor extends MiniGameActor {
    protected _resources: any;
    protected _doll: Spine;
    protected _eventListener: EventListenerService = undefined; // injects    
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects    
    protected _dressUpModel: DressUpModel = undefined; // injects

    constructor(resources: any) {
        super();
        this._resources = resources;
    }

    public setup() {
        this._positionOffset = { x: 50, y: 330 };
        this.loadDoll();

        this._eventListener.addListener(DressUpEvents.REFRESH_DOLL, this.onRefreshDoll, this);
    }

    protected loadDoll() {
        this._doll = new Spine(this._resources.dolls.spineData);
        this.addAnimation(this._doll);
        this._doll.state.setAnimation(0, "idle", true);
        this._doll.autoUpdate = true;        
        this._doll.scale.set(1.7, 1.7)
    }

    protected onRefreshDoll(evt: { dollId: number }) {
        let combinedSkin = new Skin("combinedSkin");
        
        let doll = this._dressUpModel.dolls[evt.dollId];
        for (let collectionId in doll) {
            //let collection: PrizeCollectionModel = this._prizeCollectionsModel.getCollectionById(collectionId);
            let prize: PrizeModel = this._prizeCollectionsModel.getPrizeById(doll[collectionId]);

            /* deprecated: changing the image manually
            Assets.load(prize.imageSlot).then((resource) => {
                this._doll.hackTextureBySlotName(collection.slotName, resource, resource.orig);
            });*/        

            combinedSkin.addSkin(this._doll.skeleton.data.findSkin(prize.skin));
        }

        this._doll.skeleton.setSkin(combinedSkin);
        this._doll.skeleton.setBonesToSetupPose();
    }

    public update(delta: number) {
        
    }

}