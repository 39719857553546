import { GameEvent } from "../../events/MainSceneEvent";
import { ModalPopupModel } from "../../models/ModalPopupModel";
import { ServerErrorModel } from "../../models/ServerErrorModel";
import { Action } from "../Action";

export class ServerErrorAction extends Action {    
    protected _serverErrorModel: ServerErrorModel = undefined; // injects    
    protected _modalPopupModel: ModalPopupModel = undefined; // injects
    protected _data: any;

    public constructor(data: any) {
        super();
        this._data = data;
    }

    public setup(): void {
        console.log("Server Error Action");
    }

    protected payload(): void {

        console.log(this._data);

        // if error can be handled by an advanced popup:
        if (this._modalPopupModel.canHandle(this._data)) {
            this._modalPopupModel.init(this._data);
        } else {
            // else, triggers the generic error popup
            this._serverErrorModel.init(this._data);
        }

        this._eventListener.emit(GameEvent.SERVER_ERROR_RECEIVED);

        this.finish(true);
    }

    public destroy(): void {
        
    }

}