import { ActionsManager } from "../../../managers/ActionsManager";
import { MachineDescriptor, MachinesModel } from "../../../models/MachinesModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { ServerAction } from "../../ServerAction";

export class BOUpdateMachineStateAction extends ServerAction {    
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects
    
    constructor(id: string, newState: string) {        
        super("bo-update-machine-state", "bo-update-machine-state-completed", { id: id, newState: newState });
    }
    
    public setup(): void {
        console.log("BO Delete Machine Action");
    }

    protected processResponse(evtData: { result: string, machineId: string, state: string }) {
        if (evtData.result === "machine-state-updated") {
            this._machinesModel.getMachineById(evtData.machineId).state = evtData.state;
        }        
    }

    public destroy(): void {
        
    }

}