import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDbsV37L97u7u7PuQfezukoh1RpsfQfze0",
    authDomain: "instant-gifts.firebaseapp.com",
    projectId: "instant-gifts",
    storageBucket: "instant-gifts.appspot.com",
    messagingSenderId: "736864209966",
    appId: "1:736864209966:web:2acbd238158a1abe6923bf",
    measurementId: "G-YLNJ44C42P"
};

// Inicializa Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default { 
    analytics : getAnalytics(firebaseApp), 
    auth : getAuth(firebaseApp) 
};


