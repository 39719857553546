import React, { FunctionComponent, useState, useEffect } from 'react';
import { LockedPrizeImage, PrizeContainer, PrizeImage, PrizeImageContainer, PrizeTitle, PrizeWonDate } from './styles';

interface PrizeViewProps {
    prize: any;
    isUnlocked?: boolean;
    onClick?: () => void;
    showDate?: boolean;
}

export const PrizeView = (props: PrizeViewProps) => {
    const { prize, isUnlocked = true, onClick = () => {}, showDate = false } = props;

    return (
        <PrizeContainer key={prize.id} onClick={onClick}>            
            <PrizeTitle>{prize.title}</PrizeTitle>
            {showDate && <PrizeWonDate>Won on: {prize.date.toLocaleDateString()}</PrizeWonDate>}            
            <PrizeImageContainer>
                {isUnlocked ? (
                    <PrizeImage className={prize.cssClass} />
                ) : (
                    <LockedPrizeImage className={prize.cssClass} />
                )}
            </PrizeImageContainer>
            
        </PrizeContainer>
    )
};