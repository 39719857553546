import { Form } from 'formik';
import styled from 'styled-components';

export const BOStyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
`;

export const BOLabel = styled.label`
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
`;

export const BOInputField = styled.input`
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`;

export const BOError = styled.div`
    color: red;
    font-size: 14px;
    margin-top: -15px;
    margin-bottom: 20px;
`;

export const BOFormButton = styled.button`
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

export const BORemoveButton = styled.button`
    background-color: #FF2525;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

export const BOAddButton = styled.button`
    background-color: #25FF25;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

export const BOSelect = styled.select`
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.2rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: #333;
    &:focus {
        outline: none;
        border-color: #007bff;
    }
`;

export const BOPrizeDetailsCard = styled.div`
    padding: 0.5rem;
    margin-top: 1rem;
    background-color: #f5f5f5;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
`;

export const BOPrizeDetails = styled.div`
    padding: 0.5rem;
    margin-top: 1rem;
    background-color: #f5f5f5;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    p {
        margin: 0.2rem 0;
        font-size: 0.9rem;
    }
`;

export const BOCountLabel = styled.label`
    display: block;
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1rem;
    color: #333;
`;


export const BOContainer = styled.div`
    margin: 20px;
`;

export const BOTitle = styled.h1`
    display: flex;
    justify-content: space-between;
`;

export const BOMachineRow = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    > div {
        flex: 1;
        text-align: center;
    }
`;

export const BOCollectionRow = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    > div {
        flex: 1;
        text-align: center;
    }
`;

export const BOPrizeGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px; // Espacio entre los elementos
`

export const BOPrizeItem = styled.div`
    flex: 1 1 calc(33.333% - 16px); // Toma un tercio del espacio disponible, menos el espacio entre elementos
    max-width: calc(33.333% - 16px); // Ancho máximo
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const BOFixedSizeImage = styled.img`    
    height: 100px; // Alto fijo
    width: auto;
    object-fit: cover;
`;

export const BOFixedSizeImageFromAtlas = styled.div`    
    height: auto; // Alto fijo
    width: 100%;
    object-fit: cover;
`;

export const BONavigationBarContainer = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
`

export const BONavigationBarInnerContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 2.75rem);
    justify-items: center;
    padding: 0.5rem 0px;
`

export const BONavigationBarItemLink = styled.a`
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    transition: all 0.2s ease 0s;
    border-radius: 10rem;

    text-decoration: none;
    color: rgb(89, 26, 101);
    cursor: pointer;    

    background-color: transparent;
`

export const BONavigationBarItemImage = styled.img`
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    border-style: none;
    filter: ${props => 
        props.color === "red" ? "invert(50%) sepia(100%) saturate(600%) hue-rotate(-10deg)" : props.color === "green" ? "invert(50%) sepia(100%) saturate(600%) hue-rotate(90deg)" : props.color === "blue" ? "invert(50%) sepia(100%) saturate(600%) hue-rotate(-90deg)" : "none"
    };
  
`


export const BOHamburgerMenuContainer = styled.div`
    position: relative;
`;

export const BOHamburgerIcon = styled.img`
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
`;

export const BOMenu = styled.div<{ open: boolean }>`
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    display: ${props => (props.open ? 'block' : 'none')};
    z-index: 100;
`;

export const BOMenuItem = styled.div`
    padding: 10px;
    cursor: pointer;
    &:hover {
        background-color: #f2f2f2;
    }
`;