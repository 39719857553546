import { PRNG } from "seedrandom";
import { IAnimationStateListener, IEvent, ITrackEntry, Spine } from '../../../ilmare/spine/SpineFacade';
import { BallActor, IManualAnimationDef } from "./BallActor";


export class BallAsPrizeActor extends BallActor implements IAnimationStateListener {

    protected _rng: PRNG;
    protected _rotate3DTrack: IManualAnimationDef;
    protected _rotateTrack: IManualAnimationDef;

    constructor(resources: any, colorAnimation: string, container: any) {
        super(resources, colorAnimation, container);
    }

    public setup() {
        this.loadBall();

    }

    public init() {
        this._rotateTrack = this.initializeSpineAnimationAtPercent(this._ball, "rotations/simple", 0.45 + Math.random() * 0.1, 2);
        this._rotate3DTrack = this.initializeSpineAnimationAtPercent(this._ball, "rotations/3d", 0, 3);

        if (Math.random() > 0.5) {
            this._invertedColor = true;
        }
        this.updateColorAnimation();
    }

    public rotate3D(percent) {
        this._rotate3DTrack.trackEntry.trackTime = this._rotate3DTrack.totalDuration * percent;
        this._ball.update(0);
    }

}
