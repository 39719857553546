import { CreditsModel } from "../../models/CreditsModel";
import { ServerAction } from "../ServerAction";

export class UpdateCreditsAction extends ServerAction {
    protected _creditsModel: CreditsModel = undefined; // injects   
    
    constructor() {        
        super("get-credits", "get-credits-completed");
    }

    
    public setup(): void {
        console.log("Update Credits Action");
    }

    protected processResponse(evtData: any) {
        this._creditsModel.creditsAmount = evtData.credits;
    }

    public destroy(): void {
     
    }

}