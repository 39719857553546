import styled from "styled-components"

export const HeaderContainer = styled.div`
    background-color: rgb(89, 26, 101);
    display: block;
`

export const HeaderInnerContainer = styled.div`
    max-width: 72rem;
    margin: 0px auto;
    padding: 0px 1rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
`

export const LogoLink = styled.a`
    margin-right: auto;
`

export const LogoImage = styled.img`
    display: initial;
    width: 11rem;
    height: 2.75rem;
    padding: 0.75rem 0px 0.5rem;
`

export const TopBarItemContainer = styled.div`
    display: flex;
`

export const CreditsContainer = styled.div`
    margin: 0.25rem;
    grid-template-columns: 0.9375rem 3.0625rem 1.5625rem;

    position: relative;
    display: grid;
    grid-template: "icon points button" 2rem / 1.25rem 4.375rem 1.875rem;
    margin: 1rem;
    color: rgb(255, 255, 255);
    background: 0% 0% no-repeat padding-box padding-box rgb(51, 28, 56);
    border-radius: 0.625rem;
`

export const CreditsIcon = styled.img`
    grid-area: icon / icon / icon / icon;
    place-self: center;
    height: 80%;
    width: 100%;
    margin-left: 0.5rem;    
    font-size: 1.25rem;
`

export const CreditsAmountContainer = styled.div`
    font-size: 0.875rem;
`

export const CreditsAmount = styled.p`
    text-align: center;
    font-size: 1.25rem;
    font-family: Heebo, Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin-top: 0.3em;
    animation: 0s ease 0s 1 normal none running none;
`

export const AddCreditsButton = styled.button`
    grid-area: button / button / button / button;
    text-align: center;
    padding-top: 0.0rem;
    width: 100%;
    height: 100%;
    font-size: 1.875rem;
    color: rgb(255, 255, 255);
    background-color: rgb(102, 187, 102);
    border-bottom: 0.1875rem solid rgb(65, 117, 65);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0.625rem;
    cursor: pointer;
`

