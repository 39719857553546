import BaseApplication from "../../../../ilmare/BaseApplication";
import { BaseGameScene } from "../../../../ilmare/scenes/BaseGameScene";
import { EventListenerService } from "../../../services/EventListenerService";
import { DollActor } from "../actors/DollActor";
import { DressUpEvents } from "../events/DressUpEvents";
import { DressUpModel } from "../models/DressUpModel";

export class DressUpMainScene extends BaseGameScene {    
    protected _dressUpModel: DressUpModel = undefined; // injects
    protected _eventListener: EventListenerService = undefined; // injects   
    private _resources: any;

    constructor(owner: BaseApplication, resources: any) {
        super(owner, "DressUpMainScene");
        this._resources = resources;
    }

    public setup() {
        console.log("### DressUpMainScene setup")

        this.addActor(new DollActor(this._resources));
        
        for (let i = 0; i < this._dressUpModel.dolls.length; i++) {
            this._eventListener.emit(DressUpEvents.REFRESH_DOLL, { dollId: i });
        }
    }

    public destroy() {
        // Implement your destroy logic here
    }

}