import { UserModel } from "../../../game/models/UserModel";
import { ActionsManager } from "../../managers/ActionsManager";
import { ServerAction } from "../ServerAction";

export class GetUserDetailsAction extends ServerAction {
    protected _actionsManager: ActionsManager = undefined; // injects
    protected _userModel: UserModel = undefined; // injects

    constructor() {
        super('get-user-details', 'get-user-details-completed');
    }

    public setup(): void {
        
    }
    
    protected processResponse(evtData: any) {
        console.log('get-user-details-completed');
        this._userModel.update(evtData);
    }

    public destroy(): void {
        
    }

}