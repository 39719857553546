import React, { useState, useEffect } from 'react';
import { useOrientation } from '../orientation/OrientationContext';
import { AreasContainerLandscape, AreasContainerPortrait, ButtonsArea, CurrenciesArea, InfoArea, PlayArea, VerticalContainer } from './styles';
import { Loading } from '../loading/Loading';

interface GameLayoutProps {
    playArea: React.ReactNode;
    infoArea?: React.ReactNode;
    currenciesArea?: React.ReactNode;
    buttonArea?: React.ReactNode;    
}

export const GameLayout: React.FunctionComponent<GameLayoutProps> = (props: GameLayoutProps) => {
    const orientation = useOrientation();
    const { playArea, infoArea, currenciesArea, buttonArea } = props;    
    let content: React.ReactNode = <Loading/>;

    if (orientation === 'landscape') {
        content = (
            <AreasContainerLandscape>
                <PlayArea>{playArea}</PlayArea>
                <VerticalContainer>
                    { infoArea ? <InfoArea>{infoArea}</InfoArea> : <></> }
                    { currenciesArea ? <CurrenciesArea>{currenciesArea}</CurrenciesArea> : <></> }
                    { buttonArea ? <ButtonsArea>{buttonArea}</ButtonsArea> : <></> }
                </VerticalContainer>
            </AreasContainerLandscape>
        );
    } else {
        content = (
            <AreasContainerPortrait>
                { infoArea ? <InfoArea>{infoArea}</InfoArea> : <></> }
                <PlayArea>{playArea}</PlayArea>
                { currenciesArea ? <CurrenciesArea>{currenciesArea}</CurrenciesArea> : <></> }
                { buttonArea ? <ButtonsArea>{buttonArea}</ButtonsArea> : <></> }
            </AreasContainerPortrait>
        );
    }        
    return (
        <main>{content}</main>
    );    
}