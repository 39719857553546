import { ActionsManager } from "../../../managers/ActionsManager";
import { CurrentMachineModel } from "../../../models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../../models/MachinesModel";
import { PrizeCollectionModel } from "../../../models/PrizeCollectionModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { PrizeModel } from "../../../models/PrizeModel";
import { ServerAction } from "../../ServerAction";

export class BOEditPrizeAction extends ServerAction {        
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects    
    protected _actionsManager: ActionsManager = undefined; // injects    
    
    constructor(data: any) {        
        super("bo-edit-prize", "bo-edit-prize-completed", { data: data });
    }
    
    public setup(): void {
        console.log("BO Create Prize Action");
    }

    protected processResponse(evtData: { result: string, collection: any, prize: any }) {
        if (evtData.result === "prize-edited") {

            throw new Error("not implemented")
            /*let collection = this._prizeCollectionsModel.getCollectionById(evtData.prize._id)
            let prize = new PrizeModel();
            prize.populateGenericPrize(evtData.collection, evtData.prize);
            collection.addPrize(prize);*/
        }        
    }

    public destroy(): void {
        
    }

}