import React, { FunctionComponent } from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import { BOError, BOInputField, BOLabel } from '../styles';


interface OptionsFormFieldProps {
    label: string;
    name: string;
    options: Array<{ id: string, title: string }>;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const OptionsFormField: FunctionComponent<OptionsFormFieldProps> = ({ label, name, options, onChange }) => {
    const [field, meta, helpers] = useField(name); 
    
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        helpers.setValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <>
            <BOLabel htmlFor={name}>{label}</BOLabel>
            <Field as='select' name={name} {...field} onChange={handleChange}>
                {options.map(option => (
                    <option key={option.id} value={option.id}>
                        {option.title}
                    </option>
                ))}
            </Field>
            <BOError>
                <ErrorMessage name={name} />
            </BOError>
        </>
    );
};

export default OptionsFormField;