//import 'pixi-spine';

import BaseApplication from "../../ilmare/BaseApplication";
import { BaseGameScene } from "../../ilmare/scenes/BaseGameScene";
import { CurrentMachineModel } from "../models/CurrentMachineModel";
import { MainScene } from "./MainScene";
import AssetPaths from '../../config/AssetPaths';
import Path from '../../utils/Path';
import { Assets } from 'pixi.js';

export class LoadCommonResourcesScene extends BaseGameScene 
{
    protected _animationId: string;
    protected _minigameCodename: string;

    constructor(owner: BaseApplication, animationId: string, minigameCodename: string) {       
        super(owner, "LoadCommonResourcesScene");        
        this._animationId = animationId;
        this._minigameCodename = minigameCodename;
    }

    public setup() {
        this._animationId = "machine_1" // TODO: remove this line

        Assets.addBundle('machine', {
            machineFront: Path.join(AssetPaths.machines.animations, this._animationId + '_front.json'),
            machineBack: Path.join(AssetPaths.machines.animations, this._animationId + '_back.json'),
            prize: Path.join(AssetPaths.machines.animations,'prize.json'),
            background: Path.join(AssetPaths.machines.animations,'background.json'),

            ball: Path.join(AssetPaths.ball.animations,'ball_1.json'),
            prizeCollection: Path.join(AssetPaths.collections.basePath, this._minigameCodename, this._minigameCodename + '.json'),
        });


        Assets.loadBundle('machine').then((resources) => {            
            this._owner.createScene(MainScene, resources);
        });
        
    }

    public preload(): void {        

        
        /*//this.load.image('logo', 'assets/phaser3-logo.png');

		this.load.setPath('../../../assets/spine/');	
        // TODO: solo cargar la animacion que se va a mostrar	
		this.load.spine('machine_1', 'machine_1.json', 'machines.atlas');
		this.load.spine('machine_2', 'machine_2.json', 'machines.atlas');
		this.load.spine('machine_3', 'machine_3.json', 'machines.atlas');
		this.load.spine('machine_4', 'machine_4.json', 'machines.atlas');

		this.load.spine('button', 'button.json', 'button.atlas');*/
    } 


    public destroy() {
        
    }

}
