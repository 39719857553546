import React, { useState, useEffect, FunctionComponent } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { UserModel } from '../../../game/models/UserModel';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { useNavigate, useParams } from 'react-router-dom';
import { BOAddButton, BOCollectionRow, BOContainer, BOError, BOFixedSizeImage, BOFormButton, BOMachineRow, BORemoveButton, BOStyledForm, BOTitle } from '../styles';
import { PrizeCollectionsModel } from '../../../game/models/PrizeCollectionsModel';
import { GetAllPrizeCollectionsAction } from '../../../game/actions/user/inventory/GetAllPrizeCollectionsAction';
import { ModalPopupModel } from '../../../game/models/ModalPopupModel';
import { useActionOnMount } from '../../../game/reactHooks/actionHooks';
import Path from '../../../utils/Path';
import { BODeleteCollectionAction } from '../../../game/actions/backOffice/collections/BODeleteCollectionAction';
import { usePrizeCollectionsCss } from '../../../game/reactHooks/cssHooks';
import { Loading } from '../../loading/Loading';

interface PrizeCollectionsEditorListProps {
    
}


export const PrizeCollectionsEditorList: FunctionComponent<PrizeCollectionsEditorListProps> = (props) => {
    const prizeCollections = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');    
    const userModel = useObservableModel<UserModel>('_userModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const modalPopupModel = useObservableModel<ModalPopupModel>('_modalPopupModel');
    const navigate = useNavigate();

    useActionOnMount(GetAllPrizeCollectionsAction);
    const [loaded, setLoaded] = useState<boolean>(false);

    usePrizeCollectionsCss("all", setLoaded);


    if (!userModel.isAdmin) {
        return <></>;
    }

    if (!loaded) {
        return <Loading />;
    }

    return (
        <BOContainer>
            <BOTitle>
                Prize Collections
                <BOAddButton onClick={() => navigate('/bo/collection/create')}>Crear</BOAddButton>
            </BOTitle>
            {prizeCollections.allCollections.map((collection) => (
                <BOCollectionRow key={collection.id}>
                    <div><BOFixedSizeImage src={Path.join(collection.path, collection.image)} alt={collection.title} /></div>
                    <div>
                        <strong>{collection.title}</strong>
                        <p>{collection.description}</p>
                    </div>
                    <div>
                        <BOFormButton onClick={() => navigate(`/bo/collection/edit/${collection.id}`)}>Edit</BOFormButton>
                        <BORemoveButton onClick={async () => {
                            await actionsManager.executeActionInstant(BODeleteCollectionAction, collection.id);
                            //console.log(`Eliminar ${collection.id}`)
                        }}>Delete</BORemoveButton>
                    </div>
                </BOCollectionRow>
            ))}
        </BOContainer>
    )
}