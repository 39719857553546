import styled from "styled-components";

export const ModalRootContainer = styled.div`
    position: fixed;
    inset: 0px;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.2s ease 0s;
    overflow: auto;
`

export const ModalContent = styled.div`
    position: fixed;
    width: calc(100vw - 2rem);
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
    background-color: white;
    transition: all 0.2s ease 0s;
`

export const ModalDialog = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding: 1.5rem;
    max-height: 95vh;
    overflow: auto;
`

export const ModalDialogIcon = styled.img`
    width: 12rem;
    height: 12rem;
    object-fit: contain;
    border-radius: 0.5rem;
    border-style: none;
`

export const ModalDialogTitle = styled.h3`
    font-size: 1.25rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: rgb(57, 57, 57);
    margin-bottom: 1rem;
    font-family: Heebo, Helvetica, Arial, sans-serif;
`

export const ModalDialogDescription = styled.div`
    font-size: 16px;
    padding: 1rem 0px;
    text-align: center;
    margin-bottom: 1rem;
    min-height: 0px;
`


export const ModalDialogGenericButton = styled.div`
    color: white;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: auto;
    padding: 1.0625rem 1rem 1rem;
    width: 100%;
    text-transform: uppercase;
    font-family: Heebo, Helvetica, Arial, sans-serif;
    border: 0px;
    box-shadow: none;
    transition: background 0.3s ease 0s;
    cursor: pointer;
    border-radius: 0.25rem;

    font: inherit;

    appearance: button;

    overflow: visible;

    margin: 0px;
`

export const ModalDialogCloseButton = styled.a`
    border-radius: 50%;
    color: var(--ds-icon,#44546f);
    height: 32px;
    margin: 4px;
    overflow: hidden;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 85ms,color 85ms;
    width: 32px;
    z-index: 2;
`

export const ModalDialogCallToActionButton = styled(ModalDialogGenericButton)`
    background-color: rgb(102, 187, 10);
    margin-bottom: 1rem;
`
