import { ActionsManager } from "../../../managers/ActionsManager";
import { CurrentMachineModel } from "../../../models/CurrentMachineModel";
import { MachineDescriptor, MachinesModel } from "../../../models/MachinesModel";
import { PrizeCollectionModel } from "../../../models/PrizeCollectionModel";
import { PrizeCollectionsModel } from "../../../models/PrizeCollectionsModel";
import { PrizeModel } from "../../../models/PrizeModel";
import { ServerAction } from "../../ServerAction";

export class BOCreatePrizeAction extends ServerAction {        
    protected _prizeCollectionsModel: PrizeCollectionsModel = undefined; // injects    
    protected _actionsManager: ActionsManager = undefined; // injects    
    
    constructor(data: MachineDescriptor) {        
        super("bo-create-prize", "bo-create-prize-completed", { data: data });
    }
    
    public setup(): void {
        console.log("BO Create Prize Action");
    }

    protected processResponse(evtData: { result: string, prize: any }) {
        if (evtData.result === "prize-created") {

            let collection = this._prizeCollectionsModel.getCollectionById(evtData.prize.prizeCollection)
            let prize = new PrizeModel();
            prize.populateGenericPrize(collection, evtData.prize);
            collection.addPrize(prize);
        }        
    }

    public destroy(): void {
        
    }

}