import { useEffect, useState } from 'react';
import { useInjection } from '../../game/reactHooks/InjectHooks';
import { Action } from '../actions/Action';
import { ActionsManager } from '../managers/ActionsManager';
import { useObservableModel } from '../../ilmare/models/ObservableModelState';
import { UserModel } from '../models/UserModel';

export function useActionOnMount(actionClass: new (...params) => Action, ...params) {
    
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    useEffect(() => {
        let isMounted = true;

        const execute = async () => {            
            await actionsManager.executeActionInstant(actionClass, ...params);
        };

        if (isMounted) {
            execute();
        }
        
        return () => {
            isMounted = false;
        };
    }, [ ]);

}

export function useActionOnLoggedInMount(actionClass: new (...params) => Action, ...params) {
    
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const userModel = useObservableModel<UserModel>('_userModel');

    useEffect(() => {
        let isMounted = true;

        const execute = async () => {            
            await actionsManager.executeActionInstant(actionClass, ...params);
        };

        if (isMounted && userModel.isLoggedIn) {
            execute();
        }
        
        return () => {
            isMounted = false;
        };
    }, [ userModel.isLoggedIn ]);

}

