import { GameModel } from "../../../../ilmare/models/GameModel";
import { DollModel } from "./DollModel";

type DollDef = {
    [collectionId: string]: string;
};

export class DressUpModel extends GameModel {

    protected _dolls: DollDef[] = [];

    public setup() {
        // TODO: unhardcode this
        this._dolls = [
            {}
        ];        
    }

    public get dolls(): DollDef[] {        
        return this._dolls;
    }

    public set dolls(value: DollDef[]) {
        this._dolls = value;
    }

    public getDollElement(dollIndex: number, collectionId: string): string {
        return this._dolls[dollIndex][collectionId];
    }

    public setDollElement(dollIndex: number, collectionId: string, value: string): void {
        this._dolls[dollIndex][collectionId] = value;
    }    

    public destroy() {
        
    }

}

