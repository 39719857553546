import { GameModel } from "../../ilmare/models/GameModel";
import { InsufficientCreditsDialog } from "../../reactComponents/error/InsufficientCreditsDialog";
import { Action } from "../actions/Action";

interface ModalPopupButtonOption {
    type: string;
    title: string;
    action: new (...params) => Action
}

export class ModalPopupModel extends GameModel {
    public hasPopup: boolean = false;
    public content: any;
    public contentParams: any;
    
    // standard popup definitions from server error codes
    public static popupDefs = [
        {
            reason: "insufficient-credits",
            content: InsufficientCreditsDialog
        }
    ];

    protected findDefFor(reason) {
        for (let def of ModalPopupModel.popupDefs) {
            if (reason == def.reason) {
                return def;
            }
        }
        return null;
    }

    public canHandle(evtData: any) {
        return this.findDefFor(evtData.reason);
    }

    public init(evtData: any) {
        if (this.canHandle(evtData)) {
            this.hasPopup = true;

            let def = this.findDefFor(evtData.reason);
            this.content = def.content;
        
            this.notifyChange();
        }
    }

    public initWithContent(content: any, contentParams: any = null) {
        this.hasPopup = true;
        this.content = content;
        this.contentParams = contentParams;

        this.notifyChange();
    }
    
    public clear() {
        this.hasPopup = false;
        this.content = null;
        this.contentParams = null;

        this.notifyChange();
    }

    public setup() {
        
    }

    public destroy() {
        
    }

}