import { Action } from "../Action";

export class OnUserLoginAction extends Action {
    
    public constructor(data) {
        super();
    }

    public setup(): void {
        
    }

    protected payload() {
        window.location.reload(); // reload the page to reset the socket listeners
        this.finish(true);
    }

    public destroy(): void {
        
    }

}