import { IInjectable } from "../../ilmare/di/IInjectable";
import { EventListenerService } from "../services/EventListenerService";

export abstract class Action implements IInjectable {
    protected _eventListener: EventListenerService = undefined; // injects
    protected _resolve: (boolean) => void;

    public constructor() {
        
    }
    
    public execute(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this._resolve = resolve;
            this.payload();
        });
    }

    protected finish(result: boolean) {
        this._resolve(result);
    }

    protected abstract payload();
    public abstract setup(): void;    
    public abstract destroy(): void;

}