import React, { useState, useEffect, FunctionComponent } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { UserModel } from '../../../game/models/UserModel';
import { useNavigate } from 'react-router-dom';
import { useActionOnMount } from '../../../game/reactHooks/actionHooks';
import { GetAllPrizeCollectionsAction } from '../../../game/actions/user/inventory/GetAllPrizeCollectionsAction';
import { PrizeCollectionsModel } from '../../../game/models/PrizeCollectionsModel';
import { BOAddButton, BOCollectionRow, BOContainer, BOError, BOFixedSizeImage, BOFixedSizeImageFromAtlas, BOFormButton, BOMachineRow, BORemoveButton, BOSelect, BOStyledForm, BOTitle } from '../styles';
import Path from '../../../utils/Path';
import { PrizeModel } from '../../../game/models/PrizeModel';
import { usePrizeCollectionsCss } from '../../../game/reactHooks/cssHooks';
import { Loading } from '../../loading/Loading';

interface PrizesEditorListProps {

}


export const PrizesEditorList: FunctionComponent<PrizesEditorListProps> = (props) => {
    const prizeCollections = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');
    const userModel = useObservableModel<UserModel>('_userModel');
    const navigate = useNavigate();

    const [selectedCollection, setSelectedCollection] = useState<string | null>(null);
    const [loaded, setLoaded] = useState<boolean>(false);

    usePrizeCollectionsCss("all", setLoaded);


    useActionOnMount(GetAllPrizeCollectionsAction);

    if (!userModel.isAdmin) {
        return <></>;
    }

    if (!loaded) {
        return <Loading/>;
    }

    let prizes: PrizeModel[] = [];
    const collectionNames = prizeCollections.allCollections.map((collection) => collection.title);
    prizeCollections.allCollections.forEach((collection) => {
        prizes = prizes.concat(collection.prizes);
    });

    return (
        <BOContainer>
            <BOTitle>
                Prizes
                <BOAddButton onClick={() => navigate('/bo/prize/create')}>Crear</BOAddButton>
            </BOTitle>
            <BOSelect onChange={(e) => setSelectedCollection(e.target.value)}>
                <option value="">Todas las colecciones</option>
                {collectionNames.map((name, index) => (
                    <option key={index} value={name}>
                        {name}
                    </option>
                ))}
            </BOSelect>

            {prizes
                .filter((prize) => !selectedCollection || prizeCollections.getCollectionById(prize.collection).title === selectedCollection)
                .map((prize) => (

                <BOCollectionRow key={prize.id}>
                    <div><BOFixedSizeImageFromAtlas className={prize.cssClass} /></div>
                    <div>
                        <strong>{prize.title}</strong>
                        <p>{prize.description}</p>
                    </div>
                    <div>
                        <strong>{prizeCollections.getCollectionById(prize.collection).title}</strong>
                    </div>
                    <div>
                        <BOFormButton onClick={() => navigate(`/bo/prize/edit/${prize.id}`)}>Edit</BOFormButton>
                        <BORemoveButton onClick={() => {

                            console.log(`Eliminar ${prize.id}`)
                        }}>Delete</BORemoveButton>
                    </div>
                </BOCollectionRow>
            ))}
        </BOContainer>
    )

}