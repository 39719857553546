import { LoadMinigameStatusAction } from "../../../actions/minigames/LoadMinigameStatusAction";
import { EventListenerService } from "../../../services/EventListenerService";
import { DressUpEvents } from "../events/DressUpEvents";
import { DressUpModel } from "../models/DressUpModel";

export class LoadDressUpStatusAction extends LoadMinigameStatusAction {

    protected _dressUpModel: DressUpModel = undefined;
    protected _eventListener: EventListenerService = undefined;

    public setup() {

    }

    protected payload(): void {
        console.log('### LoadDressUpStatusAction.payload');
        super.payload();
    }

    protected processResponse(evtData) {
        console.log('### LoadDressUpStatusAction.processResponse', evtData);

        this._dressUpModel.unserialize(evtData.state);
        for (let i = 0; i < this._dressUpModel.dolls.length; i++) {
            this._eventListener.emit(DressUpEvents.REFRESH_DOLL, { dollId: i });
        }        
    }


    public destroy() {
    }
}