import React, { FunctionComponent } from 'react';
import { Header } from "./header/Header";
import { Routes, Route, Link, Outlet, useLocation } from "react-router-dom";
import { MachineView } from "./mainContent/machine/MachineView";
import { MachinesList } from "./mainContent/machinesList/MachinesList";
import { Login } from "./user/Login";
import { NotFound } from "./error/NotFound";
import { ActionsManager } from '../game/managers/ActionsManager';
import { useInjection } from '../game/reactHooks/InjectHooks';
import { OnUserLoginAction } from '../game/actions/user/OnUserLoginAction';
import GlobalStyle from '../styles/global';
import { Footer } from './footer/Footer';
import { ProfileView } from './user/profile/ProfileView';
import { LeaderboardsView } from './leaderboards/LeaderboardsView';
import { InventoryView } from './user/inventory/InventoryView';
import { PrizeCollectionView } from './user/inventory/PrizeCollectionView';
import { MachineEditor } from './backOffice/machineEditor/MachineEditor';
import { MachineCreator } from './backOffice/machineEditor/MachineCreator';
import { MachinesEditorList } from './backOffice/machineEditor/MachinesEditorList';
import { PrizeCollectionsEditorList } from './backOffice/collectionsEditor/PrizeCollectionsEditorList';
import { PrizeCollectionEditorEdit } from './backOffice/collectionsEditor/PrizeCollectionEditorEdit';
import { PrizeCollectionEditorCreate } from './backOffice/collectionsEditor/PrizeCollectionEditorCreate';
import { PrizesEditorList } from './backOffice/prizesEditor/PrizesEditorList';
import { PrizesEditorCreate } from './backOffice/prizesEditor/PrizesEditorCreate';
import { PrizesEditorEdit } from './backOffice/prizesEditor/PrizesEditorEdit';
import { DressUpGame } from '../game/miniGames/dressUp/DressUpGame';
import LoginFirebase from './user/LoginFirebase';
import ImportAnimation from './backOffice/importer/ImportAnimation';
import ConfigEditorEdit from './backOffice/configEditor/ConfigEditorEdit';

type RootProps = {
}

export const Root: FunctionComponent<RootProps> = ()=> {

    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const location = useLocation();
    
    const showHeader = !location.pathname.startsWith("/machine/view/") && !location.pathname.startsWith("/game/");
    const showFooter = !location.pathname.startsWith("/machine/view/") && !location.pathname.startsWith("/game/");

    return <>
            <GlobalStyle/>
            { showHeader && <Header/> }
            
            <Routes>
                <Route path="/" element={<MachinesList />} />
                <Route path="/login" element={<LoginFirebase />}/>                
                <Route path="/logout" element={<Login onLogin={ () => actionsManager.executeActionInstant(OnUserLoginAction) }/>} />
                <Route path="/machine/view/:id" element={<MachineView />} />
                <Route path="/user/profile/view" element={<ProfileView />} />
                <Route path="/leaderboards/view" element={<LeaderboardsView />} />
                <Route path="/user/inventory/view" element={<InventoryView/>} />
                <Route path="/user/inventory/collection/view/:id" element={<PrizeCollectionView/>} />
                
                <Route path="/bo/config" element={<ConfigEditorEdit/>} />
                <Route path="/bo/machines" element={<MachinesEditorList/>} />
                <Route path="/bo/machine/edit/:id" element={<MachineEditor/>} />
                <Route path="/bo/machine/create" element={<MachineCreator/>} />
                <Route path="/bo/collections" element={<PrizeCollectionsEditorList/>} />
                <Route path="/bo/collection/edit/:id" element={<PrizeCollectionEditorEdit/>} />
                <Route path="/bo/collection/create" element={<PrizeCollectionEditorCreate/>} />
                <Route path="/bo/prizes" element={<PrizesEditorList/>} />
                <Route path="/bo/prize/create" element={<PrizesEditorCreate/>} />
                <Route path="/bo/prize/edit/:id" element={<PrizesEditorEdit/>} />
                <Route path="/bo/import/animation" element={<ImportAnimation/>} />


                <Route path="/game/dressup" element={<DressUpGame/>}/>

                <Route path="*" element={<NotFound/>}/>             
            </Routes>            

            { showFooter && <Footer/> }
        </>

}