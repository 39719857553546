import { GameEvent } from "../../events/MainSceneEvent";
import { ModalPopupModel } from "../../models/ModalPopupModel";
import { Action } from "../Action";

export class DismissModalPopupAction extends Action {    
    protected _modalPopupModel: ModalPopupModel = undefined; // injects

    public setup(): void {
        console.log("Dismiss Modal Popup Action");
    }

    protected payload(): void {

        this._modalPopupModel.clear();

        this.finish(true);
    }

    public destroy(): void {
        
    }

}