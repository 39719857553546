import React, { FunctionComponent, useEffect, useState } from 'react';
import { FieldArray } from 'formik';
import { BORemoveButton, BOLabel, BOAddButton, BOPrizeDetailsCard } from '../styles';
import { InitialPrizeEditor } from './InitialPrizeEditor';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { PrizeCollectionsModel } from '../../../game/models/PrizeCollectionsModel';
import { PrizeModel } from '../../../game/models/PrizeModel';

type InitialPrizesFieldArrayProps = {
    values: any;
    setFieldValue: (field: string, value: any) => void;
};

const InitialPrizesFieldArray: FunctionComponent<InitialPrizesFieldArrayProps> = ({ values, setFieldValue }) => {
    
    const prizeCollectionsModel = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');
    const [allPrizes, setAllPrizes] = useState<PrizeModel[]>([]);

    useEffect(() => {
        const collection = prizeCollectionsModel.getCollectionById(values.prizesCollection);

        if (collection) {
            //const prizes = collection.prizes.map((prize) => prize.id);
            setAllPrizes(collection.prizes);
        }
    }, [ values.prizesCollection ]);
    
    return (<FieldArray name="initialPrizes">
        {({ insert, remove, push }) => (
            <BOPrizeDetailsCard>
                <BOLabel htmlFor='initialPrizes'>Premios iniciales</BOLabel> 

                {Object.keys(values.initialPrizes).map((prizeId, index) => (
                    <BOPrizeDetailsCard key={prizeId}>
                        <InitialPrizeEditor                            
                            prizeId={values.initialPrizes[prizeId].id}
                            count={values.initialPrizes[prizeId].count}
                            collectionId={values.prizesCollection}
                            allPrizes={allPrizes}
                            index={index}
                            setFieldValue={(field, value) => {
                                setFieldValue(`initialPrizes.${prizeId}.${field}`, value);
                            }}
                        />
                        <BORemoveButton type="button" onClick={() => remove(index)}>Eliminar</BORemoveButton>
                    </BOPrizeDetailsCard>
                ))}

                <div></div>
                <BOAddButton type="button" onClick={() => push({ 
                        id: '', 
                        count: 10 
                    })}>
                    Añadir Nuevo Premio
                </BOAddButton>
            </BOPrizeDetailsCard>
        )}
    </FieldArray>
)};

export default InitialPrizesFieldArray;