import React, { FunctionComponent } from 'react';
import { DismissServerErrorAction } from '../../game/actions/error/DismissServerErrorAction';
import { ActionsManager } from '../../game/managers/ActionsManager';
import { ServerErrorModel } from '../../game/models/ServerErrorModel';
import { useInjection } from '../../game/reactHooks/InjectHooks';
import { ModalContent, ModalDialog, ModalDialogCloseButton, ModalDialogDescription, ModalDialogIcon, ModalDialogTitle } from './styles';
import { useObservableModel } from '../../ilmare/models/ObservableModelState';


export interface ServerErrorProps {
    
}

export const ServerError: FunctionComponent<ServerErrorProps> = (data: ServerErrorProps) => {
    const serverErrorModel = useObservableModel<ServerErrorModel>('_serverErrorModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    const closeModal = () => {
        actionsManager.executeActionInstant(DismissServerErrorAction);
    };


    if (serverErrorModel.hasError) {
        
        return <div>
            <ModalContent>
                <ModalDialogCloseButton onClick={closeModal}>CLOSE</ModalDialogCloseButton>
                <ModalDialog>
                    <ModalDialogIcon></ModalDialogIcon>
                    <ModalDialogTitle>{serverErrorModel.title}</ModalDialogTitle>
                    <ModalDialogDescription>{serverErrorModel.message}</ModalDialogDescription>
                </ModalDialog>
            </ModalContent>
        </div>
    } else {
        return <div/>
    }

}