import React, { useState, useEffect, FunctionComponent } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { UserModel } from '../../../game/models/UserModel';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { MachineDescriptor, MachinesModel } from '../../../game/models/MachinesModel';
import { BOEditMachineAction } from '../../../game/actions/backOffice/machines/BOEditMachineAction';
import { Loading } from '../../loading/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import InputFormField from '../common/InputFormField';
import { BOAddButton, BOCollectionRow, BOContainer, BOError, BOFixedSizeImage, BOFixedSizeImageFromAtlas, BOFormButton, BOLabel, BOMachineRow, BOPrizeGrid, BOPrizeItem, BORemoveButton, BOStyledForm, BOTitle } from '../styles';
import { PrizeCollectionsModel } from '../../../game/models/PrizeCollectionsModel';
import { GetAllPrizeCollectionsAction } from '../../../game/actions/user/inventory/GetAllPrizeCollectionsAction';
import { PrizeCollectionModel } from '../../../game/models/PrizeCollectionModel';
import ManifestFS from '../../../manifest/ManifestFS';
import Path from '../../../utils/Path';
import { BOEditCollectionAction } from '../../../game/actions/backOffice/collections/BOEditCollectionAction';
import { ModalPopupModel } from '../../../game/models/ModalPopupModel';
import { InfoPopupPreset } from '../../modal/presets/InfoPopupPreset';
import { BOCreateCollectionAction } from '../../../game/actions/backOffice/collections/BOCreateCollectionAction';
import { useActionOnMount } from '../../../game/reactHooks/actionHooks';
import OptionsFormField from '../common/OptionsFormField';
import { BOCreatePrizeAction } from '../../../game/actions/backOffice/prizes/BOCreatePrizeAction';
import { PrizeModel } from '../../../game/models/PrizeModel';
import { BOEditPrizeAction } from '../../../game/actions/backOffice/prizes/BOEditPrizeAction';
import { usePrizeCollectionsCss } from '../../../game/reactHooks/cssHooks';

interface PrizesEditorEditProps {

}


export const PrizesEditorEdit: FunctionComponent<PrizesEditorEditProps> = (props) => {
    const prizeCollections = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');
    const userModel = useObservableModel<UserModel>('_userModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');
    const modalPopupModel = useObservableModel<ModalPopupModel>('_modalPopupModel');
    const [images, setImages] = useState<string[]>([]);
    const [imageFullPath, setImageFullPath] = useState<string>("");
    //const [imageLockedFullPath, setImageLockedFullPath] = useState<string>("");
    //const [imageSlotFullPath, setImageSlotFullPath] = useState<string>("");

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const { id } = useParams<"id">();
    const [loaded, setLoaded] = useState<boolean>(false);

    usePrizeCollectionsCss("all", setLoaded);

    useActionOnMount(GetAllPrizeCollectionsAction);

    const prize: PrizeModel | null = prizeCollections.getPrizeById(id)

    useEffect(() => {
        if (prize) {
            setImages(ManifestFS.getFiles(Path.join(prizeCollections.getCollectionById(prize.collection).path, "prizes"))
                .filter((file) => !file.includes('_locked'))
                .filter((file) => !file.includes('_slot'))
            );
            setImageFullPath(prize.cssClass);
            //setImageLockedFullPath(prize.imageLocked);
        }
    }, [prize]);

    if (!userModel.isAdmin) {
        return <></>;
    }

    if (!loaded) {
        return <Loading />;
    }
    
    if (!prize) {
        return <></>;
    }


    return (
        <div>
            <h1>Editar Premio</h1>
            {loading ? (
                <Loading />
            ) : error ? (
                <p>{error}</p>
            ) : (
                <Formik
                    initialValues={{
                        title: prize.title,
                        description: prize.description,
                        image: prize.cssClass,
                        imagePath: prize.rawData.imagePath,
                        imageExtension: prize.rawData.imageExtension,
                        collection: prize.collection
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values, { setSubmitting }) => {
                        debugger;
                        setLoading(true);
                        await actionsManager.executeActionInstant(BOEditPrizeAction, values);
                        modalPopupModel.initWithContent(InfoPopupPreset, { title: 'Premio Editado', message: 'El premio se ha editado correctamente' });
                        setLoading(false);
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <BOStyledForm>
                            <InputFormField label="Título" name="title" />
                            <InputFormField label="Descripción" name="description" />

                            <OptionsFormField label="Collection" name="collection"
                                options={prizeCollections.allCollections.map((collection) => { return { id: collection.id, title: collection.title } })}
                                onChange={(e) => {
                                    let collection = prizeCollections.getCollectionById(e.target.value);
                                    setImages(ManifestFS.getFiles(Path.join(collection.path, "prizes"))
                                        .filter((file) => !file.includes('_locked'))
                                        .filter((file) => !file.includes('_slot'))
                                    );
                                }}
                            />

                            <OptionsFormField label="Imagen" name="image" options={images.map(image => { return { id: image, title: image } })} onChange={(e) => {
                                setFieldValue('imagePath', Path.join('prizes', e.target.value.split('.')[0]));
                                setFieldValue('imageExtension', e.target.value.split('.')[1]);
                                setImageFullPath(Path.join(prizeCollections.getCollectionById(values.collection).path, "prizes", e.target.value));
                                //setImageLockedFullPath(Path.join(prizeCollections.getCollectionById(values.collection).path, "prizes", e.target.value.split('.')[0] + '_locked.' + e.target.value.split('.')[1]));
                                //setImageSlotFullPath(Path.join(prizeCollections.getCollectionById(values.collection).path, "prizes", e.target.value.split('.')[0] + '_slot.' + e.target.value.split('.')[1]));
                            }} />
                            <BOFixedSizeImageFromAtlas className={imageFullPath} />

                            <BOFormButton type="submit">Guardar</BOFormButton>
                        </BOStyledForm>
                    )}
                </Formik>
            )}
        </div>
    )
}