import React, { useState, useEffect, useRef } from 'react';
import { CategoryButton, CategoryList, DressUpButtonsContainer, Item, ItemImage, ItemList } from './styles';
import { DressUpBootScene } from './scenes/DressUpBootScene';
import { DressUpApplication } from './DressUpApplication';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { MinigamesModel } from '../../models/MinigamesModel';
import { PrizeCollectionsModel } from '../../models/PrizeCollectionsModel';
import { PrizeModel } from '../../models/PrizeModel';
import { useActionOnLoggedInMount, useActionOnMount } from '../../reactHooks/actionHooks';
import { GetAllPrizeCollectionsAction } from '../../actions/user/inventory/GetAllPrizeCollectionsAction';
import { GetMinigamesAction } from '../../actions/minigames/GetMinigamesAction';
import { UserModel } from '../../models/UserModel';
import { GetAllWonPrizesAction } from '../../actions/user/inventory/GetAllWonPrizesAction';
import { DressUpModel } from './models/DressUpModel';
import { useInjection } from '../../reactHooks/InjectHooks';
import { EventListenerService } from '../../services/EventListenerService';
import { DressUpEvents } from './events/DressUpEvents';
import { LoadDressUpStatusAction } from './actions/LoadDressUpStatusAction';
import { SaveDressUpStatusAction } from './actions/SaveDressUpStatusAction';
import { ActionsManager } from '../../managers/ActionsManager';
import { GameLayout } from '../../../reactComponents/layout/GameLayout';
import { Grid3ColumnContainer, InfoAreaContent, MachineAnimationContainer } from '../../../reactComponents/mainContent/machine/styles';
import SpineAnimationComponent from '../../../ilmare/animations/SpineAnimationComponent';
import { BackToHomeButton } from '../../../reactComponents/mainContent/machine/BackToHomeButton';
import { InfoButton } from '../../../reactComponents/mainContent/machine/InfoButton';
import { usePrizeCollectionsCss } from '../../reactHooks/cssHooks';
import ColorUtils from '../../../utils/ColorUtils';

// Tipos de datos
type Category = {
    id: string;
    name: string;
    items: PrizeModel[];
}

const minigameCodename = 'dressup';
const backgroundAlpha = 0.8;

export const DressUpGame: React.FunctionComponent = () => {
    const [loaded, setLoaded] = useState(false);
    const [cssLoaded, setCssLoaded] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const [selectedItems, setSelectedItems] = useState({});
    const [categories, setCategories] = useState<Category[]>([]);
    const categoriesRef = useRef(categories);
    const eventListener = useInjection<EventListenerService>('_eventListener');
    const minigamesModel = useObservableModel<MinigamesModel>('_minigamesModel');
    const prizesCollectionsModel = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');
    const userModel = useObservableModel<UserModel>('_userModel');
    const dressUpModel = useObservableModel<DressUpModel>('_dressUpModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    const minigame = minigamesModel.getMinigameByCodename(minigameCodename);
    const minigameId = minigame ? minigame.id : '';

    usePrizeCollectionsCss([minigameCodename], setCssLoaded);

    useActionOnMount(GetAllPrizeCollectionsAction);
    useActionOnMount(GetMinigamesAction);
    useActionOnLoggedInMount(GetAllWonPrizesAction);
    useActionOnLoggedInMount(LoadDressUpStatusAction, { minigameId: minigameId });

    const selectItem = (item: PrizeModel) => {
        //let doll = dressUpModel.dolls[0];
        let dollId = 0; // TODO: unhardcode this
        dressUpModel.setDollElement(0, item.collection, item.id);

        eventListener.emit(DressUpEvents.REFRESH_DOLL, { dollId: dollId, categories: minigame.prizeCollections });
        actionsManager.executeActionInstant(SaveDressUpStatusAction, { minigameId: minigameId, minigameState: dressUpModel.serialize() });
    }

    useEffect(() => {
        if (minigame && userModel.wonPrizes.length > 0) {
            let loadedCategories: Category[] = [];
            for (let collection of minigame.prizeCollections) {
                let prizeCollection = prizesCollectionsModel.getCollectionById(collection);
                if (!prizeCollection) {
                    return;
                }
                // filter prizes that are not in the player won prizes
                let prizes = prizeCollection.prizes.filter((prize) => {
                    return userModel.wonPrizes.find((wonPrize) => wonPrize.id == prize.id);
                });
                loadedCategories.push({ id: prizeCollection.id, name: prizeCollection.title, items: prizes });
            }
            setCategories(loadedCategories);
            setSelectedCategory(loadedCategories[1]);
        }
    }, [minigame, prizesCollectionsModel, userModel.wonPrizes]);

    useEffect(() => {
        categoriesRef.current = categories;
    }, [categories]);

    useEffect(() => {
        handleRefreshDoll();
    }, [categories]);

    const handleRefreshDoll = (evt?: any) => {
        // hay que usar el Ref porque categories no se actualiza
        if (categoriesRef.current.length > 0) {
            for (let category of categoriesRef.current) {
                console.log(dressUpModel.getDollElement(0, category.id))
                setSelectedItems(prevItems => ({
                    ...prevItems,
                    [category.id]: dressUpModel.getDollElement(0, category.id),
                }));
            }
            setLoaded(true);
        }
    };

    useEffect(() => {
        eventListener.addListener(DressUpEvents.REFRESH_DOLL, handleRefreshDoll);

        return () => {
            eventListener.removeListener(DressUpEvents.REFRESH_DOLL, handleRefreshDoll);
        };
    }, [eventListener]);

    if (!loaded || !cssLoaded) {
        return <GameLayout
            playArea={<MachineAnimationContainer />}
            infoArea={
                <Grid3ColumnContainer>
                    <BackToHomeButton />
                    <InfoAreaContent>
                        Loading...
                    </InfoAreaContent>
                    <InfoButton id={minigameId} />
                </Grid3ColumnContainer>
            }
        />;
    }
    return (<GameLayout
        playArea={
            <MachineAnimationContainer>
                <SpineAnimationComponent BootSceneClass={DressUpBootScene} PixiApplicationClass={DressUpApplication} />
            </MachineAnimationContainer>
        }
        infoArea={
            <Grid3ColumnContainer>
                <BackToHomeButton />
                <InfoAreaContent>
                    Dress up the doll
                </InfoAreaContent>
                <InfoButton id={minigameId} />
            </Grid3ColumnContainer>
        }
        buttonArea={
            <DressUpButtonsContainer>
                {selectedCategory && (
                    <ItemList backgroundColor={ColorUtils.generatePastelColor(selectedCategory.name, 0.8, backgroundAlpha)}>
                        {selectedCategory.items.map(item => (
                            <Item
                                key={item.id}
                                onClick={() => selectItem(item)}
                                isSelected={selectedItems[selectedCategory.id] === item.id}
                            >
                                <ItemImage className={item.cssClass} />
                            </Item>
                        ))}
                    </ItemList>
                )}

                <CategoryList>
                    {categories.map(category => (
                        <CategoryButton 
                            key={category.name} 
                            onClick={() => setSelectedCategory(category)} 
                            isSelected={ selectedCategory.name == category.name } 
                            backgroundColor={ColorUtils.generatePastelColor(category.name, 0.7, backgroundAlpha)}
                            selectedBackgroundColor={ColorUtils.generatePastelColor(category.name, 0.8, backgroundAlpha)}
                        >
                            {category.name}
                        </CategoryButton>
                    ))}
                </CategoryList>
            </DressUpButtonsContainer>
        }
    />);

}
