import React from 'react';

import firebaseConfig from '../../firebase/firebaseConfig';
import firebaseui from 'firebaseui';
import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth'
import StyledFirebaseAuth from '../auth/firebase/StyledFirebaseAuth';

const uiConfig: firebaseui.auth.Config = {
    signInSuccessUrl: '/', // Ajusta según tu flujo
    signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        FacebookAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
    ],
    
};



const LoginFirebase: React.FC = () => {
    return (
        <div>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseConfig.auth} />
        </div>
    );
};

export default LoginFirebase;
