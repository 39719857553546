import { IInjectable } from "../../ilmare/di/IInjectable";
import { Ticker } from "../../utils/Ticker";
import { GameEvent } from "../events/MainSceneEvent";
import { CurrentQueueModel } from "../models/CurrentQueueModel";
import { EventListenerService } from "../services/EventListenerService";

export class TimersTicker extends Ticker implements IInjectable {

    protected _currentQueueModel: CurrentQueueModel = undefined; // injects
    protected _eventListener: EventListenerService = undefined; // injects
    protected _lastTickTime = undefined;

    setup(): void {
        
    }

    protected get timeInterval(): number {
        return 100;
    }

    public start() {
        this._lastTickTime = Date.now();
        super.start();
    }

    protected tick(): void {
        let delta = Date.now() - this._lastTickTime;
        if (this._currentQueueModel.timeLeft != undefined && this._currentQueueModel.timeLeft > 0) {
            if (this._currentQueueModel.timeLeft - delta / 1000 < 0) {
                this._currentQueueModel.reset(); // esto deberia ser un action
            } else {
                this._currentQueueModel.timeLeft -= delta / 1000;
            }
        }
        this._lastTickTime = Date.now();
    }

    destroy(): void {
        this.stop();
    }

}
