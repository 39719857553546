import firebaseConfig from "../../../firebase/firebaseConfig";
import { ServerEvent } from "../../events/ServerEvent";
import { Action } from "../Action";

export class OnFirebaseLoginAction extends Action {
    protected _initialized: boolean = false;
    protected _authFinished: boolean = false;

    public constructor(data) {
        super();
    }

    public setup(): void {
        console.log("### OnFirebaseLoginAction.setup");
        
        firebaseConfig.auth.onAuthStateChanged(this.onAuthStateChanged.bind(this));        

        this._initialized = true;
    }

    protected onAuthStateChanged(user) {
        console.log("### OnFirebaseLoginAction.onAuthStateChanged", user);
        if (user) {
            user.getIdToken().then((token) => {
                this._eventListener.emit(ServerEvent.SEND_MESSAGE, 'token-updated', { token: token });
            });
        } else {
            this._eventListener.emit(ServerEvent.SEND_MESSAGE, 'token-updated', { token: null });
        }
        
        if (this._initialized && !this._authFinished && this._resolve) {
            this.finish(true); // Si viene muy rapido, crashea porque no llega a inicializar el execute, cambiarlo por un flag y tambien chequear si esta clase esta inicializada porque si viene lento igual va a terminar aca
        }
        this._authFinished = true;
    }

    protected payload() {
        if (this._authFinished) {
            this.finish(true);
        }
    }

    public destroy(): void {

    }

}