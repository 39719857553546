import React, { FunctionComponent, useEffect, useState } from 'react';
import { UserModel } from '../../../game/models/UserModel';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { CollectionContainer, CollectionTitle, CollectionTitleContainer } from './styles';
import { PrizeView } from './PrizeView';
import { Loading } from '../../loading/Loading';
import { usePrizeCollectionsCss } from '../../../game/reactHooks/cssHooks';
import { useNavigate } from 'react-router-dom';

type InventoryViewProps = {

}

export const InventoryView: FunctionComponent<InventoryViewProps> = () => {
    const userModel = useObservableModel<UserModel>('_userModel');    
    
    const [loaded, setLoaded] = useState<boolean>(false);
    const navigate = useNavigate();

    usePrizeCollectionsCss("all", setLoaded);    

    if (!loaded) {
        return (
            <Loading/>
        );
    } else {
        return (
            <CollectionContainer>
                <CollectionTitleContainer>
                    <CollectionTitle>User Inventory</CollectionTitle>
                </CollectionTitleContainer>
                {userModel.wonPrizes.map((prize) => {
                    return <PrizeView 
                            prize={prize} 
                            key={prize.transactionId}
                            showDate={true}
                            onClick={() => {
                                navigate('/user/inventory/collection/view/' + prize.collection);
                            }}
                        />;
                })}                        
            </CollectionContainer>
        );
    }
}