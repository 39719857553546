import { GameEvent } from "../../events/MainSceneEvent";
import { ActionsManager } from "../../managers/ActionsManager";
import { CurrentMachineModel } from "../../models/CurrentMachineModel";
import { MachinesModel } from "../../models/MachinesModel";
import { UserModel } from "../../models/UserModel";
import { EventListenerService } from "../../services/EventListenerService";
import { Action } from "../Action";

export class PrizeAwardedToPlayerAction extends Action {    
    protected _machinesModel: MachinesModel = undefined; // injects
    protected _currentMachineModel: CurrentMachineModel = undefined; // injects
    protected _userModel: UserModel = undefined; // injects
    protected _eventListener: EventListenerService = undefined; // injects
    protected _actionsManager: ActionsManager = undefined; // injects
    protected _data: any;

    public constructor(data: any) {
        super();
        this._data = data;
    }

    public setup(): void {
        console.log("Update Machine Viewers Action");
    }

    protected payload(): void {
        
        if (this._currentMachineModel.currentMachineId == this._data.machineId) {
            this._currentMachineModel.updateFillLevel(this._data.newFillLevel);
            this._eventListener.emit(GameEvent.UPDATE_MACHINE_FILL_LEVEL, this._data.newFillLevel);
        }

        if (this._currentMachineModel.currentMachineId == this._data.machineId && this._data.playingUser != this._userModel.id) {
            this._eventListener.emit(GameEvent.START_OTHER_PRIZE_PRESENTATION, this._data.prizeId);
        }

        //this._actionsManager.executeActionInstant(GetAllWonPrizesAction); // TODO: esta llamada es pesada y deberia hacerse paginada cuando sea necesario.

        this.finish(true);
    }


    public destroy(): void {
        
    }

}