import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFormik, Field, Form } from 'formik';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';
import { PrizeModel } from '../../../game/models/PrizeModel';
import { PrizeCollectionsModel } from '../../../game/models/PrizeCollectionsModel';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { GetPrizeCollectionAction } from '../../../game/actions/user/inventory/GetPrizeCollectionAction';
import { BOCountLabel, BOFixedSizeImage, BOInputField, BOLabel, BOPrizeDetails, BOPrizeDetailsCard, BOSelect } from '../styles';
import OptionsFormField from '../common/OptionsFormField';

interface InitialPrizeEditorProps {
    prizeId: string;
    collectionId: string;
    count: number;
    allPrizes: PrizeModel[];
    index: number;
    setFieldValue: (field: string, value: any) => void;
}

export const InitialPrizeEditor: React.FunctionComponent<InitialPrizeEditorProps> = (props) => {
    const { prizeId, collectionId, count, allPrizes, index, setFieldValue } = props;

    const prizeCollectionsModel = useObservableModel<PrizeCollectionsModel>('_prizeCollectionsModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    const [selectedPrize, setSelectedPrize] = useState(null);
    const collection = prizeCollectionsModel.getCollectionById(collectionId);

    if (!collection) {
        return <></>;
    }

    useEffect(() => {
        if (allPrizes.length > 0) {
            setSelectedPrize(allPrizes[0]);
            setFieldValue(`id`, allPrizes[0].id)
        }
    }, [allPrizes]);

    return (
        <div>
            <OptionsFormField label="Select Prize:" name={`initialPrizes[${index}].id`}  options={ allPrizes } onChange={(e) => {                        
                        const newPrize = collection.getPrizeById(e.target.value);
                        setSelectedPrize(newPrize);
                    }}/>

            {selectedPrize && (
                <BOPrizeDetails>
                    <p>{selectedPrize.title}</p>
                    <BOFixedSizeImage src={selectedPrize.image}/>
                </BOPrizeDetails>
            )}

            <BOCountLabel>
                Count:
                <BOInputField
                    type="number"
                    value={count}
                    onChange={(e) => setFieldValue('count', parseInt(e.target.value, 10))}
                />
            </BOCountLabel>
        </div>
    );
};