import { GameModel } from "../../ilmare/models/GameModel";
import { PrizeModel } from "./PrizeModel";

export class UserModel extends GameModel {

    protected _id: string;    
    protected _role: string;
    protected _email: string;
    protected _name: string;
    protected _services: any;
    protected _picture: string;    
    protected _wonPrizes: PrizeModel[];

    public get role(): string {
        return this._role;
    }

    public get isAdmin(): boolean {
        return this._role === 'admin';
    }

    public get isLoggedIn(): boolean {
        return this._role !== undefined && this._role !== null && this._role !== '';
    }

    public get id(): string {
        return this._id;
    }

    public get name(): string {
        return this._name;
    }

    public get services(): any {
        return this._services;
    }

    public get picture(): string {
        return this._picture;
    }

    public get email(): string {
        return this._email;
    }

    public get wonPrizes(): PrizeModel[] { 
        return this._wonPrizes; 
    };



    public authorize(id: string, role: string) {
        this._id = id;
        this._role = role;

        this.notifyChange();
    }

    // id, role and password are not to be handled here, only details. Note: email needs to always be validated server side and shouldn't be easily changed (if possible)
    public update(evtData) {
        this._email = evtData.email;
        this._name = evtData.name
        this._services = evtData.services
        this._picture = evtData.picture;

        this.notifyChange();
    }

    public updateWonPrizes(prizes) {
        this._wonPrizes = [];
        for (let prize of prizes) {
            let prizeModel = new PrizeModel();
            prizeModel.populateWonPrize(prize);
            this._wonPrizes.push(prizeModel);
        }

        this.notifyChange();
    }

    public addWonPrize(prize): PrizeModel {
        let prizeModel = new PrizeModel();
        prizeModel.populateWonPrize(prize);
        this._wonPrizes.push(prizeModel);

        this.notifyChange();
        return prizeModel;
    }

    public hasWonPrize(prizeId: string) {
        for (let prize of this._wonPrizes) {
            if (prize.id === prizeId) {
                return true;
            }
        }
        return false;
    }

    public setup() {
        this._wonPrizes = [];

        this.notifyChange();
    }

    public destroy() {
    
    }

    
}