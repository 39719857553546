import React, { FunctionComponent } from 'react';
import { useNavigate } from "react-router-dom";
import { PlayAtMachineAction } from '../../../game/actions/machines/PlayAtMachineAction';
import { ReserveMachineAction } from '../../../game/actions/machines/ReserveMachineAction';
import { LeaveCurrentQueueAction } from '../../../game/actions/queue/LeaveCurrentQueueAction';
import { ActionsManager } from '../../../game/managers/ActionsManager';
import { CurrentMachineModel, CurrentMachineStatus } from '../../../game/models/CurrentMachineModel';
import { MachinesModel } from '../../../game/models/MachinesModel';
import { useInjection } from '../../../game/reactHooks/InjectHooks';
import { EventListenerService } from '../../../game/services/EventListenerService';
import { CancelActionButton, InQueueActionButton, PlayActionButton, ReserveActionButton } from './styles';
import { useObservableModel } from '../../../ilmare/models/ObservableModelState';

type CancelButtonProps = {
    id: string
}

export const CancelButton: FunctionComponent<CancelButtonProps> = ({id})=> {

    const navigate = useNavigate();   
    const currentMachine = useObservableModel<CurrentMachineModel>('_currentMachineModel');
    const actionsManager = useInjection<ActionsManager>('_actionsManager');

    let actionMessage: string = "CANCEL";    

    if (currentMachine.status == CurrentMachineStatus.PLAYING || currentMachine.status == CurrentMachineStatus.IN_QUEUE) {
        return <CancelActionButton onClick={
                (evt) => {
                    actionsManager.executeActionInstant(LeaveCurrentQueueAction, id);                
                }        
            }>
            { actionMessage }
        </CancelActionButton>;
    
    } else {
        return <></>
    }

    
}